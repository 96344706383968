export enum DateTypes {
  deutsch = "deutsch",
  englisch = "englisch"
}

export function toMoneyFormat(v: number, moneytype = "€"): string {
  return toNumberFormat(v) + " " + moneytype;
}

export function toNumberFormat(v: number, digits: number = 2): string {
  let vnew = v.toFixed(digits);
  let splitted = vnew.split(".");

  return trenner(splitted[0]) + (splitted[1] !== undefined ? "," + splitted[1] : "");
}

export function fromMoneyFormat(v: string): number {
  return parseFloat(replaceAll(v, ",", "").replace(/(?!-)[^0-9.]/g, ""));
}

export function replaceAll(str: string, find: string, replace: string): string {
  return str.replace(new RegExp(find, "g"), replace);
}

export function trenner(number: string) {
  if (number.length > 3) {
    var mod = number.length % 3;
    var output = mod > 0 ? number.substring(0, mod) : "";
    for (let i: number = 0; i < Math.floor(number.length / 3); i++) {
      if (mod == 0 && i == 0) output += number.substring(mod + 3 * i, mod + 3 * i + 3);
      else output += "." + number.substring(mod + 3 * i, mod + 3 * i + 3);
    }
    return output;
  } else return number;
}

export function toTimeNumber(time: string) {
  let [hours, minutes] = time.split(":");
  return ourParseFloat(hours) * 60 + (minutes !== undefined ? ourParseFloat(minutes) : 0);
}
export function toTimeString(time: number) {
  let minutes = time % 60;
  let hours = Math.floor(time / 60);
  return hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0");
}

export function ourParseFloat(number: string) {
  let res = 0;
  if (!isNaN(parseFloat(number))) res = parseFloat(number);
  return res;
}

export function toDateFormat(date: string, format: DateTypes) {
  if (format === DateTypes.deutsch) {
    let splitted = date.split("-");
    return splitted[2] + "." + splitted[1] + "." + splitted[0];
  } else return date;
}
