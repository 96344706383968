import { InputHOC } from "./InputHOC";
import { OurAdressInput, OurAdressInputSplitted, OurDateInput, OurDateRangeInput, OurInput, OurNumberInput, OurTimeInput, OurTimeRangeInput } from "./Input";
import { OurCheckbox, OurSwitch } from "./Checkbox";
import { OurAutocomplete, OurOption, OurSelect } from "./Select";
import { OurSlider } from "./Slider";

import { OButton as Button, ColorEnum } from "./Button";
import TextArea from "antd/es/input/TextArea";

// @ts-ignore
import countryList from "react-select-country-list";
import { Select } from "antd";
import { OurFileUpload } from "./FileUpload";
export const OCountryList = countryList().getData();
export const OTextArea = InputHOC(TextArea);
export const OInput = InputHOC(OurInput);
export const OTimeInput = InputHOC(OurTimeInput);
export const OTimeRangeInput = InputHOC(OurTimeRangeInput);
export const OSelect = InputHOC(OurSelect);
export const OAdressInput = InputHOC(OurAdressInput);
export const OAdressInputSplitted = InputHOC(OurAdressInputSplitted, false);
export const OCheckbox = InputHOC(OurCheckbox, false);
export const OSwitch = InputHOC(OurSwitch, false);
export const OFileUpload = InputHOC(OurFileUpload, false);
export const OSlider = InputHOC(OurSlider, false);
export const ONumberInput = InputHOC(OurNumberInput);
export const ODateInput = InputHOC(OurDateInput);
export const ODateRangeInput = InputHOC(OurDateRangeInput);
export const OOption = OurOption;
export const OOptGroup = Select.OptGroup;
export const OAutoComplete = InputHOC(OurAutocomplete);
export const OButton = Button;
export const OColors = ColorEnum;

export const OTextAreaNoborder = InputHOC(TextArea, false);
export const OInputNoborder = InputHOC(OurInput, false);
export const OTimeInputNoborder = InputHOC(OurTimeInput, false);
export const OSelectNoborder = InputHOC(OurSelect, false);
export const ONumberInputNoborder = InputHOC(OurNumberInput, false);
export const ODateInputNoborder = InputHOC(OurDateInput, false);
