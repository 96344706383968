import { observer } from "mobx-react"
import React, { Component } from "react"
import { action, computed, observable } from "mobx"

import { OurFontAwsomeIcon } from "../Framework/OurComps/Icons/OurFontAwsomeIcon"
import {
  faBarcode,
  faCircle,
  faExclamationTriangle,
  faFileExport,
  faList,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons"
import { LanguageClassObject } from "../Framework/Languagechanger/Language"
import { Column, OTable, Searchenum } from "../Framework/Table/Table"
import { Backendschnittstelle } from "../backend"
import { OrderItem, Orders } from "../models/Order"

import { ourParseFloat, toDateFormat, toMoneyFormat, toNumberFormat } from "../Framework/Helperfunctions/Formatter"
import { Row } from "../Framework/Stylers/Stylers"
import { NumberTypes } from "../Framework/FormElements/Input"
import { OInput, ONumberInput } from "../Framework/FormElements/FormElements"
import axios from "axios"
import { faFilePdf } from "@fortawesome/free-regular-svg-icons"
import { Drawer, Modal, Popconfirm } from "antd"

import moment from "moment"
import { ColorEnum, OButton } from "../Framework/FormElements/Button"
import ReactDOM from "react-dom"

const { confirm } = Modal

interface OwnProps2 {
  language: LanguageClassObject
  bs: Backendschnittstelle
}

type TableProps = OwnProps2
@observer
export class TableOrders extends Component<TableProps> {
  @observable NVEdelSearch: { value: string } = { value: "" }
  @observable deleterquestVisible: boolean = false
  @observable currentRowForDel: OrderItem | undefined = undefined
  @observable NVEDelDrawerOpend: boolean = false
  @observable checkBoxArray: string[] = []
  @observable columns: Column[] = []
  @observable columns2: Column[] = []
  @observable orders: OrderItem[] | undefined
  @observable isClickedAlready: boolean = false
  @computed get currentNVEList() {
    return this.tabledata.reduce((prev, cur) => {
      prev.push(
        ...cur.article.reduce((p, c) => {
          p.push(
            ...c.NVEs.map((N) => {
              return { NVE: N, arr: c.NVEs }
            })
          )
          return p
        }, [] as { NVE: string; arr: string[] }[])
      )
      return prev
    }, [] as { NVE: string; arr: string[] }[])
  }
  @computed get filteredCurrentNVEList() {
    return this.currentNVEList.filter((v) => v.NVE.includes(this.NVEdelSearch.value) || this.NVEdelSearch.value === "")
  }
  @computed get tabledata() {
    if (this.orders === undefined) return []
    else {
      // return this.orders.filter((o: OrderItem) => {
      //   return o.sender.includes("Hornbach")
      // })
      return this.orders
    }
  }
  ordersDB: Orders | undefined
  static defaultProps = {}

  constructor(props: TableProps) {
    super(props)
    this.props.bs.getDataset("Orders").then((v: any) => {
      this.ordersDB = v
      this.orders = (v as Orders).getValue()
      console.log(this.tabledata)
    })

    this.columns2.push(
      {
        key: "linenumber",
        name: "Pos",
        activ: true,
        width: 60,
        minWidth: 60,
        style: { textAlign: "right" },
        sortable: true,
      },
      {
        key: "articledescription",
        name: "Artikelbeschreibung",
        activ: true,
        width: 580,
        maxWidth: 580,
      },
      {
        key: "gtin",
        name: "EAN",
        activ: true,
        style: { textAlign: "right" },
        width: 100,
      },
      {
        key: "articlecodesupplier",
        name: "Code Verkäufer",
        activ: true,
        style: { textAlign: "right" },
        width: 100,
      },
      {
        key: "articlecodebuyer",
        name: "Code Käufer",
        activ: true,
        style: { textAlign: "right" },
        width: 90,
      },
      {
        key: "orderedquantityunitcode",
        name: "Einheit",
        activ: true,
        formatter: (value, row) => {
          switch (value) {
            case "PCE":
              return "Stk"
            case "PCS":
              return "Stk"
            case "MTK":
              return "m²"
            case "MTQ":
              return "m³"
            case "MTR":
              return "m"
            default:
              return "Stk"
          }
        },
        style: { textAlign: "right" },
        width: 50,
      },
      {
        key: "orderedquantity",
        name: "Menge",
        activ: true,
        formatter: (value, row) => toNumberFormat(ourParseFloat(value), 2),
        style: { textAlign: "right" },
        width: 70,
      },

      {
        key: "purchaseprice",
        name: "E. Preis",
        formatter: (value) => toMoneyFormat(ourParseFloat(value)),
        activ: true,
        style: { textAlign: "right" },
        width: 60,
      },
      {
        key: "pakete",
        name: "Pakete",
        formatter: (value, row) => row.NVEs.length + " / " + ourParseFloat(row.orderedpackages),
        activ: true,
        style: { textAlign: "right" },
        width: 60,
      },
      {
        key: "orderdpackages",
        name: "Bestellte",
        formatter: (value, row) => (
          <div>
            <ONumberInput
              height={"small"}
              /*     onChange={() => {
                      window.setTimeout(
                        () => {
                          if (this.ordersDB !== undefined) this.ordersDB.save();
                        },

                        1000
                      );
                    }}*/
              onBlur={() => {
                if (this.ordersDB !== undefined) {
                  console.log("hi")
                  this.ordersDB.save()
                }
              }}
              autoWidth
              listSize={true}
              numbertype={NumberTypes.full}
              value={row}
              savename={"orderedpackages"}
            />
          </div>
        ),
        activ: true,
        style: { textAlign: "right", padding: 0 },
        width: 70,
      },
      {
        key: "lieferbar",
        name: "Lieferbar",
        formatter: (value, row) => (
          <div>
            <ONumberInput
              max={ourParseFloat(row.orderedpackages)}
              height={"small"}
              /*     onChange={() => {
                window.setTimeout(
                  () => {
                    if (this.ordersDB !== undefined) this.ordersDB.save();
                  },

                  1000
                );
              }}*/
              onBlur={() => {
                if (this.ordersDB !== undefined) {
                  console.log("hi")
                  this.ordersDB.save()
                }
              }}
              autoWidth
              listSize={true}
              numbertype={NumberTypes.full}
              value={row}
              savename={"lieferbar"}
            />
          </div>
        ),
        activ: true,
        style: { textAlign: "right", padding: 0 },
        width: 70,
      },
      {
        key: "m3packagesCurrent",
        name: "m3",
        formatter: (value, row) => toNumberFormat(ourParseFloat(value), 2),
        activ: true,
        style: { textAlign: "right" },
        width: 80,
      },
      {
        key: "tour",
        name: "Tour",
        formatter: (value, row) =>
          value !== "" && value !== undefined ? (
            <div onClick={this.openTour.bind(this, value)} style={{ color: "#1890ff" }}>
              {value.slice(3, 10)}
            </div>
          ) : null,
        activ: true,
        width: 70,
      },
      {
        key: "NVEs",
        name: "NVEs",
        formatter: (value, row) => value.map((v: string) => v.slice(15, v.length)).join(", "),
        activ: true,
        width: 500,
      }
    )
    this.columns.push(
      {
        key: "konzern",
        name: "Von",
        activ: true,
        searchfunc: Searchenum.includesString,
        width: 100,
        sortable: true,
      },
      {
        key: "ordernumberbuyer",
        name: "Bestellnummer",
        searchfunc: Searchenum.includesString,
        activ: true,
        style: { textAlign: "right" },
        width: 100,
        sortable: true,
      },
      {
        key: "orderdate",
        name: "Bestelldatum",
        activ: true,
        searchfunc: Searchenum.includesString,
        formatter: (value, row) => toDateFormat(value, this.props.language.dateFormat),

        style: { textAlign: "right" },
        width: 90,
        sortable: true,
      },
      {
        key: "requesteddeliverydate",
        name: "Lieferdatum",
        formatter: (value, row) => toDateFormat(value, this.props.language.dateFormat),
        activ: true,
        searchfunc: Searchenum.includesString,
        style: { textAlign: "right" },
        width: 90,
        sortable: true,
      },
      {
        key: "buyer",
        name: "Besteller",
        searchfunc: Searchenum.includesString,
        activ: true,
        width: 200,
        sortable: true,
      },
      {
        key: "buyeradressefull",
        name: "Besteller Adresse",
        activ: false,
        searchfunc: Searchenum.includesString,
        formatter: (value, row: OrderItem) => {
          return row.konzern + " - " + row.buyerzipcode + " " + row.buyercity
        },
        width: 250,
        sortable: true,
      },
      {
        key: "marktnummer",
        name: "Nummer",
        searchfunc: Searchenum.includesString,
        activ: true,
        width: 70,
        sortable: true,
      },
      {
        key: "deliveryparty",
        name: "Anlieferung",
        searchfunc: Searchenum.includesString,
        activ: false,
        width: 160,
        sortable: true,
      },
      {
        key: "lieferadresse",
        name: "Lieferadresse",
        searchfunc: Searchenum.includesString,
        formatter: (value, row: OrderItem) => {
          return (
            row.deliveryparty + "-" + row.deliverypartyzipcode + " " + row.deliverypartycity + ", " + row.deliverypartyaddress
          )
        },
        activ: true,
        width: 300,
        minWidth: 300,
        maxWidth: 700,
        sortable: true,
      },
      {
        key: "ansprechpartner",
        name: "Ansprechpartner",
        formatter: (value, row: OrderItem) => {
          return row.buyercontactperson + " - " + row.buyercontactpersontelephone
        },
        activ: true,
        width: 280,
        maxWidth: 400,
        sortable: true,
      },
      {
        key: "isspecialorder",
        name: "Extra",
        searchfunc: Searchenum.includesString,
        formatter: (value, row) => (value === true ? <div style={{ color: "red" }}>Ja</div> : "Nein"),
        activ: true,
        width: 50,
      },
      {
        key: "pakete",
        name: "Pakete",
        formatter: (value, row) => row.totalpacksdelivered + " / " + row.totalpacksordered,
        activ: true,
        style: { textAlign: "right" },
        width: 60,
      },
      {
        key: "totallieferbar",
        name: "Lieferbar",
        activ: true,
        style: { textAlign: "right" },
        width: 70,
      },
      {
        key: "m3packages",
        name: "m3",
        activ: true,
        style: { textAlign: "right" },
        width: 80,
      },
      /*{
        key: "NVEList",
        name: "NVE",
        activ: true,
        formatter: (value, row) => value.join(", "),
        style: { textAlign: "right" },
        width: 150
      }*/
      {
        key: "touren",
        name: "Touren",
        searchfunc: Searchenum.includesString,
        formatter: (value, row) => (
          <Row>
            <OurFontAwsomeIcon color={row.tourenstatus} icon={faCircle} />
            {value.map((tour: string, index: number) => (
              <Row>
                <div onClick={this.openTour.bind(this, tour)} style={{ color: "#1890ff" }}>
                  {tour.slice(3, 10)}
                </div>
                {index + 1 < value.length ? ", " : ""}
              </Row>
            ))}
          </Row>
        ),
        activ: true,
        width: 150,
      }
    )
  }

  componentWillUnmount() {
    if (this.ordersDB !== undefined) this.ordersDB.abortSave()
  }

  openTour = (tour: string) => {}
  @action sendDESADV = (row: OrderItem) => {
    if (!this.isClickedAlready) {
      this.isClickedAlready = true
      confirm({
        title: "Wirklich versenden?",
        okText: this.props.language.l["Ja"],
        okType: "danger",
        cancelText: this.props.language.l["Nein"],
        onOk: () => {
          row.article.map((a) => console.log(a.NVEs))
          axios.get("/desadv/" + row.ID).then((response) => {
            row.DESADVNumber = response.data.DESADVNumber
            if (this.ordersDB !== undefined) {
              this.ordersDB.save()
            }
          })
        },
        onCancel() {},
      })
      window.setTimeout(() => {
        this.isClickedAlready = false
      }, 3000)
    }
  }
  @action getNVE = (row: OrderItem) => {
    //console.log(row);
    if (!this.isClickedAlready) {
      this.isClickedAlready = true
      axios.get("/nve/" + row.ID + "/" + row.totallieferbar).then((response) => {
        // console.log(response.data.NVEs);
        row.NVEList.splice(0, row.NVEList.length)
        row.NVEList.push(...response.data.NVEs)
        //response.data.NVEs.map((nve: string) => row.NVEList.push(nve));
        //console.log(row.NVEList);
        //      row.NVEList.splice(0, 0, response.data.NVEs);
        row.NVEFile = response.data.NVEPDF
        window.open(response.data.NVEPDF, "__blank")
        if (this.ordersDB !== undefined) {
          this.ordersDB.save()
        }
        //   window.setTimeout(() => console.log(row), 3000);
      })
      window.setTimeout(() => {
        this.isClickedAlready = false
      }, 3000)
    }
  }

  @action getNVEDirectConnect = (row: OrderItem) => {
    //console.log(row);
    if (!this.isClickedAlready) {
      this.isClickedAlready = true
      axios.get("/nveDirectConnect/" + row.ID).then((response) => {
        // console.log(response.data.NVEs);
        row.NVEList.splice(0, row.NVEList.length)

        row.article.map((article) => {
          article.NVEs.splice(0, article.NVEs.length)
          article.NVEs.push(...response.data.NVEs.find((item: any) => item.articleID === article.ID).NVEs)
        })

        row.NVEFile = response.data.NVEPDF
        window.open(response.data.NVEPDF, "__blank")
        if (this.ordersDB !== undefined) {
          this.ordersDB.save()
        }
      })
      window.setTimeout(() => {
        this.isClickedAlready = false
      }, 3000)
    }
  }
  toggleNVEdelDrawer = () => {
    this.NVEDelDrawerOpend = !this.NVEDelDrawerOpend
  }
  delNVE = (NVELIneItem: { NVE: string; arr: string[] }) => {
    let index = NVELIneItem.arr.findIndex((v) => v === NVELIneItem.NVE)
    if (index !== -1) {
      NVELIneItem.arr.splice(index, 1)
      if (this.ordersDB !== undefined) {
        this.ordersDB.save()
      }
    }
    this.NVEdelSearch.value = ""
  }

  @action
  openDelConfirm = (row: OrderItem) => {
    this.currentRowForDel = row
    this.deleterquestVisible = true
  }
  @action cancelDel = () => {
    this.currentRowForDel = undefined
    this.deleterquestVisible = false
  }
  @action confirmDel = () => {
    let index = this.orders?.findIndex((item) => item.ID === this.currentRowForDel?.ID)
    if (index !== -1 && index !== undefined) {
      this.orders?.splice(index, 1)
    }
    this.ordersDB?.save()
    this.currentRowForDel = undefined
    this.deleterquestVisible = false
  }
  render() {
    return this.orders !== undefined ? (
      <>
        <Popconfirm
          visible={this.deleterquestVisible}
          title="Wollen Sie diese Bestllung wirklich löschen?"
          onConfirm={this.confirmDel}
          onCancel={this.cancelDel}
          okText="Ja"
          cancelText="Nein"
        ></Popconfirm>
        <OButton width={4} onClick={this.toggleNVEdelDrawer}>
          NVEs Löschen
        </OButton>
        <Drawer title={"NVE löschen"} visible={this.NVEDelDrawerOpend} closable={true} onClose={this.toggleNVEdelDrawer}>
          <OInput placeholder={"Suche"} savename={"value"} value={this.NVEdelSearch} outerStyle={{ margin: 0 }} />
          {this.filteredCurrentNVEList.map((item) => (
            <Row centerVertical={true}>
              <div style={{ marginTop: "4px" }}>{item.NVE.slice(12)}</div>
              <OButton onClick={this.delNVE.bind(this, item)} color={ColorEnum.danger}>
                <OurFontAwsomeIcon icon={faTrashAlt} />
              </OButton>
            </Row>
          ))}
        </Drawer>
        <OTable
          actionWidth={140}
          language={this.props.language}
          columns={this.columns}
          data={this.tabledata}
          search={true}
          extensible={(row: OrderItem) => (
            <div style={{ margin: "10px" }}>
              <OTable
                language={this.props.language}
                pagination={false}
                customizeName={"OrdersInnerTable"}
                columns={this.columns2}
                data={row.article}
                size={"small"}
              />
            </div>
          )}
          getSubTableData={(row: any) => {
            return [this.columns2, row.article]
          }}
          checkBoxArray={this.checkBoxArray}
          customizeName={"OrdersTable"}
          withCSV
          size={"small"}
          searchtype={"and"}
          actionRenderer={(row: OrderItem) => {
            return (
              <Row>
                <OurFontAwsomeIcon color={"red"} icon={faTrash} onClick={this.openDelConfirm.bind(this, row)} />
                {/*        <OurFontAwsomeIcon
                  icon={faTrash}
                  onClick={() => {
                    // alert("hi");
                    row.NVEList.splice(0, 1);
                    // @ts-ignore
                    this.ordersDB.save();
                  }}
                  color={"black"}
                />*/}
                {row.hasWrongEAN ? <OurFontAwsomeIcon color={"red"} icon={faExclamationTriangle} /> : null}
                <OurFontAwsomeIcon color={row.verladen ? "green" : "blue"} icon={faCircle} />
                {(row.verladen || row.totalpacksdelivered >= row.totallieferbar) &&
                (row.DESADVNumber === "" || row.DESADVNumber === undefined) ? (
                  <OurFontAwsomeIcon
                    style={{ cursor: "pointer" }}
                    onClick={this.sendDESADV.bind(this, row)}
                    color={"black"}
                    icon={faFileExport}
                  />
                ) : row.NVEFile === "" || row.NVEFile === undefined ? (
                  <Row>
                    <OurFontAwsomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={this.getNVE.bind(this, row)}
                      color={"black"}
                      icon={faBarcode}
                    />
                    <OurFontAwsomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={this.getNVEDirectConnect.bind(this, row)}
                      color={"black"}
                      icon={faList}
                    />
                  </Row>
                ) : null}

                {row.NVEFile !== "" && row.NVEFile !== undefined ? (
                  <a href={row.NVEFile} target={"__blank"}>
                    <OurFontAwsomeIcon style={{ cursor: "pointer" }} color={"red"} icon={faFilePdf} />
                  </a>
                ) : null}
              </Row>
            )
          }}
        />
      </>
    ) : null
  }
}
