import { observer } from "mobx-react";
import React, { Component } from "react";
import { Slider } from "antd";
import { SliderProps } from "antd/es/slider";
import { HOCInputProps } from "./InputHOC";

interface OwnPropsSlider extends SliderProps {}

type MySlider = OwnPropsSlider;
@observer
export class OurSlider extends Component<HOCInputProps & MySlider> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    // @ts-ignore
    const { onKeyDown, ...props } = this.props;
    return <Slider {...props} />;
  }
}
