import { computed, observable, toJS, autorun, reaction, action, IObservableArray } from "mobx";
import { computedFn, deepObserve } from "mobx-utils";
import equal from "deep-equal";
import io from "socket.io-client";
import { throwError } from "rxjs";
import { Datatable, SaveStream } from "./DataSets";
import { LanguageClassObject } from "../Languagechanger/Language";
import lscache from "lscache";

export type BackendProps = {
  l: LanguageClassObject;
  withLocalStorage: boolean;
};

export type LoginResponse = {};

var counter = 0;

function setIntervalImmediately(func: any, interval: number) {
  func();
  return window.setInterval(func, interval);
}

export abstract class Backend {
  languageClass: LanguageClassObject;
  datasets: Array<Datatable<any>> = [];
  kundenID: number = 0;
  incrementer = 0;
  sessionID = "";
  firstConnect: boolean = true;
  @observable sendQueue: { name: string; changestream: SaveStream; ID: string }[] = [];
  @observable inQueue: string[] = [];
  @observable lastSyncIndex: number = 0;
  @observable connected: boolean = false;
  @observable initconnected: boolean = false;
  @observable loggedIn: boolean = false;
  @observable userdata: any = {};
  @observable companydata: any = {};
  mousesuspend = () => {};
  l: (text: string) => string = (text: string) => {
    return "test";
  };
  //socket =  io("https://hornbachnve.its-intern.de",{ transports: ["websocket"] });
  socket =
    window.location.hostname.replace("www.", "") == "localhost" || window.location.hostname.replace("www.", "").slice(0, 7) == "192.168"
      ? io(window.location.hostname.replace("www.", "") + ":2000", { transports: ["websocket"] })
      : io({ transports: ["websocket"] });

  constructor({ ...props }: BackendProps) {
    if (lscache.get("loggedIn") === true) {
      this.loggedIn = true;
      this.userdata = lscache.get("user");
      this.companydata = lscache.get("company");
      this.mousesuspend = this.refreshChache.bind(this);
      document.body.addEventListener("click", this.mousesuspend);

      window.location.href = this.userdata.firstPage !== undefined ? this.userdata.firstPage : "/#/";
    }
    this.languageClass = props.l;
    this.socket.on("connect", () => {
      this.socketConnect();

      /*      window.setInterval(() => {
        let counteri = counter++;
        console.log("send");

        this.socket.emit("test", counteri, (id: any) => {
          console.log("testsends", id);
        });
      }, 10000);*/
    });
    this.socket.on("getsyncnumber", (index: number) => {
      if (this.firstConnect) {
        this.firstConnect = false;

        this.lastSyncIndex = index;
      }
    });
    this.socket.on("disconnect", () => {
      this.connected = false;
    });
    this.socket.on("reconnect", () => {
      window.setTimeout(() => {
        var getReconnectData = setIntervalImmediately(() => {
          this.socket.emit(
            "getReconnectData",
            this.inQueue,
            this.lastSyncIndex,
            (lastIndex: number, missedData: { name: string; changeStream: SaveStream; ID: string }[]) => {
              window.clearInterval(getReconnectData);
              console.log("reconectevent");
              this.lastSyncIndex = lastIndex;
              missedData.map(data => this.updateTable(data.name, data.changeStream, data.ID));
              this.connected = true;
              let tempsendqueue = this.sendQueue.slice();
              this.inQueue = [];
              this.sendQueue.splice(0, this.sendQueue.length);
              tempsendqueue.map(q => {
                this.saveToServer(q.name, q.changestream, q.ID);
              });
            }
          );
        }, 20000);
      }, 15000);
    });

    this.socket.on("updateDBTabel", (name: string, changestream: SaveStream, ID: string) => {
      //this.inQueue.push(ID);
      this.updateTable(name, changestream, ID);
    });
  }

  socketConnect = async () => {
    this.sessionID = this.socket.id + "t" + Date.now().toString();
    /*await Promise.all([this.getDataset("Orders")]);
    window.setTimeout(() => {
      this.connected = true;
      this.initconnected = true;
    }, 100);*/
    this.connected = true;
    this.initconnected = true;
  };
  newID = (): string => {
    this.incrementer++;
    return this.sessionID + "t" + this.incrementer;
  };
  login = (login: { username: string; passwort: string }): { status: boolean; userdata: any; companydata: any } => {
    //nur bei gültigem lOGIN
    this.loggedIn = true;
    this.userdata.username = login.username;
    this.userdata.passwort = login.passwort;
    this.userdata.tools = ["eDispo", "eTarif"];
    this.companydata.name = "company";
    this.companydata.urlpath = "efuture-group.de";
    lscache.set("loggedIn", true, 15);
    lscache.set("user", this.userdata, 15);
    lscache.set("company", this.companydata, 15);
    this.mousesuspend = this.refreshChache.bind(this);
    document.body.addEventListener("click", this.mousesuspend);
    window.location.href = this.userdata.firstPage !== undefined ? this.userdata.firstPage : "/#/";
    return { status: true, userdata: {}, companydata: {} };
  };
  refreshChache = () => {
    if (!lscache.get("loggedIn")) {
      this.logout();
      return;
    }
    lscache.set("loggedIn", true, 15);
    lscache.set("user", this.userdata, 15);
    lscache.set("company", this.companydata, 15);
  };
  logout = (): void => {
    document.body.removeEventListener("click", this.mousesuspend);
    lscache.remove("loggedIn");
    lscache.remove("user");
    lscache.remove("company");
    this.loggedIn = false;
  };
  updateTable = (name: string, changestream: SaveStream, ID: string) => {
    if (!this.inQueue.includes(ID)) {
      this.inQueue.push(ID);
      let dt = this.datasets.find(item => item.tableName === name);

      if (dt !== undefined) {
        dt.addChangesFromServer(changestream);
      }
    }
  };
  saveToServer = (name: string, changestream: SaveStream, IDin?: string) => {
    let ID = IDin !== undefined ? IDin : this.newID();
    let beforeStream = this.sendQueue.find(ele => ele.name === name);
    if (beforeStream !== undefined && beforeStream !== null) {
      beforeStream.changestream.changes.push(...changestream.changes);
      beforeStream.changestream.forceReroll = beforeStream.changestream.forceReroll || changestream.forceReroll;
    } else {
      this.sendQueue.push({ ID, changestream, name });
    }
    if (this.socket.connected) {
      this.inQueue.push(ID);
      this.socket.emit("updateDBTable", name, changestream, ID, (ID: string) => {
        let index = this.sendQueue.findIndex(v => v.ID === ID);
        if (index !== undefined) {
          this.sendQueue.splice(index, 1);
        }
      });
    }
  };

  async getDataset(name: string) {
    let dt = this.datasets.find(item => item.tableName === name);
    if (dt !== undefined) {
      return this.datasets.find(item => item.tableName === name);
    } else {
      let set: Datatable<any> = this.getTableClass(name, [], this.saveToServer.bind(this, name), this.languageClass);

      this.datasets.push(set);
      this.socket.emit("getDBTable", name, (dataset: any[]) => {
        set.initData(dataset);
      });

      return set;
    }
  }

  abstract getTableClass: (name: string, initValue: any[], onSave: (save: SaveStream) => void, l: LanguageClassObject) => Datatable<any>;
}
