import React, { Component, CSSProperties, ReactChildren, ReactNode, RefObject } from "react";
import { observer } from "mobx-react";
import { Input, Popover } from "antd";

import { InputProps } from "antd/es/input";
import { dates, numbers } from "../Helperfunctions/patterns";
import { action, computed, observable } from "mobx";
import { DateTypes, ourParseFloat, toTimeNumber, trenner } from "../Helperfunctions/Formatter";
import moment from "moment";
import OurDatepicker from "./Datepicker";
import { LanguageClassObject, Languages, LanguagesEnum } from "../Languagechanger/Language";
import { ODateInput, ODateInputNoborder, OInput, OInputNoborder, OSelect, OSelectNoborder, OTimeInput, OTimeInputNoborder } from "./FormElements";
import { HOCInputProps } from "./InputHOC";
import { Row } from "../Stylers/Stylers";
import "./Input.scss";
import ReactDOM from "react-dom";
import { OurOption } from "./Select";
// @ts-ignore
import countryList from "react-select-country-list";
import { SelectProps } from "antd/es/select";
import { measureText } from "../Helperfunctions/allgemein";
import plus24h from "./plus24h.png";

interface OwnProps2 extends InputProps {
  emptyonenter?: boolean;
  ovalue?: any;
  savename?: string;
}

type MyInputProps = OwnProps2;
@observer
export class OurInput extends Component<HOCInputProps & MyInputProps> {
  @observable clearReady: boolean = false;
  static defaultProps = {
    emptyonenter: false
  };

  constructor(props: any) {
    super(props);
  }
  @action
  setClearReady = (e: any) => {
    this.clearReady = true;
    if (this.props.onFocus) this.props.onFocus(e);
  };
  @action
  clear = (e: any) => {
    if (this.props.emptyonenter && this.clearReady && e.keyCode != 40 && e.keyCode != 39 && e.keyCode != 38 && e.keyCode != 37 && e.keyCode != 9) {
      this.clearReady = false;
      this.props.ovalue[this.props?.savename ?? ""] = "";
    }
    if (this.props.onKeyPress) this.props.onKeyPress(e);
  };
  render() {
    let { onFocus, onKeyPress, ...props } = this.props;
    return <Input onFocus={this.setClearReady} onKeyPress={this.clear} {...props} />;
  }
}
export enum NumberTypes {
  full = "full",
  fullSinged = "fullSigned",
  withComma = "withComma",
  withCommaSigned = "withCommaSigned",
  twoDigitComma = "twoDigitComma",
  twoDigitCommaSigned = "twoDigitCommaSigned"
}

interface OwnProps3 extends OwnProps2 {
  numbertype: NumberTypes;
  onChange?: (v: any) => void;
  digits?: number;
  suffix?: string;
  steps?: number;
  min?: number;
  max?: number;
}

type NumberInputPorps = OwnProps3;

@observer
export class OurNumberInput extends Component<NumberInputPorps & HOCInputProps> {
  @computed get newvalue() {
    let [number, rest] = this.props.value !== undefined && this.props.value !== "" ? this.props.value.toString().split(".") : [""];
    let sign = number.toString()[0] === "-" ? number[0] : "";
    number = trenner(sign !== "" ? number.slice(1, number.length).toString() : number);
    return sign + number + (rest !== undefined ? "," + rest : "");
  }
  static defaultProps = {
    emptyonenter: true,
    digits: 1
  };

  constructor(props: any) {
    super(props);
  }
  @action
  blurAction = () => {
    let changedbefore = false;
    if (this.props.steps !== undefined && this.props.ovalue[this.props.savename] !== "") {
      let tempval = ourParseFloat(this.props.ovalue[this.props.savename]);
      tempval = Math.round(tempval / this.props.steps) * this.props.steps;
      this.props.ovalue[this.props.savename] = tempval.toString();
      changedbefore = true;
    }
    if (
      this.props.min !== undefined &&
      ourParseFloat(this.props.ovalue[this.props.savename]) < this.props.min &&
      this.props.ovalue[this.props.savename] !== ""
    ) {
      this.props.ovalue[this.props.savename] = this.props.min.toString();
      changedbefore = true;
    }
    if (
      this.props.max !== undefined &&
      ourParseFloat(this.props.ovalue[this.props.savename]) > this.props.max &&
      this.props.ovalue[this.props.savename] !== ""
    ) {
      this.props.ovalue[this.props.savename] = this.props.max.toString();
      changedbefore = true;
    }

    if (
      this.props.digits !== undefined &&
      this.props.numbertype !== NumberTypes.full &&
      this.props.numbertype !== NumberTypes.fullSinged &&
      this.props.value !== undefined &&
      this.props.savename != undefined &&
      this.props.value !== ""
    ) {
      let [number, rest] = this.props.ovalue[this.props.savename].toString().split(".");
      if (number === "") {
        number = 0;
      }
      if (rest === undefined) {
        if (this.props.numbertype === NumberTypes.twoDigitComma || this.props.numbertype === NumberTypes.twoDigitCommaSigned) {
          rest = "00";
        } else {
          rest = "";
          for (let i: number = 0; i < this.props.digits; i++) {
            rest += "0";
          }
        }
      } else {
        if (this.props.numbertype === NumberTypes.twoDigitComma || this.props.numbertype === NumberTypes.twoDigitCommaSigned) {
          if (rest.toString().length < 2) rest += "0";
          else if (rest.toString().length > 2) {
            rest = rest[0] + rest[1];
          }
        } else {
          for (let i: number = rest.toString().length; i < this.props.digits; i++) {
            rest += "0";
          }
          if (rest.toString().length > this.props.digits) rest = rest.slice(0, this.props.digits);
        }
      }
      if (this.props.onChange !== undefined) {
        this.props.onChange(number + "." + rest);
      }
    } else if (changedbefore && this.props.onChange !== undefined) {
      this.props.onChange(this.props.ovalue[this.props.savename]);
    }
    if (this.props.onBlur !== undefined) {
      window.setTimeout(() => {
        // @ts-ignore
        this.props.onBlur();
      }, 500);
    }
  };
  @action
  handleChange = (e: any) => {
    let v = e.target.value.replace(/\./g, "");
    let sign = "";
    let [number, rest] = v.split(",");
    if (
      this.props.numbertype === NumberTypes.fullSinged ||
      this.props.numbertype === NumberTypes.twoDigitCommaSigned ||
      this.props.numbertype === NumberTypes.withCommaSigned
    ) {
      if (number[0] === "-") {
        sign = "-";
      }
    }

    number = number.replace(/[^0-9]+/g, "");
    if (rest != undefined) {
      rest = rest.replace(/[^0-9]+/g, "");
    }
    if (this.props.onChange !== undefined) {
      if (this.props.numbertype === NumberTypes.full) {
        this.props.onChange(number);
      } else if (this.props.numbertype === NumberTypes.fullSinged) {
        this.props.onChange(sign + number);
      } else {
        this.props.onChange(sign + number + (rest !== undefined ? "." + rest : ""));
      }
    }
  };
  render() {
    let { onChange, value, style, onBlur, ...props } = this.props;
    let newstyle = style !== undefined ? { textAlign: "right", ...style } : { textAlign: "right" };

    return <OurInput onBlur={this.blurAction} style={newstyle as CSSProperties} {...props} onChange={this.handleChange} value={this.newvalue} />;
  }
}

interface OwnProps4 extends OwnProps2 {
  from?: string;
  to?: string;
  prefillyear?: boolean;
  onChange?: (v: any) => void;
  onKeyDown?: (v: any) => void;
  datepicker?: boolean;
  language: LanguageClassObject;
}

type DateInputPorps = OwnProps4;

@observer
export class OurDateInput extends Component<DateInputPorps & HOCInputProps> {
  @computed get newvalue() {
    let value = this.props.value !== undefined ? this.props.value.toString() : "";
    if (value !== "") {
      let newvalue: string = value;
      let [year, month, day] = value.split("-");
      if (this.props.language.dateFormat === DateTypes.deutsch) {
        newvalue =
          day +
          (day.length === 2 ? "." : "") +
          (month !== undefined && month !== "" ? month + (month.length === 2 ? "." : "") : "") +
          (year !== undefined && year !== "" ? year : this.props.prefillyear && month.length === 2 ? "20" : "");
      } else {
        newvalue =
          year + (year.length === 4 ? "-" : "") + (month !== undefined ? month + (month.length === 2 ? "-" : "") : "") + (day !== undefined ? day : "");
      }

      return newvalue;
    } else return "";
  }
  static defaultProps = {
    emptyonenter: true,
    prefillyear: true,
    from: moment("2000-01-01")
      .startOf("day")
      .format("YYYY-MM-DD"),
    to: "2199-12-31"
  };

  constructor(props: any) {
    super(props);
  }
  @action
  blurAction = () => {
    let valid = moment(this.props.value, "YYYY-MM-DD").isValid() && this.props.value != undefined && (this.props.value as string).length === 10;

    if (moment(this.props.value).isBefore(moment(this.props.from).startOf("day")) || moment(this.props.value).isAfter(moment(this.props.to).endOf("day"))) {
      valid = false;
    }
    if (!valid) {
      if (this.props.onChange !== undefined) {
        this.props.onChange("");
      }
    }
  };
  @action
  handleChange = (e: any) => {
    let v = e.target?.value ?? e;
    let sign = "";
    let year = undefined;
    let month = undefined;
    let day = undefined;
    if (this.props.language.dateFormat === DateTypes.deutsch) {
      [day, month, year] = v.split(".");
    } else {
      [year, month, day] = v.split("-");
      if (year.length === 1 && this.props.prefillyear) {
        year = "20" + year;
      }
    }

    if (year !== undefined) year = year.replace(/[^0-9]+/g, "");
    if (month !== undefined) month = month.replace(/[^0-9]+/g, "");
    if (day !== undefined) day = day.replace(/[^0-9]+/g, "");

    if (this.props.onChange !== undefined) {
      this.props.onChange((year === undefined ? "" : year) + "-" + (month === undefined ? "" : month) + "-" + (day === undefined ? "" : day));
    }
  };
  @action
  onKeyDownAction = (e: any) => {
    if (this.props.onKeyDown !== undefined) this.props.onKeyDown(e);
    if (e.key === "Backspace") {
      if (this.props.language.dateFormat === DateTypes.deutsch) {
        let [day, month, year] = this.newvalue.split(".");
        if (year !== undefined) {
          if (this.props.prefillyear && year.length === 2) {
            // @ts-ignore
            year = undefined;
            month = month[0];
            e.preventDefault();
          } else if (year.length === 0) {
            month = month[0];
            e.preventDefault();
          }
        } else if (month !== undefined) {
          if (month.length === 0) {
            day = day[0];
            e.preventDefault();
          }
        }
        if (this.props.onChange !== undefined) {
          this.props.onChange((year === undefined ? "" : year) + "-" + (month === undefined ? "" : month) + "-" + (day === undefined ? "" : day));
        }
      } else {
        let [year, month, day] = this.newvalue.split("-");
        if (year !== undefined) {
          if (day !== undefined) {
            if (day.length === 0) {
              // @ts-ignore
              day = undefined;
              month = month[0];
              e.preventDefault();
            }
          } else if (month !== undefined) {
            if (month.length === 0) {
              year = year[0] + year[1] + year[2];
              e.preventDefault();
            }
          } else if (this.props.prefillyear && year.length === 2) {
            // @ts-ignore
            year = undefined;
            e.preventDefault();
          }
          if (this.props.onChange !== undefined) {
            this.props.onChange((year === undefined ? "" : year) + "-" + (month === undefined ? "" : month) + "-" + (day === undefined ? "" : day));
          }
        }
      }
    }
  };
  render() {
    let { onChange, value, style, ...props } = this.props;
    let newstyle = style !== undefined ? { textAlign: "right", ...style } : { textAlign: "right" };

    return (
      <div>
        {this.props.datepicker && this.props.language !== undefined && this.props.savename !== undefined ? (
          <Popover
            content={
              <OurDatepicker
                from={this.props.from}
                to={this.props.to}
                onChange={this.handleChange}
                height={this.props.height as "small" | "medium" | "large"}
                value={this.props.ovalue}
                language={this.props.language}
                savename={this.props.savename}
              />
            }
            trigger="focus"
            placement={"bottom"}
          >
            <OurInput
              emptyonenter={true}
              onBlur={this.blurAction}
              placeholder={
                this.props.language.dateFormat === DateTypes.deutsch
                  ? "ttmm" + (this.props.prefillyear ? "jj" : "jjjj")
                  : (this.props.prefillyear ? "yy" : "yyyy") + "mmdd"
              }
              style={newstyle as CSSProperties}
              {...props}
              onChange={this.handleChange}
              value={this.newvalue}
              onKeyDown={this.onKeyDownAction}
            />
          </Popover>
        ) : (
          <OurInput
            emptyonenter={true}
            onBlur={this.blurAction}
            placeholder={
              this.props.language.dateFormat === DateTypes.deutsch
                ? "ttmm" + (this.props.prefillyear ? "jj" : "jjjj")
                : (this.props.prefillyear ? "yy" : "yyyy") + "mmdd"
            }
            style={newstyle as CSSProperties}
            {...props}
            onChange={this.handleChange}
            value={this.newvalue}
            onKeyDown={this.onKeyDownAction}
          />
        )}
      </div>
    );
  }
}

interface OwnProps5 extends OwnProps2 {
  from?: string;
  to?: string;
  prefillyear?: boolean;
  onChange?: (v: any) => void;
  onKeyDown?: () => void;
  datepicker?: boolean;
  language: LanguageClassObject;
}

type DateRangeInputPorps = OwnProps5;
@observer
export class OurDateRangeInput extends Component<DateRangeInputPorps & HOCInputProps> {
  @observable clearReady: boolean = false;
  nextref: any;
  static defaultProps = {
    emptyonenter: true,
    prefillyear: true,
    from: moment("2000-01-01")
      .startOf("day")
      .format("YYYY-MM-DD"),
    to: "2199-12-31"
  };

  constructor(props: any) {
    super(props);
    this.nextref = React.createRef();
  }

  @action
  onChange = (savename: string, e: any) => {
    this.props.value[savename] = e;
    if (this.props.value.from.length === 10 && savename === "from") {
      let domele = ReactDOM.findDOMNode(this.nextref.current);
      if (domele !== null) {
        if ("getElementsByTagName" in domele) {
          window.setTimeout(() => {
            // @ts-ignore
            domele.getElementsByTagName("input")[0].focus();
          }, 10);
        }
      }
    }
    let from = moment(this.props.value.from);
    let to = moment(this.props.value.to);

    if (to.isValid() && this.props.value.to.length === 10 && (!(to.isValid() && this.props.value.from.length === 10) || to.isBefore(from))) {
      this.props.value.from = "";
      this.props.value.to = "";
    }
  };
  render() {
    let { onChange, value, style, savename, ovalue, width, outerStyle, ...props } = this.props;
    return (
      <Row className={"InputCombo"}>
        <ODateInputNoborder
          onChange={this.onChange.bind(this, "from")}
          listSize
          value={this.props.ovalue[savename as string]}
          savename={"from"}
          outerStyle={{
            width: measureText(this.props.value.from, this.props.height === "small" ? 11 : 14) + 10 + "px",
            minWidth:
              measureText(
                this.props.prefillyear
                  ? this.props.language.language === LanguagesEnum.DE
                    ? "ttmmjj"
                    : "yymmdd"
                  : this.props.language.language === LanguagesEnum.DE
                  ? "ttmmjjjj"
                  : "yyyymmdd",
                this.props.height === "small" ? 11 : 14
              ) +
              10 +
              "px"
          }}
          {...(props as OwnProps5 & HOCInputProps)}
          style={{ textAlign: "left" }}
        />
        <ComboInputVerbinder style={{ paddingLeft: "5px", paddingRight: "5px" }}>
          <div>{this.props.language.l["bis"]}</div>
        </ComboInputVerbinder>

        <ODateInputNoborder
          ref={this.nextref}
          onChange={this.onChange.bind(this, "to")}
          outerStyle={{
            width: measureText(this.props.value.from, this.props.height === "small" ? 11 : 14) + 3 + "px",
            minWidth:
              measureText(
                this.props.prefillyear
                  ? this.props.language.language === LanguagesEnum.DE
                    ? "ttmmjj"
                    : "yymmdd"
                  : this.props.language.language === LanguagesEnum.DE
                  ? "ttmmjjjj"
                  : "yyyymmdd",
                this.props.height === "small" ? 11 : 14
              ) +
              3 +
              "px"
          }}
          listSize
          value={this.props.ovalue[savename as string]}
          savename={"to"}
          {...(props as OwnProps5 & HOCInputProps)}
          style={{ textAlign: "left" }}
        />
        <ComboInputVerbinder style={{ flexGrow: 1 }}></ComboInputVerbinder>
      </Row>
    );
  }
}

export const ComboInputVerbinder = (props: { children?: ReactNode; style?: CSSProperties }) => {
  let style = props.style !== undefined ? props.style : {};
  return (
    <Row center style={{ fontWeight: "bold", ...style }} className={"ComboInputVerbinder"} centerVertical={true}>
      {props.children}
    </Row>
  );
};

interface OwnProps7 extends OwnProps2 {
  clockFormat?: boolean;
  onChange?: (v: any) => void;
  onKeyDown?: (v: any) => void;
  steps?: number;
  language: LanguageClassObject;
}

type TimeInputPorps = OwnProps7;

@observer
export class OurTimeInput extends Component<TimeInputPorps & HOCInputProps> {
  static defaultProps = {
    emptyonenter: true,
    clockFormat: true,
    steps: 1
  };

  constructor(props: any) {
    super(props);
  }
  @action
  blurAction = () => {
    if (this.props.value !== "") {
      let [hours, minutes] = this.props.value.split(":");
      hours = hours.padStart(2, "0");
      if (minutes === undefined || minutes === "") minutes = "0";

      if ((this.props.steps as number) > 1) {
        minutes = (Math.round(parseInt(minutes) / (this.props.steps as number)) * (this.props.steps as number)).toString();
        if (parseInt(minutes) > 59) {
          minutes = (60 - (this.props.steps as number)).toString();
        }
      }
      minutes = minutes.padStart(2, "0");
      if (this.props.onChange !== undefined) {
        this.props.onChange(hours + ":" + minutes);
      }
    }
  };

  @action
  handleChange = (e: any) => {
    let v = e.target.value;
    let [hours, minutes] = v.split(":");

    hours = hours.replace(/[^0-9]+/g, "");
    if (parseInt(hours) > 23 && this.props.clockFormat) hours = "23";
    if (hours.length < 2 && minutes != undefined) hours = hours.padStart(2, "0");

    if (minutes != undefined) {
      minutes = minutes.replace(/[^0-9]+/g, "");
      if (parseInt(minutes) > 59) minutes = "59";
    }
    if (this.props.onChange !== undefined) {
      this.props.onChange(hours + (hours.length === 2 && minutes === undefined ? ":" : "") + (minutes !== undefined ? ":" + minutes : ""));
    }
  };
  @action
  onKeyDownAction = (e: any) => {
    if (this.props.onKeyDown !== undefined) this.props.onKeyDown(e);

    if (e.key === "Backspace" && this.props.value.toString().length === 3 && this.props.onChange !== undefined) {
      this.props.onChange(this.props.value[0]);

      e.preventDefault();
    }
  };
  render() {
    let { onChange, value, style, onKeyDown, outerStyle, ...props } = this.props;
    let newstyle = style !== undefined ? { textAlign: "right", ...style } : { textAlign: "right" };

    return (
      <OurInput
        placeholder={this.props.language.l["hhmm"]}
        onBlur={this.blurAction}
        style={newstyle as CSSProperties}
        {...props}
        onKeyDown={this.onKeyDownAction}
        onChange={this.handleChange}
        value={value}
        suffix={this.props.suffix}
      />
    );
  }
}
interface OwnProps9 extends OwnProps2 {
  onChange?: (v: any) => void;
  onKeyDown?: () => void;
  language: LanguageClassObject;
  steps?: number;
  nextDay?: boolean;
}

type TimeRangeInputPorps = OwnProps9;
@observer
export class OurTimeRangeInput extends Component<TimeRangeInputPorps & HOCInputProps> {
  @observable clearReady: boolean = false;
  @computed get nextday(): boolean {
    console.log(toTimeNumber(this.props.ovalue[this.props.savename].from));
    console.log(toTimeNumber(this.props.ovalue[this.props.savename].to));
    console.log(
      this.props.nextDay &&
        this.props.ovalue[this.props.savename].to.length > 1 &&
        toTimeNumber(this.props.ovalue[this.props.savename].from) > toTimeNumber(this.props.ovalue[this.props.savename].to)
    );

    if (
      this.props.nextDay &&
      this.props.ovalue[this.props.savename].to.length > 1 &&
      toTimeNumber(this.props.ovalue[this.props.savename].from) > toTimeNumber(this.props.ovalue[this.props.savename].to)
    )
      return true;
    else return false;
  }
  @observable isnextDay: boolean = false;

  nextref: any;
  static defaultProps = {
    emptyonenter: true,
    steps: 1,
    nextDay: true
  };

  constructor(props: any) {
    super(props);
    this.nextref = React.createRef();
  }
  @action
  onChange = (savename: string, e: any) => {
    this.props.value[savename] = e;
    if (this.props.value.from.length === 5 && savename === "from") {
      let domele = ReactDOM.findDOMNode(this.nextref.current);
      if (domele !== null) {
        if ("getElementsByTagName" in domele) {
          window.setTimeout(() => {
            // @ts-ignore
            domele.getElementsByTagName("input")[0].focus();
          }, 10);
        }
      }
    }
    if (this.props.value.from.length === 5 && this.props.value.to.length === 5 && !this.props.nextDay) {
      let fromsplitted = this.props.value.from.split(":");
      let from = fromsplitted[0] * 60 + fromsplitted[1];
      let tosplitted = this.props.value.to.split(":");
      let to = tosplitted[0] * 60 + tosplitted[1];
      if (parseInt(from) > parseInt(to)) {
        this.props.value.from = "";
        this.props.value.to = "";
      }
    }
  };
  check24h = () => {
    this.isnextDay = this.nextday;
  };
  render() {
    let { onChange, value, style, savename, ovalue, width, outerStyle, ...props } = this.props;
    return (
      <Row className={"InputCombo"}>
        <OTimeInputNoborder
          onChange={this.onChange.bind(this, "from")}
          listSize
          value={this.props.ovalue[savename as string]}
          savename={"from"}
          outerStyle={{
            width: measureText(this.props.value.from, this.props.height === "small" ? 11 : 14) + 10 + "px",
            minWidth: measureText(this.props.language.l["hhmm"], this.props.height === "small" ? 11 : 14) + 12 + "px"
          }}
          {...(props as OwnProps7 & HOCInputProps)}
          style={{ textAlign: "left" }}
        />
        <ComboInputVerbinder style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <div>{this.props.language.l["bis"]}</div>
        </ComboInputVerbinder>

        <OTimeInputNoborder
          ref={this.nextref}
          onBlur={this.check24h}
          onChange={this.onChange.bind(this, "to")}
          outerStyle={{
            width:
              measureText(this.props.value.from, this.props.height === "small" ? 11 : 14) +
              3 +
              (this.isnextDay ? (this.props.height === "small" ? 26 : 34) : 0) +
              "px",
            minWidth: measureText(this.props.language.l["hhmm"], this.props.height === "small" ? 11 : 14) + 4 + "px"
          }}
          listSize
          value={this.props.ovalue[savename as string]}
          savename={"to"}
          {...(props as OwnProps7 & HOCInputProps)}
          style={{ textAlign: "left" }}
          suffix={this.isnextDay ? <img width={this.props.height === "small" ? 16 : 20} src={plus24h} /> : ""}
        />

        <ComboInputVerbinder style={{ flexGrow: 1 }}></ComboInputVerbinder>
      </Row>
    );
  }
}

interface OwnProps10 extends OwnProps2 {
  onChange?: (v: any) => void;
  onKeyDown?: () => void;
  language: LanguageClassObject;
}

type AdressInputPorps = OwnProps9;
@observer
export class OurAdressInput extends Component<AdressInputPorps & HOCInputProps> {
  @observable clearReady: boolean = false;
  plzRef: any;
  cityRef: any;
  streetRef: any;
  numberRef: any;
  static defaultProps = {
    emptyonenter: false
  };

  constructor(props: any) {
    super(props);
    this.plzRef = React.createRef();
    this.streetRef = React.createRef();
    this.cityRef = React.createRef();
    this.numberRef = React.createRef();
  }
  @action
  onChange = (savename: string, e: any) => {
    this.props.value[savename] = e.target?.value ?? e;
    if (this.props.value.country !== "" && savename === "country") {
      let domele = ReactDOM.findDOMNode(this.plzRef.current);
      if (domele !== null) {
        if ("getElementsByTagName" in domele) {
          window.setTimeout(() => {
            // @ts-ignore
            domele.getElementsByTagName("input")[0].focus();
          }, 10);
        }
      }
    }
    /*
  if (this.props.value.from.length === 5 && this.props.value.to.length === 5) {
    let fromsplitted = this.props.value.from.split(":");
    let from = fromsplitted[0] * 60 + fromsplitted[1];
    let tosplitted = this.props.value.to.split(":");
    let to = tosplitted[0] * 60 + tosplitted[1];
    if (from < to) {
      this.props.value.from = "";
      this.props.value.to = "";
    }
  }*/
  };
  nextRefSpace = (nextRef: any, e: any) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      let domele = ReactDOM.findDOMNode(nextRef.current);
      if (domele !== null) {
        if ("getElementsByTagName" in domele) {
          window.setTimeout(() => {
            // @ts-ignore
            domele.getElementsByTagName("input")[0].focus();
          }, 10);
        }
      }
    }
  };
  nextRefComma = (nextRef: any, e: any) => {
    if (e.keyCode === 188) {
      e.preventDefault();
      let domele = ReactDOM.findDOMNode(nextRef.current);
      if (domele !== null) {
        if ("getElementsByTagName" in domele) {
          window.setTimeout(() => {
            // @ts-ignore
            domele.getElementsByTagName("input")[0].focus();
          }, 10);
        }
      }
    }
  };
  render() {
    let { onChange, value, style, savename, ovalue, width, ...props } = this.props;
    return (
      <Row className={"DateRangeInput InputCombo"}>
        <OSelectNoborder
          onChange={this.onChange.bind(this, "country")}
          listSize
          value={this.props.ovalue[savename as string]}
          savename={"country"}
          width={1.5}
          allowClear={false}
          height={this.props.height}
          required={this.props.required}
          showSearch={true}
          optionFilterProp={"search"}
        >
          {countryList()
            .getData()
            .map((v: any) => (
              <OurOption value={v.value} search={v.value + " " + this.props.language.l[v.label]}>
                {v.value}
              </OurOption>
            ))}
        </OSelectNoborder>
        <ComboInputVerbinder>
          <div style={{ marginTop: "-2px" }}>-</div>
        </ComboInputVerbinder>
        <OInputNoborder
          placeholder={this.props.language.l["plz"]}
          onChange={this.onChange.bind(this, "plz")}
          listSize
          value={this.props.ovalue[savename as string]}
          savename={"zip"}
          onKeyDown={this.nextRefSpace.bind(this, this.cityRef)}
          outerStyle={{ width: measureText(this.props.value.zip, this.props.height === "small" ? 11 : 14) + 4 + "px", minWidth: "25px" }}
          height={this.props.height}
          required={this.props.required}
          ref={this.plzRef}
        />
        <OInputNoborder
          autoWidth
          placeholder={this.props.language.l["ort"]}
          onChange={this.onChange.bind(this, "city")}
          listSize
          value={this.props.ovalue[savename as string]}
          onKeyDown={this.nextRefComma.bind(this, this.streetRef)}
          savename={"city"}
          outerStyle={{ width: measureText(this.props.value.city, this.props.height === "small" ? 11 : 14) + 4 + "px", minWidth: "25px" }}
          height={this.props.height}
          required={this.props.required}
          ref={this.cityRef}
        />
        <ComboInputVerbinder style={{ paddingRight: "2px" }}>
          <div>,</div>
        </ComboInputVerbinder>
        <OInputNoborder
          autoWidth
          placeholder={this.props.language.l["strasse"]}
          onKeyDown={this.nextRefComma.bind(this, this.numberRef)}
          onChange={this.onChange.bind(this, "street")}
          listSize
          value={this.props.ovalue[savename as string]}
          savename={"street"}
          outerStyle={{ width: measureText(this.props.value.street, this.props.height === "small" ? 11 : 14) + 6 + "px", minWidth: "40px" }}
          height={this.props.height}
          required={this.props.required}
          ref={this.streetRef}
        />
        <OInputNoborder
          autoWidth
          placeholder={this.props.language.l["nr"]}
          onChange={this.onChange.bind(this, "number")}
          listSize
          value={this.props.ovalue[savename as string]}
          savename={"number"}
          outerStyle={{ width: measureText(this.props.value.number, this.props.height === "small" ? 11 : 14) + 4 + "px", minWidth: "25px" }}
          height={this.props.height}
          required={this.props.required}
          ref={this.numberRef}
        />
        <ComboInputVerbinder style={{ flexGrow: 1 }} />
      </Row>
    );
  }
}

@observer
export class OurAdressInputSplitted extends Component<AdressInputPorps & HOCInputProps> {
  @observable clearReady: boolean = false;
  plzRef: any;
  cityRef: any;
  streetRef: any;
  numberRef: any;
  static defaultProps = {
    emptyonenter: false
  };

  constructor(props: any) {
    super(props);
    this.plzRef = React.createRef();
    this.streetRef = React.createRef();
    this.cityRef = React.createRef();
    this.numberRef = React.createRef();
  }
  @action
  onChange = (savename: string, e: any) => {
    this.props.value[savename] = e.target?.value ?? e;
    if (this.props.value.country !== "" && savename === "country") {
      let domele = ReactDOM.findDOMNode(this.plzRef.current);
      if (domele !== null) {
        if ("getElementsByTagName" in domele) {
          window.setTimeout(() => {
            // @ts-ignore
            domele.getElementsByTagName("input")[0].focus();
          }, 10);
        }
      }
    }
    /*
  if (this.props.value.from.length === 5 && this.props.value.to.length === 5) {
    let fromsplitted = this.props.value.from.split(":");
    let from = fromsplitted[0] * 60 + fromsplitted[1];
    let tosplitted = this.props.value.to.split(":");
    let to = tosplitted[0] * 60 + tosplitted[1];
    if (from < to) {
      this.props.value.from = "";
      this.props.value.to = "";
    }
  }*/
  };
  nextRefSpace = (nextRef: any, e: any) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      let domele = ReactDOM.findDOMNode(nextRef.current);
      if (domele !== null) {
        if ("getElementsByTagName" in domele) {
          window.setTimeout(() => {
            // @ts-ignore
            domele.getElementsByTagName("input")[0].focus();
          }, 10);
        }
      }
    }
  };
  nextRefComma = (nextRef: any, e: any) => {
    if (e.keyCode === 188) {
      e.preventDefault();
      let domele = ReactDOM.findDOMNode(nextRef.current);
      if (domele !== null) {
        if ("getElementsByTagName" in domele) {
          window.setTimeout(() => {
            // @ts-ignore
            domele.getElementsByTagName("input")[0].focus();
          }, 10);
        }
      }
    }
  };
  render() {
    let { onChange, value, style, savename, ovalue, width, ...props } = this.props;
    return (
      <Row>
        <OSelect
          value={this.props.ovalue[savename as string]}
          savename={"country"}
          width={1.5}
          allowClear={false}
          height={this.props.height}
          required={this.props.required}
          showSearch={true}
          label={this.props.language.l["Land"]}
        >
          {countryList()
            .getData()
            .map((v: any) => (
              <OurOption value={v.value} search={v.value + " " + v.label}>
                {v.value}
              </OurOption>
            ))}
        </OSelect>
        <OInput
          label={this.props.language.l["plz"]}
          onChange={this.onChange.bind(this, "plz")}
          value={this.props.ovalue[savename as string]}
          savename={"plz"}
          width={2}
          height={this.props.height}
          required={this.props.required}
          ref={this.plzRef}
        />
        <OInput
          width={5}
          label={this.props.language.l["ort"]}
          value={this.props.ovalue[savename as string]}
          savename={"city"}
          height={this.props.height}
          required={this.props.required}
          ref={this.cityRef}
        />

        <OInput
          width={5.5}
          label={this.props.language.l["strasse"]}
          value={this.props.ovalue[savename as string]}
          savename={"street"}
          height={this.props.height}
          required={this.props.required}
          ref={this.streetRef}
        />
        <OInput
          width={1.5}
          label={this.props.language.l["nr"]}
          value={this.props.ovalue[savename as string]}
          savename={"number"}
          height={this.props.height}
          required={this.props.required}
          ref={this.numberRef}
        />
      </Row>
    );
  }
}
