import React, { Component } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps, Switch, withRouter } from "react-router-dom";
import lscache from "lscache";
import "./App.scss";
import { Backendschnittstelle } from "./backend";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import hintergrund from "./Images/backgroundimage.jpg";
import logo from "./Images/ziegler_logo.png";

import { OButton, OInput } from "./Framework/FormElements/FormElements";
import { OurFontAwsomeIcon } from "./Framework/OurComps/Icons/OurFontAwsomeIcon";
import { faList, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { Column as Col, Row } from "./Framework/Stylers/Stylers";
import { ColorEnum } from "./Framework/FormElements/Button";
import { Header } from "./Framework/Header/Header";
import { Footer, FooterItem } from "./Framework/Footer/Footer";
import ONavi, { ONavMenuItem } from "./Framework/Navi/Navi";
import { LanguageClassObject } from "./Framework/Languagechanger/Language";
import Cookie from "./Framework/OurComps/Cookie";
import { TableOrders } from "./Testsites/TableOrders";
import Tablescanner from "./Testsites/Tablescanner";
import Details from "./Testsites/Details";
import { OurForm } from "./Framework/FormElements/Form";

const LanguageClass: LanguageClassObject = new LanguageClassObject();

const yearanzeige = new Date().getFullYear();
var bs = new Backendschnittstelle({ l: LanguageClass, withLocalStorage: false });
interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
}
const Valuerenderer = observer((props: any) => {
  return <div>{props.value[props.savename]}</div>;
});

class ProtectedRoute extends Route<ProtectedRouteProps> {
  public render() {
    let redirectPath: string = "";
    if (!this.props.isAuthenticated) {
      redirectPath = this.props.authenticationPath;
    }

    if (redirectPath) {
      const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
      return <Route {...this.props} component={renderComponent} render={undefined} />;
    } else {
      return <Route {...this.props} />;
    }
  }
}

interface OwnProps extends RouteComponentProps {}

type Props = OwnProps;

type State = Readonly<{
  loggedIn: boolean;
  user?: any;
  navopen: boolean;
}>;

class Adresse {
  @observable plz: string[5] = "";
  @observable ort: string = "";

  constructor(plz = "67823", ort = "obermoschel") {
    this.plz = plz;
    this.ort = ort;
  }
}

class User {
  @observable username: string = "hans";
  @observable nachname: string = "WURST";
  @observable adressen: Adresse[] = [new Adresse()];
  key: number;

  @computed get fullname() {
    return this.username + " " + this.nachname;
  }
  constructor(username: string, nachname: string, adresse: Adresse, key: number) {
    this.username = username;
    this.nachname = nachname;
    this.adressen = [adresse];
    this.key = key;
  }

  @action addAdresse = (adresse: Adresse, index: number) => {
    this.adressen.splice(index + 1, 0, adresse);
  };

  @action deleteAdresse = (index: number) => {
    this.adressen.splice(index, 1);
  };
}

let testdata1: User[] = [];
for (let i: number = 0; i < 100; i++) {
  testdata1.push(new User("kevin" + i.toString(), "flotrter", new Adresse(), i));
}

@observer
class App extends Component<Props, State> {
  @observable loggedIn: boolean = false;
  @observable scanner: boolean = false;
  @observable logindata: { username: string; passwort: string } = { username: "", passwort: "" };

  mousesuspend = () => {};
  static defaultProps = {};
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    if (lscache.get("loggedIn") === true) {
      this.loggedIn = true;
      this.mousesuspend = this.refreshChache.bind(this, lscache.get("user"));
      document.body.addEventListener("click", this.mousesuspend);
    }
    if (lscache.get("scanner") === true && lscache.get("loggedIn") === true) {
      this.scanner = true;
      this.props.history.push("/TableScanner");
    } else if (lscache.get("loggedIn") === true) {
      this.props.history.push("/TableOrders");
    }
  }
  @action
  login = (): void => {
    if (this.logindata.username === "admin" && this.logindata.passwort === "az1234b") {
      lscache.set("loggedIn", true, 300);
      lscache.set("user", this.logindata.username, 300);
      this.loggedIn = true;
      this.mousesuspend = this.refreshChache.bind(this, this.logindata.username);
      // @ts-ignore
      this.props.history.push("/TableOrders");
      document.body.addEventListener("click", this.mousesuspend);
    } else if (this.logindata.username === "scanner" && this.logindata.passwort === "scanner") {
      lscache.set("loggedIn", true, 300);
      lscache.set("scanner", true, 300);
      lscache.set("user", this.logindata.username, 300);
      this.loggedIn = true;
      this.scanner = true;
      this.mousesuspend = this.refreshChache.bind(this, this.logindata.username);
      this.props.history.push("/TableScanner");
      // @ts-ignore
      document.body.addEventListener("click", this.mousesuspend);
    }
    this.logindata.passwort = "";
    this.logindata.username = "";
  };
  refreshChache = (user: any) => {
    if (!lscache.get("loggedIn")) {
      this.logout();
      return;
    }
    lscache.set("scanner", this.scanner, 300);
    lscache.set("loggedIn", true, 300);
    lscache.set("user", user, 300);
  };
  logout = (): void => {
    document.body.removeEventListener("click", this.mousesuspend);
    lscache.remove("loggedIn");
    lscache.remove("user");
    lscache.remove("scanner");
    this.loggedIn = false;
    this.scanner = false;
  };

  render() {
    // @ts-ignore

    return (
      <>
        {bs.connected ? (
          <div style={{ overflow: "hidden", width: "100%" }}>
            {!this.loggedIn ? (
              <div
                style={{
                  backgroundImage: `url(${hintergrund})`,
                  height: "100vh",
                  padding: 0,
                  margin: 0,
                  overflow: "auto"
                }}
              >
                <div style={{ marginTop: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src={logo} style={{ marginBottom: "4rem" }} />
                  <OurForm onSubmit={this.login}>
                    <OInput value={this.logindata} savename={"username"} label={"Username"} />
                    <OInput value={this.logindata} savename={"passwort"} type={"password"} label={"Passwort"} />
                    <OButton type={"submit"} width={4}>
                      Einloggen
                    </OButton>
                  </OurForm>
                </div>
              </div>
            ) : (
              <>
                {!this.scanner ? (
                  <>
                    <ONavi>
                      <ONavMenuItem key="TableOrders" path="TableOrders" description={"Tabelle Orders"} icon={faList} />

                      {/*<ONavMenuItem key="test" description={"test"} path={"test"} icon={faCogs} />
          <ONavMenuItem description={"test2"} icon={faCog} />*/}

                      {/*<OSubMenu keys={["TableTest", "test4"]} key={"sub1"} description={"Testseiten"} icon={faCarCrash}>
            <ONavMenuItem key="test2" path="TableTest" description={"Tabelle"} icon={faList} />
            <ONavMenuItem description={"test4"} icon={faDelicious} />
          </OSubMenu>*/}
                    </ONavi>
                    <Header>
                      <Row centerVertical={true} fullLine={true} style={{ width: "100%" }}>
                        <Row centerVertical={true} style={{ width: "100%" }}>
                          <div style={{ color: "white", fontSize: "24px", marginLeft: "30px" }}>Bestellverwaltung</div>
                        </Row>

                        <Row centerVertical={true}>
                          <OButton color={ColorEnum.white} width={3} onClick={this.logout}>
                            Ausloggen
                          </OButton>
                        </Row>
                      </Row>
                    </Header>
                  </>
                ) : null}
                <div className={"OContent"} style={{ overflow: "hidden", padding: "6px" }}>
                  {!this.scanner ? (
                    <>
                      <Switch>
                        {/* <ProtectedRoute isAuthenticated={this.state.loggedIn} path="/Startseite" render={() => <Startseite></Startseite>} authenticationPath={"/"} />*/}

                        <ProtectedRoute
                          isAuthenticated={this.loggedIn}
                          path="/TableOrders"
                          render={() => <TableOrders language={LanguageClass} bs={bs}></TableOrders>}
                          authenticationPath={"/"}
                        />

                        {/*<ProtectedRoute
                  isAuthenticated={true}
                  path="/EingabeFelder"
                  render={() => <EingabeFelder language={LanguageClass}></EingabeFelder>}
                  authenticationPath={"/"}
                />
                <ProtectedRoute
                  isAuthenticated={true}
                  path="/SonstigeTests"
                  render={() => <SonstigeTests language={LanguageClass}></SonstigeTests>}
                  authenticationPath={"/"}
                />
                <ProtectedRoute
                  isAuthenticated={true}
                  path="/impliste"
                  render={() => <TableshowImp language={LanguageClass}></TableshowImp>}
                  authenticationPath={"/"}
                />*/}
                      </Switch>
                    </>
                  ) : (
                    <Switch>
                      <ProtectedRoute
                        isAuthenticated={this.loggedIn}
                        path="/TableScanner"
                        render={() => <Tablescanner language={LanguageClass} bs={bs} />}
                        authenticationPath={"/"}
                      />
                      <ProtectedRoute
                        isAuthenticated={this.loggedIn}
                        path="/Details/:ID"
                        render={() => <Details language={LanguageClass} bs={bs} />}
                        authenticationPath={"/"}
                      />
                    </Switch>
                  )}
                </div>
                {!this.scanner ? (
                  <>
                    <Footer>
                      <FooterItem> Ziegler Group</FooterItem>
                      <FooterItem> &copy; 2021</FooterItem>
                    </Footer>
                    <Cookie language={LanguageClass} />
                  </>
                ) : null}
              </>
            )}
          </div>
        ) : (
          <Row centerVertical={true} center style={{ width: "100vw", height: "100vh", backgroundColor: "rgba(0,0,0,0.6)" }}>
            <Col centerhorizontal={true} center={true}>
              <OurFontAwsomeIcon color={"#1890ff"} style={{ fontSize: "100px" }} icon={faSpinner} spin={true} />

              <div style={{ color: "#1890ff", fontSize: "24px", marginTop: "25px" }}>
                {bs.initconnected ? LanguageClass.l["ServerConnectionLost"] : LanguageClass.l["FirstDataLoad"]}
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default withRouter(App);
