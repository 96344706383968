import { observer } from "mobx-react";
import React, { Component, CSSProperties, ReactChildren, ReactNode } from "react";
import { Slider } from "antd";
import { SliderProps } from "antd/es/slider";
import { LanguageClassObject } from "../Languagechanger/Language";

interface RowProps {
  center?: boolean;
  fullLine?: boolean;
  withWrap?: boolean;
  hover?: boolean;
  centerVertical?: boolean;
}

type RowType = RowProps;

export const Row = (props: RowType & React.HTMLProps<HTMLDivElement>) => {
  let justify = "";
  let alignItems = "flex-start";
  if (props.centerVertical) {
    alignItems = "center";
  }
  if (props.center) {
    justify = "center";
  } else if (props.fullLine) {
    justify = "space-between";
  }
  let wrap = "no-wrap";
  if (props.withWrap) {
    wrap = "wrap";
  }
  let cName = "OurRow";
  if (props.hover) {
    cName += " hoverGelb";
  }
  if (props.className !== undefined) {
    cName += " " + props.className;
  }
  let style =
    props.style === undefined
      ? { display: "flex", alignItems: alignItems, justifyContent: justify, flexWrap: wrap }
      : { display: "flex", alignItems: alignItems, justifyContent: justify, flexWrap: wrap, ...props.style };
  return (
    <div {...props} className={cName} style={style as CSSProperties}>
      {props.children}
    </div>
  );
};

export const Br = (props: { children?: ReactNode }) => {
  return (
    <Row centerVertical={true} style={{ whiteSpace: "nowrap", marginTop: "6px", marginBottom: "6px" }}>
      {props.children != undefined && props.children !== null && React.Children.count(props.children) > 0 ? (
        <>
          <div
            style={{
              border: "1px solid lightgrey",
              marginLeft: "6px",
              marginRight: "6px",
              width: "100%",
              height: "0px"
            }}
          />
          <b>{props.children}</b>
          <div
            style={{
              border: "1px solid lightgrey",
              marginLeft: "6px",
              marginRight: "6px",
              width: "100%",
              height: "0px"
            }}
          />
        </>
      ) : (
        <div
          style={{
            border: "1px solid lightgrey",
            margin: "6px",
            width: "100%",
            height: "0px"
          }}
        />
      )}
    </Row>
  );
};

interface ColProps {
  center?: boolean;
  fullLine?: boolean;
  withWrap?: boolean;
  centerhorizontal?: boolean;
}

type ColType = ColProps;

export const Column = (props: ColType & React.HTMLProps<HTMLDivElement>) => {
  let justify = "";
  let align = "";
  let wrap = "no-wrap";
  if (props.withWrap) {
    wrap = "wrap";
  }
  if (props.center) {
    justify = "center";
  } else if (props.fullLine) {
    justify = "space-between";
  }
  if (props.centerhorizontal) {
    align = "center";
  }
  let style =
    props.style === undefined
      ? {
          flexWrap: wrap,
          display: "flex",
          justifyContent: justify,
          flexDirection: "column",
          alignItems: align
        }
      : {
          flexWrap: wrap,
          display: "flex",
          justifyContent: justify,
          flexDirection: "column",
          alignItems: align,
          ...props.style
        };
  return (
    <div {...props} style={style as CSSProperties}>
      {props.children}
    </div>
  );
};
