import React, { Component, ReactNode, FC } from "react";
import { Menu, Icon, Button, Layout } from "antd";
import { withRouter, Link, NavLink, RouteComponentProps } from "react-router-dom";

import { observer } from "mobx-react";
import { action, observable } from "mobx";
import SubMenu from "antd/es/menu/SubMenu";
import { OurFontAwsomeIcon } from "../OurComps/Icons/OurFontAwsomeIcon";
import { faBars, faCog, faSave } from "@fortawesome/free-solid-svg-icons";
import { Row } from "../Stylers/Stylers";
import "./Navi.scss";
import { faPiedPiper } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SubMenuProps } from "antd/lib/menu/SubMenu";
import { OButton } from "../../Framework/FormElements/FormElements";

interface OwnProps2 extends RouteComponentProps {}

type Naviprops = OwnProps2;
@observer
class ONavi extends Component<Naviprops> {
  @observable collapsed: boolean = true;
  @action toggleCollapsed = () => {
    this.collapsed = !this.collapsed;
  };
  render() {
    return (
      <div style={{ width: this.collapsed ? 50 : 256, height: "100%" }} className={"NavBarHolder"}>
        <Row center style={{ height: "45px", alignItems: "center", backgroundColor: "#1890ff", width: "50px" }}>
          <OButton onClick={this.toggleCollapsed} style={{ width: "46px" }}>
            <OurFontAwsomeIcon color={"white"} icon={faBars} />
          </OButton>
        </Row>
        <Menu
          selectedKeys={[this.props.location.pathname.split("/")[1]]}
          style={{ height: "100%" }}
          mode="inline"
          theme="dark"
          inlineCollapsed={this.collapsed}
        >
          {this.props.children}
        </Menu>
      </div>
    );
  }
}

/*
<Layout.Sider style={{ overflow: "auto", position: "fixed", height: "calc(100vh - 60px)", marginTop: "60px", padding: "10px" }}>
    <Menu
        mode={"inline"}
        theme={"dark"}
        style={{ borderTop: "1px solid lightgrey", marginTop: "25px" }}
        selectedKeys={["/" + this.props.location.pathname.split("/")[1]]}
        forceSubMenuRender
    >
        {this.props.children}
    </Menu>
</Layout.Sider>
interface OwnPropsSubmenu {
  title: string;
  icon?: ReactNode;
}

export const SubMenu: FC<OwnPropsSubmenu> = props => {
  return (
    <Menu.SubMenu
      {...props}
      style={{ borderBottom: "1px solid lightgrey" }}
      title={
        props.icon !== undefined ? (
          <div>
            {props.icon}
            <span>{props.title}</span>
          </div>
        ) : (
          props.title
        )
      }
    >
      {props.children}
    </Menu.SubMenu>
  );
};
*/
interface OwnPropsSubMenu extends SubMenuProps, RouteComponentProps {
  description: string;
  icon: IconProp;
  onClick?: () => void;
  keys: string[];
}

const InnerSubMenu: FC<OwnPropsSubMenu> = props => {
  return (
    <div className={props.keys.includes(props.location.pathname.split("/")[1]) ? "ant-menu-submenu-selected" : ""}>
      <SubMenu
        {...props}
        title={
          <span>
            <div style={{ paddingTop: "10px" }} className={"anticon"}>
              <OurFontAwsomeIcon icon={props.icon} />
            </div>
            <span style={{ fontSize: "16px" }}>{props.description}</span>
          </span>
        }
      >
        {props.children}
      </SubMenu>
    </div>
  );
};
export const OSubMenu = withRouter(InnerSubMenu);

interface OwnPropsMenuItem {
  description: string;
  icon: IconProp;
  onClick?: (pathName?: string) => void;
  path?: string;
}

export const ONavMenuItem: FC<OwnPropsMenuItem> = props => {
  return (
    <Menu.Item
      {...props}
      style={{ borderBottom: "1px solid lightgrey", margin: 0 }}
      onClick={() => {
        if (props.onClick !== undefined) props.onClick(props.path !== undefined ? props.path : "");
      }}
    >
      <>
        {props.path !== undefined ? (
          <NavLink style={{ color: "white" }} to={props.path}>
            <div style={{ paddingTop: "10px" }} className={"anticon"}>
              <OurFontAwsomeIcon icon={props.icon} />
            </div>
            <span style={{ fontSize: "16px" }}>{props.description}</span>
          </NavLink>
        ) : (
          <>
            <div style={{ paddingTop: "10px" }} className={"anticon"}>
              <OurFontAwsomeIcon icon={props.icon} />
            </div>
            <span style={{ fontSize: "16px" }}>{props.description}</span>
          </>
        )}
      </>
    </Menu.Item>
  );
};

export default withRouter(ONavi);
