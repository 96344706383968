import React, { FC, StyleHTMLAttributes } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";

interface OwnPropsOurFontAwsomeIcon {
  icon: IconProp;
  color?: string;
  style?: any;
  onClick?: () => void;
  size?: SizeProp;
  pulse?: boolean;
  spin?: boolean;
}

type PropsOurFontAwsomeIcon = OwnPropsOurFontAwsomeIcon;

export const OurFontAwsomeIcon: FC<PropsOurFontAwsomeIcon> = observer(props => {
  return (
    <>
      <div
        style={{ display: "flex" }}
        onClick={() => {
          if (props.onClick !== undefined) props.onClick();
        }}
      >
        <div style={{ width: "2em", display: "flex", justifyContent: "center" }}>
          <FontAwesomeIcon
            className={"OIconInner"}
            spin={props.spin}
            pulse={props.pulse}
            style={props.style || {}}
            icon={props.icon}
            size={props.size !== undefined ? props.size : "lg"}
            color={props.color || "white"}
          />
        </div>
        {props.children}
      </div>
    </>
  );
});
/*@observer
export class OurFontAwsomeIcon extends React.Component<PropsOurFontAwsomeIcon> {
  render() {
    return (
      <>
        <div
          style={{ display: "flex" }}
          onClick={() => {
            if (this.props.onClick !== undefined) this.props.onClick();
          }}
        >
          <div style={{ width: "2em", display: "flex", justifyContent: "center" }}>
            <FontAwesomeIcon
              spin={this.props.spin}
              pulse={this.props.pulse}
              style={this.props.style || {}}
              icon={this.props.icon}
              size={this.props.size !== undefined ? this.props.size : "lg"}
              color={this.props.color || "white"}
            />
          </div>
          {this.props.children}
        </div>
      </>
    );
  }
}*/
