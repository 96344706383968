import { SelectProps } from "antd/es/select";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { AutoComplete, Icon, Select } from "antd";

import { HOCInputProps } from "./InputHOC";
import { AutocompleteProps } from "@react-google-maps/api";
import { AutoCompleteProps } from "antd/es/auto-complete";
import { action, computed, observable } from "mobx";
import Dragger from "antd/es/upload/Dragger";
import { DraggerProps } from "antd/lib/upload";
import axios from "axios";
interface OwnProps3 extends DraggerProps {
  header?: string;
  text?: string;
  ovalue?: any;
}

type Props3 = OwnProps3;

@observer
export class OurFileUpload extends Component<Props3 & HOCInputProps> {
  static defaultProps = {
    action: "/fileUpload"
  };

  constructor(props: any) {
    super(props);
  }

  @action
  changeUpload = (info: any) => {
    if (info.file.status === "done") {
      let file = { url: info.file.response.url, name: info.file.name, uid: info.file.uid };
      if (this.props.multiple) {
        let index = this.props.ovalue[this.props.savename].findIndex((v: any) => v.uid === info.file.uid);
        if (index !== -1) {
          this.props.ovalue[this.props.savename][index] = file;
        }
      } else {
        this.props.ovalue[this.props.savename] = file;
      }
    } else {
      if (this.props.multiple) {
        let index = this.props.ovalue[this.props.savename].findIndex((v: any) => v.uid === info.file.uid);
        if (index !== -1) {
          this.props.ovalue[this.props.savename][index] = info.file;
        } else this.props.ovalue[this.props.savename].push(info.file);
      } else {
        if (this.props.value !== undefined && this.props.value !== null && this.props.value !== "" && this.props.value.uid !== info.file.uid) {
          this.onRemove(this.props.ovalue[this.props.savename]);
        }
        this.props.ovalue[this.props.savename] = info.file;
      }
    }
  };
  @action
  onRemove = (e: any) => {
    console.log("hi");
    axios.post("/fileDelete", { url: e.url }, {});
    if (this.props.multiple) {
      let index = this.props.ovalue[this.props.savename].findIndex((v: any) => v.uid === e.uid);
      if (index !== -1) {
        this.props.ovalue[this.props.savename].splice(index, 1);
      }
    } else {
      this.props.ovalue[this.props.savename] = "";
    }
    return false;
  };
  render() {
    let { fileList, value, onChange, onRemove, ...props } = this.props;
    return (
      <Dragger
        onRemove={this.onRemove}
        onChange={this.changeUpload}
        fileList={
          this.props.multiple
            ? this.props.value.slice()
            : this.props.value !== undefined && this.props.value !== null && this.props.value !== ""
            ? [this.props.value]
            : []
        }
        {...props}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="upload" />
        </p>
        <p className="ant-upload-text">{props.header}</p>
        <p className="ant-upload-hint">{props.text}</p>
      </Dragger>
    );
  }
}
