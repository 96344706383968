import { SelectProps } from "antd/es/select";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { AutoComplete, Select } from "antd";
import { OptionProps } from "antd/lib/select";
import { HOCInputProps } from "./InputHOC";
import { AutocompleteProps } from "@react-google-maps/api";
import { AutoCompleteProps } from "antd/es/auto-complete";
import { action, computed, observable } from "mobx";

interface OwnProps extends SelectProps {
  required?: boolean;
}

type Props = OwnProps;

@observer
export class OurSelect extends Component<HOCInputProps & Props> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <Select showAction={["click", "focus"]} notFoundContent={""} showArrow={false} allowClear={!this.props.required} {...this.props} />
        <input required={this.props.required} style={{ display: "none" }} value={this.props.value} />
      </>
    );
  }
}

interface OwnProps2 extends OptionProps {
  search?: string;
}

type Props2 = OwnProps2;

@observer
export class OurOption extends Component<Props2> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return <Select.Option {...this.props} />;
  }
}
interface OwnProps3 extends AutoCompleteProps {
  dataSource: any;
  minLength?: number;
}

type Props3 = OwnProps3;

@observer
export class OurAutocomplete extends Component<Props3 & HOCInputProps> {
  @computed get dataSource() {
    return this.props.value === undefined
      ? []
      : this.props.dataSource.filter((datascource: string) => {
          return datascource.toUpperCase().indexOf(this.props.value.toUpperCase()) !== -1 && this.props.value.length >= (this.props.minLength as number);
        });
  }
  static defaultProps = {
    minLength: 3
  };

  constructor(props: any) {
    super(props);
  }
  filterOptions = (inputValue: string, option: any) => {};
  render() {
    let { dataSource, ...props } = this.props;
    return (
      <>
        <AutoComplete dataSource={this.dataSource} {...props} />
        <input required={this.props.required} style={{ display: "none" }} value={this.props.value} />
      </>
    );
  }
}
