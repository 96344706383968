import { observer } from "mobx-react";
import React, { Component } from "react";
import { action, computed, observable } from "mobx";

import { OurFontAwsomeIcon } from "../Framework/OurComps/Icons/OurFontAwsomeIcon";
import { faBarcode, faCircle, faFileExport, faTrash } from "@fortawesome/free-solid-svg-icons";
import { LanguageClassObject } from "../Framework/Languagechanger/Language";
import { Column, OTable, Searchenum } from "../Framework/Table/Table";
import { Backendschnittstelle } from "../backend";
import { Artikel, OrderItem, Orders } from "../models/Order";

import { ourParseFloat, toDateFormat, toMoneyFormat, toNumberFormat } from "../Framework/Helperfunctions/Formatter";
import { Row } from "../Framework/Stylers/Stylers";
import { NumberTypes } from "../Framework/FormElements/Input";
import { ONumberInput } from "../Framework/FormElements/FormElements";
import axios from "axios";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { HashRouterProps, RouteComponentProps, withRouter } from "react-router-dom";

interface OwnProps2 {
  language: LanguageClassObject;
  bs: Backendschnittstelle;
}

type TableProps = OwnProps2;
@observer
class TablescannerInner extends Component<TableProps & RouteComponentProps> {
  @observable checkBoxArray: string[] = [];
  @observable columns: Column[] = [];
  @observable columns2: Column[] = [];
  @observable orders: OrderItem[] | undefined;
  @computed get tabledata() {
    if (this.orders === undefined) return [];
    else {
      /*console.log(
        "chnagefirststep",
        this.orders.filter((o: OrderItem) => {
          return true;
          //return o.sender.includes("Hornbach") && o.deliverypartygln === "4306517004804";
          //return o.sender.includes("Hornbach") && o.deliverypartygln === "4306517004804" && moment(o.requesteddeliverydate).isAfter("2019-12-10");
        })
      );*/
      return this.orders.filter((o: OrderItem) => {
        //return true;

        // console.log(o.NVEFile !== "");
        return o.NVEFile !== "";
        //return o.sender.includes("Hornbach") && o.deliverypartygln === "4306517004804";
        //return o.sender.includes("Hornbach") && o.deliverypartygln === "4306517004804" && moment(o.requesteddeliverydate).isAfter("2019-12-10");
      });
    }
  }
  ordersDB: Orders | undefined;
  static defaultProps = {};

  constructor(props: TableProps & RouteComponentProps) {
    super(props);
    this.props.bs.getDataset("Orders").then((v: any) => {
      this.ordersDB = v;
      this.orders = (v as Orders).getValue();
      console.log(this.tabledata);
    });

    this.columns2.push(
      {
        key: "kurztext",
        name: "Artikel",
        activ: true,
        width: 150
      },
      {
        key: "gtin",
        name: "EAN",
        activ: true,
        style: { textAlign: "right" },
        width: 90
      },

      {
        key: "pakete",
        name: "Pakete",
        formatter: (value, row) => ourParseFloat(row.NVEs.length) + " / " + ourParseFloat(row.lieferbar),
        activ: true,
        style: { textAlign: "right" },
        width: 60
      },
      {
        key: "actions",
        name: " ",
        activ: true,
        formatter: (value, row) => <OurFontAwsomeIcon icon={faTrash} color={"red"} onClick={this.delVerNVE.bind(this, row)} />,
        width: 30
      }
    );
    this.columns.push(
      {
        key: "ordernumberbuyer",
        name: "BeestellNr.",
        searchfunc: Searchenum.includesString,
        activ: true,
        style: { textAlign: "right" },
        width: 80
      },
      {
        key: "requesteddeliverydate",
        name: "Datum",
        formatter: (value, row) => toDateFormat(value, this.props.language.dateFormat),
        activ: true,
        searchfunc: Searchenum.includesString,
        style: { textAlign: "right" },
        width: 65
      },
      {
        key: "buyer",
        name: "Besteller",
        searchfunc: Searchenum.includesString,
        activ: true,
        width: 120
      },
      {
        key: "marktnummer",
        name: "Nr.",
        searchfunc: Searchenum.includesString,
        activ: true,
        width: 30
      },
      {
        key: "deliverypartycity",
        name: "Anlieferung",
        searchfunc: Searchenum.includesString,
        activ: true,
        width: 100
      },
      {
        key: "pakete",
        name: "Pak.",
        formatter: (value, row) => row.totalpacksdelivered + " / " + row.totallieferbar,
        activ: true,
        style: { textAlign: "right" },
        width: 40
      },
      {
        key: "m3packages",
        name: "m3",
        activ: true,
        style: { textAlign: "right" },
        width: 40
      }
    );
  }

  componentWillUnmount() {
    if (this.ordersDB !== undefined) this.ordersDB.abortSave();
  }

  openTour = (tour: string) => {};
  @action delVerNVE = (row: Artikel) => {
    if (this.tabledata !== undefined) {
      let item = this.tabledata.find(oitem => {
        return oitem.article.findIndex(aitem => aitem.ID === row.ID) !== -1 ? true : false;
      });

      //      alert(item);
      if (item !== undefined) {
        row.NVEs.map(nve => {
          // @ts-ignore
          item.NVEList.push(nve);
        });
      }
    }
    row.NVEs.splice(0, row.NVEs.length);
    if (this.ordersDB !== undefined) this.ordersDB.save();
  };
  @action goToDetails = (row: OrderItem) => {
    this.props.history.push("/Details/" + row.ID);
  };
  render() {
    return this.orders !== undefined ? (
      <>
        <OTable
          language={this.props.language}
          columns={this.columns}
          data={this.tabledata}
          actionWidth={35}
          extensible={(row: OrderItem) => (
            <div style={{ margin: "10px" }}>
              <OTable
                language={this.props.language}
                pagination={false}
                customizeName={"OrdersInnerTable"}
                columns={this.columns2}
                data={row.article}
                size={"small"}
              />
            </div>
          )}
          getSubTableData={(row: any) => {
            return [this.columns2, row.article];
          }}
          size={"small"}
          searchtype={"and"}
          actionRenderer={(row: OrderItem) => {
            return (
              <Row>
                {row.totalpacksdelivered < row.totallieferbar ? (
                  <OurFontAwsomeIcon style={{ cursor: "pointer" }} onClick={this.goToDetails.bind(this, row)} color={"black"} icon={faBarcode} />
                ) : null}
              </Row>
            );
          }}
        />
      </>
    ) : null;
  }
}
// @ts-ignore
export default withRouter(TablescannerInner);
