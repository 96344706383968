import React, { Component, ReactElement, ReactNode } from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";

function recursiveMap(children: any, fn: any) {
  return React.Children.map(children, (child: any) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    // @ts-ignore
    if (child.props.children) {
      child = React.cloneElement(child, {
        // @ts-ignore
        children: recursiveMap(child.props.children, fn)
      });
    }

    return fn(child);
  });
}

interface OwnProps {
  children: ReactNode;
  onSubmit: () => void;
  submitted?: boolean;
}

type Props = OwnProps;

@observer
export class OurForm extends Component<Props> {
  @observable submitted: boolean = false;
  static defaultProps = {
    onSubmit: () => {}
  };

  constructor(props: any) {
    super(props);
  }

  @action
  submit = (originalSubmit: () => void) => {
    this.submitted = true;
    if (originalSubmit != undefined) originalSubmit();
  };

  @action
  submitexec = (e: any) => {
    e.preventDefault();
    this.submitted = false;
    this.props.onSubmit();
  };
  render() {
    return (
      <form onSubmit={this.submitexec} className={this.submitted || this.props.submitted ? "submitted" : ""}>
        {recursiveMap(this.props.children, (child: any) => {
          if (React.isValidElement(child)) {
            // @ts-ignore
            return child.props.type === "submit"
              ? React.cloneElement(child as ReactElement, {
                  onClick: this.submit.bind(this, (child as ReactElement).props.onClick)
                })
              : child;
          } else return child;
        })}
      </form>
    );
  }
}
