import { CheckboxProps } from "antd/es/checkbox";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Checkbox, Switch } from "antd";
import { SwitchProps } from "antd/es/switch";
import { HOCInputProps } from "./InputHOC";

interface OwnProps2 extends CheckboxProps {}

type CheckBoxProps = OwnProps2;
@observer
export class OurCheckbox extends Component<HOCInputProps & CheckBoxProps> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    const { value, onKeyDown, ...props } = this.props;
    return (
      <div style={{ marginLeft: "4px" }}>
        <Checkbox checked={value} {...props} />
      </div>
    );
  }
}

interface OwnPropsSwitch extends SwitchProps {
  checkedSign?: string;
  uncheckedSign?: string;
}

type MYSwitchProps = OwnPropsSwitch;
@observer
export class OurSwitch extends Component<HOCInputProps & MYSwitchProps> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    // @ts-ignore
    const { value, onKeyDown, style, ...props } = this.props;
    return (
      <div style={{ width: "60px", marginLeft: "4px" }}>
        <Switch checkedChildren={this.props.checkedSign ?? ""} unCheckedChildren={this.props.uncheckedSign ?? ""} checked={value} {...props} />
      </div>
    );
  }
}
