import React, { Component, FC } from "react";

import lscache from "lscache";

import { Col, Popover, Row } from "antd";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { $enum } from "ts-enum-util";
import { registerLocale } from "react-datepicker";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import { DateTypes } from "../Helperfunctions/Formatter";

registerLocale("de-DE", require("date-fns/locale/de"));
registerLocale("en-GB", require("date-fns/locale/en-GB"));

const lObjekt = {
  DE: {
    DE: "Deutschland",
    GB: "Germany"
  },
  Germany: {
    DE: "Deutschland",
    GB: "Germany"
  },
  navileft_Stammdaten: {
    DE: "Stammdaten",
    GB: "Base data"
  },
  navileft_Userstammdaten: {
    DE: "User",
    GB: "User"
  },
  navileft_Gststammdaten: {
    DE: "Geschäftsstellen",
    GB: "Branch offices"
  },
  navileft_Importlist: {
    DE: "Import Liste",
    GB: "Import list"
  },
  navileft_Exportlist: {
    DE: "Export Liste",
    GB: "Export list"
  },
  navileft_Neuerimport: {
    DE: "Neuer Import Auftrag",
    GB: "New import order"
  },
  navileft_Neuerexport: {
    DE: "Neuer Export Auftrag",
    GB: "New export order"
  },
  userstamm_Name: {
    DE: "Name",
    GB: "Name"
  },
  userstamm_Email: {
    DE: "Email",
    GB: "Email"
  },
  userstamm_Phone: {
    DE: "Telefon",
    GB: "Phone"
  },
  userstamm_Fax: {
    DE: "Fax",
    GB: "Fax"
  },
  userstamm_Gsts: {
    DE: "Geschäftsstellen",
    GB: "Branchoffices"
  },
  userstamm_Countries: {
    DE: "Länder",
    GB: "Countries"
  },
  userstamm_Firstname: {
    DE: "Vorname",
    GB: "Firstname"
  },
  userstamm_Lastname: {
    DE: "Nachname",
    GB: "Lastname"
  },
  userstamm_Tools: {
    DE: "Anwendungen",
    GB: "Applications"
  },
  userstamm_Neueruser: {
    DE: "User anlegen",
    GB: "New User"
  },
  userstamm_Position: {
    DE: "Position",
    GB: "Position"
  },
  gststamm_Neuegst: {
    DE: "Geschäftsstelle anlegen",
    GB: "New Branch office"
  },
  gststamm_Gstdetails: {
    DE: "Geschäftsstellendetails",
    GB: "Branch office details"
  },
  gststamm_Gstname: {
    DE: "Geschäftsstelle",
    GB: "Branch office"
  },
  gststamm_Gstcode: {
    DE: "GST Code",
    GB: "Branch office code"
  },
  gststamm_Gstadresse: {
    DE: "Adresse",
    GB: "Address"
  },
  gststamm_Gsttools: {
    DE: "Anwendungen",
    GB: "Applications"
  },
  gststamm_Gstcountry: {
    DE: "Land",
    GB: "Country"
  },
  gststamm_Gstplz: {
    DE: "PLZ",
    GB: "ZIP"
  },
  gststamm_Gstort: {
    DE: "Ort",
    GB: "City"
  },
  gststamm_Gststrasse: {
    DE: "Strasse",
    GB: "Street"
  },
  gststamm_Gsthnummer: {
    DE: "Nummer",
    GB: "Number"
  },
  gststamm_Gstemail: {
    DE: "EMail",
    GB: "EMail"
  },
  gststamm_Gsttelefon: {
    DE: "Telefon",
    GB: "Phone"
  },
  gststamm_Gstfax: {
    DE: "Fax",
    GB: "Fax"
  },

  fax: {
    DE: "Fax",
    GB: "Fax"
  },
  position: {
    DE: "Position",
    GB: "Position"
  },

  rechteundverantwortlichkeiten: {
    DE: "Rechte",
    GB: "Rights"
  },
  lastname: {
    DE: "Nachname",
    GB: "Lastname"
  },
  NeuesPasswort: {
    DE: "Neues Passwort",
    GB: "New Password"
  },
  name: {
    DE: "Name",
    GB: "Name"
  },
  email: {
    DE: "Email",
    GB: "Email"
  },

  countries: {
    DE: "Länder",
    GB: "Countries"
  },
  Benutzerdetails: {
    DE: "Benutzerdetails",
    GB: "User details"
  },
  Stammdaten: {
    DE: "Adressdaten",
    GB: "Address data"
  },
  Einloggen: {
    DE: "Einloggen",
    GB: "Login"
  },
  Benutzername: {
    DE: "Benutzer",
    GB: "User"
  },
  FalscherLogin: {
    DE: "Benutzername oder Passwort falsch",
    GB: "Username or password incorrect"
  },
  Passwortvergessen: {
    DE: "Passwort vergessen?",
    GB: "Forgot password"
  },
  MessagePasswortVergessen: {
    DE: "Bitte wenden Sie sich an Ihren Administrator",
    GB: "Please contact your administrator"
  },
  PoolStrand: {
    DE: "Pool / Strand",
    GB: "Pool / Beach"
  },
  Dashboard: {
    DE: "Dashboard",
    GB: "Dashboard"
  },
  LiegenEquipment: {
    DE: "Strandliegen...",
    GB: "Beach couch..."
  },
  BuchungGast: {
    DE: "Buchung für Gast",
    GB: "Book for a guest"
  },
  User: {
    DE: "User",
    GB: "User"
  },
  AnsichtTag: {
    DE: "Ansicht Tagesbuchungen",
    GB: "View daily bookings"
  },
  HotelStrandName: {
    DE: "Hotelname oder Strandname",
    GB: "Hotel name or Beachname"
  },
  AdresseStandort: {
    DE: "Adresse des Standortes",
    GB: "Adress of the location"
  },
  RechnungsInfo: {
    DE: "Rechnungsinformationen",
    GB: "Billing information"
  },
  Firma: {
    DE: "Firma",
    GB: "Company"
  },
  USTID: {
    DE: "Ust. ID",
    GB: "VAT ID"
  },
  UserAnlage: {
    DE: "User Anlage",
    GB: "User administration"
  },
  NeuerUser: {
    DE: "User hinzufügen",
    GB: "Add account"
  },
  NeuerPool: {
    DE: "Pool / Strand hinzufügen",
    GB: "Add pool / beach"
  },
  WählePool: {
    DE: "Wähle Pool / Strand",
    GB: "Choose pool / beach"
  },
  Pool: {
    DE: "Pool",
    GB: "Pool"
  },
  Strand: {
    DE: "Strand",
    GB: "Beach"
  },
  Freifläche: {
    DE: "andere Liegebreiche",
    GB: "other lying areas"
  },
  PoolName: {
    DE: "Pool / Strand Name",
    GB: "Pool / Beach name"
  },
  Equipment: {
    DE: "Equipment",
    GB: "Equipment"
  },
  Bereiche: {
    DE: "Bereiche",
    GB: "Area"
  },
  LoginName: {
    DE: "Login",
    GB: "Login"
  },
  Passwort: {
    DE: "Passwort",
    GB: "Password"
  },
  Vorname: {
    DE: "Vorname",
    GB: "First name"
  },
  Nachname: {
    DE: "Nachname",
    GB: "Surname"
  },
  Speichern: {
    DE: "Speichern",
    GB: "Save"
  },
  SpracheWahl: {
    DE: "Sprache Wählen",
    GB: "Select Language"
  },
  Anmeldung: {
    DE: "Anmeldung",
    GB: "Registration"
  },
  AdressInfo: {
    DE: "Adresse",
    GB: "Address"
  },
  DatenKontrolle: {
    DE: "Datenkontrolle",
    GB: "Check data"
  },
  ZugangAdmin: {
    DE: "Zugangsdaten",
    GB: "Access data"
  },
  VorNachname: {
    DE: "Vor- und Nachname",
    GB: "For and last name"
  },
  EmailAdresse: {
    DE: "E-Mail Adresse",
    GB: "EMail address"
  },
  UserRechte: {
    DE: "User Rechte",
    GB: "User rights"
  },
  AllesAuswählen: {
    DE: "Alles auswählen",
    GB: "Choose all"
  },
  RegBetreiber: {
    DE: "Hotel / Strandbetreiber / Firma",
    GB: "Hotel / Strandbetreiber / Firma"
  },
  Land: {
    DE: "Land",
    GB: "Country"
  },
  PLZ: {
    DE: "PLZ",
    GB: "Zip code"
  },
  StrasseNummer: {
    DE: "Straße, Hausnummer",
    GB: "Street, Number"
  },
  Ort: {
    DE: "Ort",
    GB: "City"
  },
  Telefon: {
    DE: "Telefon",
    GB: "Phone"
  },
  Weiter: {
    DE: "Weiter",
    GB: "Next"
  },
  Okay: {
    DE: "Okay !",
    GB: "Okay !"
  },
  Zurück: {
    DE: "Zurück",
    GB: "Back"
  },
  Senden: {
    DE: "Senden",
    GB: "Send"
  },
  AnmStep0_1: {
    DE: "Geben Sie Ihren Gästen die Möglichkeit:",
    GB: "Bitte Daten prüfen Ü"
  },
  AnmStep0_2: {
    DE: "Strandkörbe , Strand- und Poolliegen",
    GB: "Bitte Daten prüfen Ü"
  },
  AnmStep0_3: {
    DE: "jederzeit online zu buchen.",
    GB: "Bitte Daten prüfen Ü"
  },
  AnmStep1: {
    DE: "Bitte Daten prüfen",
    GB: "Bitte Daten prüfen Ü"
  },
  AnmStep2: {
    DE: "Bitte Daten prüfen",
    GB: "Bitte Daten prüfen Ü"
  },
  AnmStep2_1: {
    DE: "Bitte Daten prüfen",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_Ü: {
    DE: "Berechnen Sie Ihren Gewinn",
    GB: "Berechnen Sie Ihren Gewinn"
  },
  Kalk_1: {
    DE: "Gesamt Liegen",
    GB: "Liegen Gesamt"
  },
  Kalk_2: {
    DE: "Mietliegen in %",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_3: {
    DE: "Anzahl Mietliegen",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_4: {
    DE: "Preis pro Mietliege",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_5: {
    DE: "Kommision",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_6: {
    DE: "Ihr Gewinn/Tag",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_7: {
    DE: "Ihr Gewinn/Monat",
    GB: "Bitte Daten prüfen Ü"
  },
  Vorteile: {
    DE: "Vorteile",
    GB: "Vorteile"
  },
  AdminTool: {
    DE: "Admin Tool",
    GB: "Admin Tool"
  },
  Impressum: {
    DE: "Impressum",
    GB: "Impressum"
  },
  Kalkulator: {
    DE: "Kalkulator",
    GB: "Kalkulator"
  },

  SucheHotel: {
    DE: "Suche nach Hotel / Strand / Ort",
    GB: "Search for Hotel / Beach / City"
  },
  Datengespeichert: {
    DE: "Ihre Daten wurden gespeichert",
    GB: "Your data has been saved"
  },
  Pflichtfelder: {
    DE: "Bitte füllen Sie zuerst alle Pflichtfelder",
    GB: "Please fill up all required fields"
  },
  Datengelöscht: {
    DE: "Ihre Daten wurden gelöscht",
    GB: "Your data has been deleted"
  },
  WirklichLöschen: {
    DE: "Datensatz löschen?",
    GB: "Delete dataset?"
  },
  MO: {
    DE: "Mo",
    GB: "Mo"
  },
  DI: {
    DE: "Di",
    GB: "Tu"
  },
  MI: {
    DE: "Mi",
    GB: "We"
  },
  DO: {
    DE: "Do",
    GB: "Th"
  },
  FR: {
    DE: "Fr",
    GB: "Fr"
  },
  SA: {
    DE: "Sa",
    GB: "Sa"
  },
  SO: {
    DE: "So",
    GB: "Su"
  },
  AuswahlDatum: {
    DE: "Auswahl Datum",
    GB: "Choose date"
  },
  AuswahlLiegen: {
    DE: "Auswahl Liegen",
    GB: "Choose beach couch"
  },
  Auswahl: {
    DE: "Auswahl",
    GB: "Selected"
  },
  AuswahlmitUntertabelle: {
    DE: "Auswahl mit Untertabelle",
    GB: "Selected with subtable"
  },
  SeitemitUntertabelle: {
    DE: "Seite mit Untertabelle",
    GB: "Page with Subtable"
  },
  AllesmitUntertabelle: {
    DE: "Alles mit Untertabelle",
    GB: "All with subtable"
  },
  CSVdownload: {
    DE: "CSV download",
    GB: "CSV download"
  },
  ProSeite: {
    DE: "Pro Seite",
    GB: "Per page"
  },
  Seite: {
    DE: "Seite",
    GB: "Page"
  },
  von: {
    DE: "von",
    GB: "from"
  },
  TabellenEinstellungen: {
    DE: "Tabellen Einstellungen",
    GB: "Table settings"
  },
  Position: {
    DE: "Position",
    GB: "Position"
  },
  Aktiv: {
    DE: "Aktiv",
    GB: "Activ"
  },
  Immeranwenden: {
    DE: "Immer anwenden",
    GB: "Save as standard"
  },
  Nurjeztanwenden: {
    DE: "Nur jezt anwenden",
    GB: "Use only now"
  },
  MitAuswahl: {
    DE: "Mit Auswahl:",
    GB: "With selected:"
  },
  Name: {
    DE: "Name",
    GB: "Name"
  },
  Alles: {
    DE: "Alles",
    GB: "All"
  },
  Ja: {
    DE: "Ja",
    GB: "Yes"
  },
  Nein: {
    DE: "Nein",
    GB: "No"
  },
  test1: {
    DE: "Hallo",
    GB: "Hello"
  },
  WollenSiedenEintragwirklichloeschen: {
    DE: "Wollen Sie den Eintrag wirklich löschen?",

    GB: "Are you sure that you want to delete this entry"
  },
  Januar: {
    DE: "Januar",
    GB: "January"
  },
  Februar: {
    DE: "Februar",
    GB: "February"
  },
  März: {
    DE: "März",
    GB: "March"
  },
  April: {
    DE: "April",
    GB: "April"
  },
  Mai: {
    DE: "Mai",
    GB: "May"
  },
  Juni: {
    DE: "Juni",
    GB: "June"
  },
  Juli: {
    DE: "Juli",
    GB: "July"
  },
  August: {
    DE: "August",
    GB: "August"
  },
  September: {
    DE: "September",
    GB: "September"
  },
  Oktober: {
    DE: "Oktober",
    GB: "October"
  },
  November: {
    DE: "November",
    GB: "November"
  },
  Dezember: {
    DE: "Dezember",
    GB: "December"
  },
  Loeschen: {
    DE: "Löschen",
    GB: "Delete"
  },
  bis: {
    DE: "bis",
    GB: "to"
  },
  ort: {
    DE: "Ort",
    GB: "City"
  },
  strasse: {
    DE: "Straße",
    GB: "Street"
  },
  nummer: {
    DE: "Nummer",
    GB: "Number"
  },
  nr: {
    DE: "Nr.",
    GB: "No."
  },
  plz: {
    DE: "PLZ",
    GB: "ZIP"
  },
  hhmm: {
    DE: "ssmm",
    GB: "hhmm"
  },
  l: {
    DE: "L.",
    GB: "C."
  },
  Datenauswahl: {
    DE: "Daten Auswahl",
    GB: "Data select"
  },
  MitUntertablle: {
    DE: "Mit Untertabelle",
    GB: "With subtable"
  },
  MitSpaltenNamen: {
    DE: "Spaltennamen",
    GB: "Columnnames"
  },
  MitSpaltenNamenUntertablle: {
    DE: "Untertablle Spaltenname",
    GB: "Subtable Columnnames"
  },
  Breite: {
    DE: "Breite",
    GB: "Width"
  },
  Download: {
    DE: "Download",
    GB: "Download"
  },
  ServerConnectionLost: {
    DE: "Serververbindung verloren, bitte warten...",
    GB: "Lost server connection, please wait..."
  },
  FirstDataLoad: {
    DE: "Daten werden geladen, bitte warten...",
    GB: "Data is loading, please wait..."
  }
};

export enum LanguagesEnum {
  DE = "DE",
  EN = "GB"
}

export type Languages = LanguagesEnum | keyof typeof LanguagesEnum;

interface OwnProps {
  languagedata: LanguageClassObject;
}

type Props = OwnProps;

type State = Readonly<{}>;

@observer
export class Languagechanger extends Component<Props> {
  static defaultProps = {};
  render() {
    return (
      <Popover
        placement="bottom"
        title={this.props.languagedata.l["SpracheWahl"]}
        content={<LSchangeContent languagedata={this.props.languagedata} />}
        trigger={navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") <= -1 ? "click" : "focus"}
      >
        <div tabIndex={1} style={{ display: "inline-block", cursor: "pointer" }}>
          <FlagIcon size={30} code={this.props.languagedata.language as FlagIconCode} />
        </div>
      </Popover>
    );
  }
}

interface OwnPropsLSchangeContent {
  languagedata: LanguageClassObject;
}

type PropsLSchangeContent = OwnPropsLSchangeContent;

const LSchangeContent: FC<PropsLSchangeContent> = observer(props => {
  return (
    <Row type="flex" justify={"start"}>
      {$enum(LanguagesEnum).map(v => (
        <Col key={v} span={6}>
          <div
            onClick={() => {
              props.languagedata.changeLanguage(v);
            }}
            style={{ cursor: "pointer" }}
          >
            <FlagIcon code={v as FlagIconCode} />
          </div>
        </Col>
      ))}
    </Row>
  );
});

export class LanguageClassObject {
  data: any = lObjekt;
  @observable language: Languages = (lscache.get("language") as Languages) || "DE";
  @computed get dateFormat() {
    if (this.language === LanguagesEnum.DE) {
      return DateTypes.deutsch;
    } else {
      return DateTypes.englisch;
    }
  }
  @computed get l() {
    let rValue = {};
    Object.keys(this.data).map(d => {
      rValue[d] = this.data[d][this.language];
    });
    return rValue;
  }
  constructor(l?: Languages) {
    if (l != undefined) {
      this.language = l;
    }
  }
  @action changeLanguage(l: Languages) {
    lscache.set("language", l);
    this.language = l;
  }
}
