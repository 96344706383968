import { computed, IObjectDidChange, observable, toJS } from "mobx";
import { Datatable, SaveStream } from "../Framework/Backend/DataSets";
import { faHotel, faUmbrellaBeach, faImage } from "@fortawesome/free-solid-svg-icons";
import { deepObserve } from "mobx-utils";
import { ourParseFloat } from "../Framework/Helperfunctions/Formatter";
import { LanguageClassObject } from "../Framework/Languagechanger/Language";

export class OrderItem {
  ID: string;
  sender: string = "";
  sendergln: string = "";
  messagereference: string = "";
  istestmessage: "N" | "Y" = "N";
  ordernumberbuyer: string = "";
  orderdate: string = "";
  requesteddeliverydate: string = "";
  buyer: string = "";
  buyergln: string = "";
  buyeraddress: string = "";
  buyerzipcode: string = "";
  buyercity: string = "";
  buyercountry: string = "";
  buyercontactperson: string = "";
  buyercontactpersontelephone: string = "";
  buyercontactpersonfax: string = "";
  invoicee: string = "";
  invoiceegln: string = "";
  invoiceeaddress: string = "";
  invoiceezipcode: string = "";
  invoiceecity: string = "";
  invoiceecountry: string = "";
  invoiceevatnumber: string = "";
  deliveryparty: string = "";
  deliverypartygln: string = "";
  deliverypartyaddress: string = "";
  deliverypartyzipcode: string = "";
  deliverypartycity: string = "";
  deliverypartycountry: string = "";
  currencycode: string = "";
  unspecifiedtext: string = "";
  isspecialorder: boolean = false;
  marktnummer: string = "";
  kunde: string = "";
  konzern: string = "";
  @observable verladen: boolean = false;
  @observable NVEList: string[] = [];
  @observable NVEFile: string = "";
  @observable article: Artikel[] = [];
  @observable deliverydate: string = "";
  @observable DESADVNumber: string = "";
  @computed get hasWrongEAN() {
    let res = false;
    this.article.map(v => {
      if (v.orderedpackages === "" || v.orderedpackages === undefined || v.orderedpackages === "0") res = true;
    });
    return res;
  }
  @computed get tourenstatus() {
    let one = false;
    let all = true;
    this.article.map(current => {
      if (current.tour !== "" && current.tour !== undefined) one = true;
      else all = false;
    });
    return all ? "green" : one ? "orange" : "blue";
  }
  @computed get totalpacksordered() {
    return this.article.reduce((prev: number, current) => {
      return ourParseFloat(current.orderedpackages) + prev;
    }, 0);
  }
  @computed get totalpacksdelivered() {
    return this.article.reduce((prev: number, current) => {
      return current.NVEs.length + prev;
    }, 0);
  }
  @computed get m3packages() {
    return (
      Math.round(
        this.article.reduce((prev: number, current) => {
          return ourParseFloat(current.m3packagesCurrent) + prev;
        }, 0.0) * 100
      ) / 100
    ).toString();
  }
  @computed get touren() {
    return this.article.reduce((prev: string[], current) => {
      if (current.tour !== "" && current.tour !== undefined) prev.push(current.tour);
      return prev;
    }, []);
  }
  @computed get totallieferbar() {
    return this.article.reduce((prev: number, current) => {
      return prev + ourParseFloat(current.lieferbar);
    }, 0);
  }

  constructor(
    ID: string,
    sender: string = "",
    sendergln: string = "",
    messagereference: string = "",
    istestmessage: "N" | "Y" = "N",
    ordernumberbuyer: string = "",
    orderdate: string = "",
    requesteddeliverydate: string = "",
    buyer: string = "",
    buyergln: string = "",
    buyeraddress: string = "",
    buyerzipcode: string = "",
    buyercity: string = "",
    buyercountry: string = "",
    buyercontactperson: string = "",
    buyercontactpersontelephone: string = "",
    buyercontactpersonfax: string = "",
    invoicee: string = "",
    invoiceegln: string = "",
    invoiceeaddress: string = "",
    invoiceezipcode: string = "",
    invoiceecity: string = "",
    invoiceecountry: string = "",
    invoiceevatnumber: string = "",
    deliveryparty: string = "",
    deliverypartygln: string = "",
    deliverypartyaddress: string = "",
    deliverypartyzipcode: string = "",
    deliverypartycity: string = "",
    deliverypartycountry: string = "",
    currencycode: string = "",
    unspecifiedtext: string = "",
    isspecialorder: boolean = false,
    deliverydate: string = "",
    marktnummer: string = "",
    kunde: string = "",
    konzern: string = "",
    verladen: boolean = false,
    NVEList: string[] = [],
    NVEFile: string = "",
    DESADVNumber: string = "",

    article?: Artikel[]
  ) {
    if (article !== undefined) {
      this.article = article;
    }
    this.ID = ID;
    this.sender = sender;
    this.sendergln = sendergln;
    this.messagereference = messagereference;
    this.istestmessage = istestmessage;
    this.ordernumberbuyer = ordernumberbuyer;
    this.orderdate = orderdate;
    this.requesteddeliverydate = requesteddeliverydate;
    this.buyer = buyer;
    this.buyergln = buyergln;
    this.buyeraddress = buyeraddress;
    this.buyerzipcode = buyerzipcode;
    this.buyercity = buyercity;
    this.buyercountry = buyercountry;
    this.buyercontactperson = buyercontactperson;
    this.buyercontactpersontelephone = buyercontactpersontelephone;
    this.buyercontactpersonfax = buyercontactpersonfax;
    this.invoicee = invoicee;
    this.invoiceegln = invoiceegln;
    this.invoiceeaddress = invoiceeaddress;
    this.invoiceezipcode = invoiceezipcode;
    this.invoiceecity = invoiceecity;
    this.invoiceecountry = invoiceecountry;
    this.invoiceevatnumber = invoiceevatnumber;
    this.deliveryparty = deliveryparty;
    this.deliverypartygln = deliverypartygln;
    this.deliverypartyaddress = deliverypartyaddress;
    this.deliverypartyzipcode = deliverypartyzipcode;
    this.deliverypartycity = deliverypartycity;
    this.deliverypartycountry = deliverypartycountry;
    this.currencycode = currencycode;
    this.unspecifiedtext = unspecifiedtext;
    this.isspecialorder = isspecialorder;
    this.deliverydate = deliverydate;
    this.marktnummer = marktnummer;
    this.kunde = kunde;
    this.konzern = konzern;
    this.verladen = verladen;
    this.NVEFile = NVEFile;
    this.NVEList = NVEList;
    this.DESADVNumber = DESADVNumber;
    if (article !== undefined) {
      this.article = article;
    }
  }
}
export class Artikel {
  ID: string = "";
  linenumber: string = "";
  articledescription: string = "";
  articlecodesupplier: string = "";
  articlecodebuyer: string = "";
  gtin: string = "";
  orderedquantity: string = "";
  purchaseprice: string = "";
  orderedquantityunitcode: string = "";
  @observable orderedpackages: string = "";
  kurztext: string = "";
  m3package: string = "";
  @observable deliveredpackages: string = "";
  @observable tour: string = "";
  @observable lieferbar: string = "";
  @observable NVEs: string[] = [];
  @computed get m3packagesCurrent() {
    return (ourParseFloat(this.lieferbar) * ourParseFloat(this.m3package)).toString();
  }

  constructor(
    ID: string,
    linenumber: string = "",
    articledescription: string = "",
    articlecodesupplier: string = "",
    articlecodebuyer: string = "",
    gtin: string = "",
    orderedquantity: string = "",
    purchaseprice: string = "",
    orderedquantityunitcode: string = "",
    orderedpackages: string = "",
    kurztext: string = "",
    deliveredpackages: string = "",
    m3package: string = "",
    tour: string = "",
    lieferbar: string = "",
    NVEs: string[] = []
  ) {
    this.ID = ID;
    this.linenumber = linenumber;
    this.articledescription = articledescription;
    this.articlecodesupplier = articlecodesupplier;
    this.articlecodebuyer = articlecodebuyer;
    this.gtin = gtin;
    this.orderedquantity = orderedquantity;
    this.purchaseprice = purchaseprice;
    this.orderedquantityunitcode = orderedquantityunitcode;
    this.orderedpackages = orderedpackages;
    this.kurztext = kurztext;
    this.deliveredpackages = deliveredpackages;
    this.m3package = m3package;
    this.tour = tour;
    if (lieferbar === undefined || lieferbar === "") {
      this.lieferbar = orderedpackages;
      // console.log("lieferbar", this.lieferbar);
    } else {
      this.lieferbar = lieferbar;
    }
    this.NVEs = NVEs;
  }
}

export class Orders extends Datatable<OrderItem> {
  constructor(name: string, currentValue: any[], onSave: (save: SaveStream) => void, l: LanguageClassObject) {
    super(name, onSave, l);

    this.currentValue = this.spliceaction(currentValue, []);

    this.disposer = deepObserve(this.currentValue, this.observeValues);
  }

  spliceaction = (added: any[], path: string[]) => {
    if (path.length === 0) {
      return added.map((item: any) => {
        let tempOrderItem = new OrderItem(
          item.ID,
          item.sender,
          item.sendergln,
          item.messagereference,
          item.istestmessage,
          item.ordernumberbuyer,
          item.orderdate,
          item.requesteddeliverydate,
          item.buyer,
          item.buyergln,
          item.buyeraddress,
          item.buyerzipcode,
          item.buyercity,
          item.buyercountry,
          item.buyercontactperson,
          item.buyercontactpersontelephone,
          item.buyercontactpersonfax,
          item.invoicee,
          item.invoiceegln,
          item.invoiceeaddress,
          item.invoiceezipcode,
          item.invoiceecity,
          item.invoiceecountry,
          item.invoiceevatnumber,
          item.deliveryparty,
          item.deliverypartygln,
          item.deliverypartyaddress,
          item.deliverypartyzipcode,
          item.deliverypartycity,
          item.deliverypartycountry,
          item.currencycode,
          item.unspecifiedtext,
          item.isspecialorder,
          item.deliverydate,
          item.marktnummer,
          item.kunde,
          item.konzern,
          item.verladen,
          item.NVEList,
          item.NVEFile,
          item.DESADVNumber
        );
        tempOrderItem.article = item.article.map((article: any) => {
          return new Artikel(
            article.ID,
            article.linenumber,
            article.articledescription,
            article.articlecodesupplier,
            article.articlecodebuyer,
            article.gtin,
            article.orderedquantity,
            article.purchaseprice,
            article.orderedquantityunitcode,
            article.orderedpackages,
            article.kurztext,
            article.deliveredpackages,
            article.m3package,
            article.tour,
            article.lieferbar,
            article.NVEs
          );
        });
        return tempOrderItem;
      });
    } else return added;
  };
}
