import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

import "antd/dist/antd.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./Framework/Navi/MainNavi.css"
import { HashRouter } from "react-router-dom"

import { LoadScriptNext } from "@react-google-maps/api"

// @ts-ignore
Array.prototype.move = function(pos1, pos2) {
  // local variables
  var i, tmp
  // cast input parameters to integers
  pos1 = parseInt(pos1, 10)
  pos2 = parseInt(pos2, 10)
  // if positions are different and inside array
  if (pos1 !== pos2 && 0 <= pos1 && pos1 <= this.length && 0 <= pos2 && pos2 <= this.length) {
    // save element from position 1
    tmp = this[pos1]
    // move element down and shift other elements up
    if (pos1 < pos2) {
      for (i = pos1; i < pos2; i++) {
        this[i] = this[i + 1]
      }
    }
    // move element up and shift other elements down
    else {
      for (i = pos1; i > pos2; i--) {
        this[i] = this[i - 1]
      }
    }
    // put element from position 1 to destination
    this[pos2] = tmp
  }
}

//googleMapsApiKey="AIzaSyA2T5XThMVo2UZakOVJ9P4RuJkeg43YKjc"
ReactDOM.render(
  <HashRouter>
    <LoadScriptNext id="script-loader" libraries={["drawing"]} googleMapsApiKey="AIzaSyA2T5XThMVo2UZakOVJ9P4RuJkeg43YKjc">
      <App />
    </LoadScriptNext>
  </HashRouter>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
