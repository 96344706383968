import { observer } from "mobx-react";
import React, { Component } from "react";
import { Checkbox, Switch } from "antd";
import { SwitchProps } from "antd/es/switch";
import "./Button.scss";
import { Row } from "../Stylers/Stylers";
import { action, observable } from "mobx";
import { OurFontAwsomeIcon } from "../OurComps/Icons/OurFontAwsomeIcon";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export enum ColorEnum {
  primary = "primary",
  white = "white",
  warning = "warning",
  success = "success",
  danger = "danger"
}
interface OwnProps2 extends React.HTMLProps<HTMLButtonElement> {
  color?: ColorEnum;
  loadingDelay?: number;
  height?: "small" | "medium" | "large";
  width: number;
  autoWidth?: boolean;
}

type ButtonProps = OwnProps2;
@observer
export class OButton extends Component<ButtonProps> {
  @observable delayed: boolean = false;
  static defaultProps = {
    color: ColorEnum.primary,
    height: "small",
    width: 2,
    autoWidth: false,
    type: "Button"
  };

  constructor(props: any) {
    super(props);
  }
  @action
  onClick = (e: any) => {
    if (!this.delayed) {
      if (this.props.loadingDelay !== undefined) {
        this.delayed = true;

        window.setTimeout(() => (this.delayed = false), this.props.loadingDelay * 1000);
      }
      if (this.props.onClick !== undefined) this.props.onClick(e);
    }
  };
  render() {
    const { color, loadingDelay, type, height, onClick, ...props } = this.props;
    return (
      <button
        className={"OButton" + " C" + color + (props.className !== undefined ? " " + props.className : "") + (this.props.disabled ? " OBtnDisabled" : "")}
        type={type as "submit" | undefined | "button"}
        onClick={this.onClick}
        style={{
          width: !this.props.autoWidth ? (36 * (this.props.height === "small" ? 1 : 14 / 11) * this.props.width - 12).toString() + "px" : "auto",
          paddingLeft: "3px",
          paddingRight: "3px"
          /*  height: height === "small" ? "24px" : height === "medium" ? "32px" : "40px"*/
        }}
        {...props}
      >
        <Row
          center
          style={{
            color: "inherit",
            height: height === "small" ? "24px" : height === "medium" ? "32px" : "40px",
            alignItems: "center",
            fontSize: height === "small" ? "12px" : height === "medium" ? "14px" : "16px",
            whiteSpace: "nowrap"
          }}
        >
          {this.delayed ? <OurFontAwsomeIcon color={"inherit"} pulse icon={faSpinner}></OurFontAwsomeIcon> : this.props.children}
        </Row>
      </button>
    );
  }
}
