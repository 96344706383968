import React, { FC } from "react";

import { observer } from "mobx-react";
import { Row } from "../Stylers/Stylers";

import "./Header.scss";

interface OwnProps {}

type HeaderProps = OwnProps;

export const Header: FC<HeaderProps> = observer(props => {
  return (
    <div className={"OurHeader"} style={{ backgroundColor: "rgb(24, 144, 255)", position: "fixed", width: "100%", height: "45px" }}>
      <Row style={{ width: "100%", alignItems: "center", fontSize: "14px", color: "white", height: "100%" }}>{props.children}</Row>
    </div>
  );
});
