import { autorun, observable, IObjectDidChange, IArrayChange, IArraySplice, IMapDidChange, action, toJS } from "mobx";
import { deepObserve, IDisposer, computedFn } from "mobx-utils";
import { Input, message } from "antd";
import { LanguageClassObject } from "../Languagechanger/Language";

function move(arr: Array<any>, old_index: number, new_index: number) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export type IChange = IObjectDidChange | IArrayChange | IArraySplice | IMapDidChange;

export interface ISaveStreamItem {
  change: IChange;
  path: string;
}

export type SaveStream = {
  forceReroll: boolean;
  changes: Array<ISaveStreamItem>;
};

export abstract class Datatable<T> {
  tableName: string;
  languageClass: LanguageClassObject;
  unsavedChanges: Array<ISaveStreamItem> = [];
  disposer: IDisposer = () => {};
  onSave: (save: SaveStream) => void;
  @observable currentValue: Array<T> = [];
  lastUpdate: ISaveStreamItem | null = null;
  forceReroll = false;
  constructor(name: string, onSave: (save: SaveStream) => void, l: LanguageClassObject) {
    this.tableName = name;
    this.onSave = onSave;
    this.languageClass = l;
  }

  @action addChangesFromServer = (changeStream: SaveStream) => {
    if (changeStream.forceReroll) this.lastUpdate = null;
    this.disposer();

    if (this.forceReroll && changeStream.forceReroll) {
      this.selfChanges(this.unsavedChanges.slice().reverse(), true);
    }
    console.log("serverChanges", changeStream.changes.slice());
    while (changeStream.changes.length > 0) {
      let item = changeStream.changes.shift();

      if (item !== undefined) {
        let change = item.change;
        let itemchange = "";

        if (item.change.type == "update") {
          itemchange += "update: ";
        } else {
          if ((item.change as IArraySplice).addedCount > 0) {
            // @ts-ignore
            itemchange += "added: " + item.change.added[0].ID;
          } else {
            // @ts-ignore
            itemchange += "deleted: " + item.change.removed[0].ID;
          }
        }
        console.log("change", itemchange);
        let path = item.path.length > 0 ? item.path.split("/") : [];

        let ele = this.currentValue;
        let abbort = false;
        path.map((p: string) => {
          if (ele !== undefined && !abbort) {
            let index: number | string = p;
            if (p.includes("(ID_)")) {
              // @ts-ignore
              index = ele.findIndex(e => e.ID == p.split("(ID_)")[1]);
            }
            if (index !== -1) {
              ele = ele[index];
            } else {
              abbort = true;
            }
          }
        });
        console.log("beforeapply", toJS(ele));
        if (ele !== undefined && !abbort) {
          if (change.type === "splice") {
            let changesadded = change.added.length > 0 ? this.spliceaction(change.added, path) : [];

            /*        if (path.length == 0) {
                        changesadded = changesadded.map((item: any) => new HSObject(item.bereich, item.name));
                      }*/
            if (change.removed.length > 0 && change.removed[0].ID !== undefined) {
              change.removed.map(e => {
                // @ts-ignore
                let index = ele.findIndex(v => v.ID == e.ID);
                if (index !== -1) {
                  ele.splice(index, 1);
                }
              });

              if (changesadded.length > 0) {
                ele.splice(0, 0, ...changesadded);
              }
            } else if (change.removed.length > 0 && typeof change.removed[0] !== "object") {
              change.removed.map(e => {
                // @ts-ignore
                let index = ele.findIndex(v => v == e);
                if (index !== -1) {
                  ele.splice(index, 1);
                }
              });

              if (changesadded.length > 0) {
                ele.splice(0, 0, ...changesadded);
              }
            } else {
              ele.splice(change.index >= ele.length ? ele.length : change.index, change.removedCount, ...changesadded);
            }
          }

          if (change.type === "update") {
            if (change.hasOwnProperty("index")) {
              ele[(change as IArrayChange).index] = change.newValue;
            } else {
              ele[(change as IObjectDidChange).name] = change.newValue;
            }
          }
          console.log("afterapply", toJS(ele));
        }
      }
    }

    /* window.setTimeout(() => {
      let changes = this.unsavedChanges.slice();
      this.unsavedChanges = [];
      this.disposer = deepObserve(this.currentValue, this.observeValues);
      this.selfChanges(changes, false);
    }, 100);*/

    let changes = this.unsavedChanges.slice();
    this.unsavedChanges = [];
    this.disposer = deepObserve(this.currentValue, this.observeValues);
    this.selfChanges(changes, false);
  };

  @action selfChanges = (changeStream: Array<ISaveStreamItem>, rollBack = false) => {
    console.log(rollBack ? "clientChangesRollback" : "clientChangesApply", changeStream.slice());
    while (changeStream.length > 0) {
      let item = changeStream.shift();
      let itemchange = "";

      if (item !== undefined) {
        if (item.change.type == "update") {
          itemchange += "update: ";
        } else {
          if ((item.change as IArraySplice).addedCount > 0) {
            // @ts-ignore
            itemchange += "added: " + item.change.added[0].ID;
          } else {
            // @ts-ignore
            itemchange += "deleted: " + item.change.removed[0].ID;
          }
        }
        console.log("change", itemchange);
        console.log("changeitem", item);
        let change = item.change;

        let path = item.path.length > 0 ? item.path.split("/") : [];

        let ele = this.currentValue;
        let abbort = false;
        path.map((p: string) => {
          if (ele !== undefined && !abbort) {
            let index: number | string = p;
            if (p.includes("(ID_)")) {
              // @ts-ignore
              index = ele.findIndex(e => e.ID == p.split("(ID_)")[1]);
            }
            if (index !== -1) {
              ele = ele[index];
            } else {
              abbort = true;
            }
          }
        });
        console.log("beforeapply", toJS(ele));

        if (ele !== undefined && !abbort) {
          if (change.type === "splice") {
            let changesadded = change.added.length > 0 ? this.spliceaction(change.added, path) : [];

            /*        if (path.length == 0) {
                        changesadded = changesadded.map((item: any) => new HSObject(item.bereich, item.name));
                      }*/
            if (change.removed.length > 0 && change.removed[0].ID !== undefined) {
              change.removed.map(e => {
                // @ts-ignore
                let index = ele.findIndex(v => v.ID == e.ID);
                if (index !== -1) {
                  if (!rollBack) ele.splice(index, 1);
                }
              });
              if (rollBack) {
                ele.splice(change.index, 0, ...change.removed);
              }
              if (changesadded.length > 0) {
                if (!rollBack) ele.splice(0, 0, ...changesadded);
                else ele.splice(0, changesadded.length);
              }
            } else if (change.removed.length > 0 && typeof change.removed[0] !== "object") {
              change.removed.map(e => {
                // @ts-ignore
                let index = ele.findIndex(v => v == e);

                if (index !== -1) {
                  if (!rollBack) ele.splice(index, 1);
                }
              });
              if (rollBack) {
                ele.splice(change.index, 0, ...change.removed);
              }
              if (changesadded.length > 0) {
                if (!rollBack) ele.splice(0, 0, ...changesadded);
                else ele.splice(0, changesadded.length);
              }
            } else {
              if (!rollBack) ele.splice(change.index >= ele.length ? ele.length : change.index, change.removedCount, ...changesadded);
              else {
                console.log("removeItem");
                console.log("elelength", ele.length);
                console.log("index", change.index);
                console.log("afterremoved", ele.splice(change.index >= ele.length ? ele.length : change.index, change.addedCount, ...change.removed));
              }
            }
          }

          if (change.type === "update") {
            if (change.hasOwnProperty("index")) {
              if (!rollBack) ele[(change as IArrayChange).index] = change.newValue;
              else ele[(change as IArrayChange).index] = change.oldValue;
            } else {
              if (!rollBack) ele[(change as IObjectDidChange).name] = change.newValue;
              else ele[(change as IObjectDidChange).name] = change.oldValue;
            }
          }
          console.log("afterapply", toJS(ele));
        }
      }
    }
  };

  observeValues = (change: IChange, path: string) => {
    console.log("changecomming", change);
    if (
      change.type === "update" &&
      this.lastUpdate != null &&
      this.lastUpdate.path === path &&
      (((this.lastUpdate.change as IObjectDidChange).name === (change as IObjectDidChange).name &&
        (this.lastUpdate.change as IObjectDidChange).name !== undefined) ||
        ((this.lastUpdate.change as IArrayChange).index === (change as IArrayChange).index && (change as IArrayChange).index !== undefined))
    ) {
      let before = this.unsavedChanges.pop();
      // @ts-ignore
      change.oldValue = before.change.oldValue;
    }
    if (change.object !== undefined) {
      delete change.object;
      console.log(change);
    }
    this.lastUpdate = change.type === "update" ? { change, path } : null;

    let calcedPath = "";
    let currentEle = this.currentValue;
    path.split("/").map(p => {
      if (!isNaN(Number.parseInt(p))) {
        if (currentEle[p].ID !== undefined) {
          calcedPath += "(ID_)" + currentEle[p].ID;
        } else {
          calcedPath += p;
        }
      } else {
        calcedPath += p;
      }
      currentEle = currentEle[p];
      calcedPath += "/";
    });

    if (calcedPath.length > 0) calcedPath = calcedPath.substring(0, calcedPath.length - 1);
    if (change.type !== "update") this.forceReroll = this.mustReroll(calcedPath);
    this.unsavedChanges.push({
      change,
      path: calcedPath
    });
  };

  abstract spliceaction: (added: any[], path: string[]) => T[];
  getValue = computedFn(
    (...args: any): Array<T> => {
      return this.currentValue;
    }
  );

  mustReroll = (path: string): boolean => {
    return false;
  };
  handleSave = (): boolean => {
    //check required etc. füge Sachen zum unsavedchangesarray etc.   gebe  true zurück für speichern und false fü nicht
    return true;
  };

  save = (): void => {
    window.setTimeout(() => {
      if (this.handleSave()) {
        this.onSave({ changes: this.unsavedChanges, forceReroll: this.forceReroll });
        this.unsavedChanges = [];
        this.lastUpdate = null;
        this.forceReroll = false;
      }
    }, 1000);
  };
  @action
  initData = (data: T[]) => {
    this.disposer();
    this.spliceaction(data, []).map((v: T) => {
      this.currentValue.push(v);
    });
    this.disposer = deepObserve(this.currentValue, this.observeValues);
  };
  @action abortSave = () => {
    this.selfChanges(this.unsavedChanges.slice().reverse(), true);
    this.unsavedChanges = [];
    this.lastUpdate = null;
    this.forceReroll = false;
  };
}
