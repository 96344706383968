import React, { Component } from "react";

import moment from "moment";
import { observer } from "mobx-react";
import { action, computed, observable } from "mobx";
import "./Datepicker.scss";
import { Divider } from "antd";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { OurFontAwsomeIcon } from "../OurComps/Icons/OurFontAwsomeIcon";
import { LanguageClassObject } from "../Languagechanger/Language";
import { Row } from "../Stylers/Stylers";
import { OButton } from "./FormElements";
import { ColorEnum } from "./Button";
import { DateTypes } from "../Helperfunctions/Formatter";

interface OurDayListItem {
  status?: boolean;
  date?: string;
  day?: number;
  disabled?: boolean;
  className: string;
}
class Calendar {
  @observable currentMonth: number;
  @observable currentYear: number;
  @observable value: string;
  @observable savename: string;
  from: moment.Moment;
  to: moment.Moment;
  constructor(from: string, to: string, value: string, savename: string) {
    this.currentMonth = moment(value[savename]).isValid() ? moment(value[savename]).month() : moment().month();
    this.currentYear = moment(value[savename]).isValid() ? moment(value[savename]).year() : moment().year();
    this.from = moment(from);
    this.to = moment(to);
    this.value = value;
    this.savename = savename;
  }

  @computed get dayList() {
    // @ts-ignore
    let currentdate = moment(this.currentYear + "-" + (this.currentMonth + 1) + "-01", "YYYY-MM-DD");
    let month = currentdate.month();
    let dates: Array<Array<OurDayListItem>> = [[], [], [], [], [], [], []];
    let firstday = currentdate.day() == 0 ? 6 : currentdate.day() - 1;
    for (let i = 0; i < firstday; i++) {
      dates[i].push({ status: false, className: "" });
    }

    while (currentdate.month() === month) {
      let day = currentdate.format("YYYY-MM-DD");
      let disabled = currentdate.isBefore(this.from) || currentdate.isAfter(this.to);
      let itemClassName = this.value[this.savename] === day ? "dateboxpicked" : disabled === true ? "" : "dateboxhighlight";
      dates[currentdate.day() == 0 ? 6 : currentdate.day() - 1].push({ date: day, day: currentdate.date(), status: true, disabled, className: itemClassName });
      currentdate.add(1, "days");
    }
    return dates;
  }
}

const month = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

interface OwnPropsOurMultiDatePicker {
  from: string;
  to: string;
  onChange: (picked: string) => void;
  withClear: boolean;
  value: any;
  savename: string;
  language: LanguageClassObject;
  height?: "small" | "medium" | "large";
}

type PropsOurMultiDatePicker = OwnPropsOurMultiDatePicker;

@observer
class OurDatepicker extends Component<PropsOurMultiDatePicker> {
  static defaultProps = {
    height: "small",
    from: moment("2000-01-01")
      .startOf("day")
      .format("YYYY-MM-DD"),
    to: "2199-12-31",
    onChange: () => {},
    withClear: false
  };
  calendar: Calendar;
  constructor(props: PropsOurMultiDatePicker) {
    super(props);

    this.calendar = new Calendar(props.from, props.to, this.props.value, this.props.savename);
  }
  weekdayName = (d: number): string => {
    if (d === 0) {
      return "MO";
    } else if (d === 1) {
      return "DI";
    } else if (d === 2) {
      return "MI";
    } else if (d === 3) {
      return "DO";
    } else if (d === 4) {
      return "FR";
    } else if (d === 5) {
      return "SA";
    } else if (d === 6) {
      return "SO";
    } else return "";
  };
  @action handleDateSelect = (d: string = "") => {
    this.props.value[this.props.savename] = d;
    if (this.props.onChange !== undefined) {
      let v = this.props.language.dateFormat === DateTypes.deutsch ? moment(d).format("DD.MM.YYYY") : moment(d).format("YYYY-MM-DD");
      this.props.onChange(v);
    }
  };

  @action clear = () => {
    this.props.value[this.props.savename] = "";
    if (this.props.onChange !== undefined) {
      this.props.onChange("");
    }
  };
  mouseDown = (e: any) => e.preventDefault();
  @action setYear = (amount: -1 | 1) => {
    this.calendar.currentYear += amount;
  };
  @action setMonth = (amount: -1 | 1) => {
    this.calendar.currentMonth += amount;
  };

  render() {
    return (
      <div className={"ODatePickerContainer" + " ODatePickerContainer-" + this.props.height} onMouseDown={this.mouseDown}>
        <div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", paddingRight: "4px" }}>
          <div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", width: "64%" }}>
            <OurFontAwsomeIcon color={" #1890ff"} style={{ cursor: "pointer" }} icon={faAngleLeft} onClick={this.setMonth.bind(this, -1)} />
            <div>{this.props.language.l[month[this.calendar.currentMonth]]}</div>
            <OurFontAwsomeIcon color={" #1890ff"} style={{ cursor: "pointer" }} icon={faAngleRight} onClick={this.setMonth.bind(this, 1)} />
          </div>
          <div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", width: "36%" }}>
            <OurFontAwsomeIcon color={" #1890ff"} style={{ cursor: "pointer" }} icon={faAngleLeft} onClick={this.setYear.bind(this, -1)} />
            <div>{this.calendar.currentYear}</div>
            <OurFontAwsomeIcon color={" #1890ff"} style={{ cursor: "pointer" }} icon={faAngleRight} onClick={this.setYear.bind(this, 1)} />
          </div>
        </div>
        <Divider style={{ margin: 0, marginTop: "4px", borderColor: "grey" }} />
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          {this.calendar.dayList.map((list: OurDayListItem[], index: number) => (
            <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Row center className={"ODatePickerContainerItem"}>
                {this.props.language.l[this.weekdayName(index)]}
              </Row>

              {list.map((d: OurDayListItem) => {
                if (d.status) {
                  return (
                    <Row
                      title={"von"}
                      centerVertical={true}
                      center={true}
                      key={d.date}
                      onClick={() => {
                        if (d.disabled !== true) this.handleDateSelect(d.date);
                      }}
                      style={{
                        cursor: "pointer",
                        color: d.disabled === true ? "lightgrey" : "black"
                      }}
                      className={"ODatePickerContainerItem " + d.className}
                    >
                      {d.day}
                    </Row>
                  );
                } else return <div className={"ODatePickerContainerItem"} key={d.day} />;
              })}
            </div>
          ))}
        </div>
        {this.props.withClear ? (
          <OButton color={ColorEnum.primary} onClick={this.clear}>
            {this.props.language.l["Loeschen"]}
          </OButton>
        ) : null}
      </div>
    );
  }
}

export default OurDatepicker;
