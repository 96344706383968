import React, { FC } from "react";

import { observer } from "mobx-react";
import { Row } from "../Stylers/Stylers";

import "./Footer.scss";

interface OwnProps {}

type HeaderProps = OwnProps;

export const Footer: FC<HeaderProps> = observer(props => {
  return (
    <footer
      className={"OurFooter"}
      style={{
        marginTop: "20px",
        width: "100%",
        backgroundColor: "#1890ff",
        overflow: "hidden",
        bottom: "0px"
      }}
    >
      <Row center withWrap={true} style={{ alignItems: "center", padding: "6px", width: "100%", color: "inherit" }}>
        {props.children}
      </Row>
    </footer>
  );
});

export const FooterItem: FC<HeaderProps> = observer(props => {
  return <div style={{ marginRight: "20px", color: "inherit" }}>{props.children}</div>;
});
