import { computed, observable, toJS, autorun, reaction, action, IObservableArray } from "mobx";
import { computedFn, deepObserve } from "mobx-utils";
import equal from "deep-equal";
import { HSDataSet, HSListe, HSObject, InnerObject } from "./models/HSObjekte";
import io from "socket.io-client";

import { StandortDatenAdmin } from "./models/StandortDatenAdmin";
import { UserDatenAdmin } from "./models/UserDatenAdmin";
import { Orders } from "./models/Order";
import { throwError } from "rxjs";
import { Datatable, SaveStream } from "./Framework/Backend/DataSets";
import { Backend, BackendProps } from "./Framework/Backend/Backend";
import { LanguageClassObject } from "./Framework/Languagechanger/Language";

export class Backendschnittstelle extends Backend {
  constructor({ ...props }: BackendProps) {
    super(props);
  }

  getTableClass: (name: string, initValue: any[], onSave: (save: SaveStream) => void, l: LanguageClassObject) => Datatable<any> = (
    name,
    initValue,
    onSave,
    l
  ) => {
    switch (name) {
      //case "test":
      // set = new HSDataSet(name, dataset, this.saveToServer.bind(this, name), this.l);
      //break;
      case "Orders":
        return new Orders(name, [], this.saveToServer.bind(this, name), this.languageClass) as Datatable<any>;
      default:
        throw "Keinen DB Table Handler in  Backend gefunden unter: " + name;
    }
  };
}
