import { observer } from "mobx-react";
import React, { Component } from "react";
import { action, computed, observable, toJS } from "mobx";

import { OurFontAwsomeIcon } from "../Framework/OurComps/Icons/OurFontAwsomeIcon";
import { faBarcode, faCircle, faFileExport, faTrash } from "@fortawesome/free-solid-svg-icons";
import { LanguageClassObject } from "../Framework/Languagechanger/Language";
import { Column, OTable, Searchenum } from "../Framework/Table/Table";
import { Backendschnittstelle } from "../backend";
import { Artikel, OrderItem, Orders } from "../models/Order";

import { ourParseFloat, toDateFormat, toMoneyFormat, toNumberFormat } from "../Framework/Helperfunctions/Formatter";
import { Row } from "../Framework/Stylers/Stylers";
import { NumberTypes } from "../Framework/FormElements/Input";
import { OButton, OInput, ONumberInput } from "../Framework/FormElements/FormElements";
import axios from "axios";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { HashRouterProps, RouteComponentProps, withRouter } from "react-router-dom";

interface OwnProps2 {
  language: LanguageClassObject;
  bs: Backendschnittstelle;
}

type TableProps = OwnProps2;
@observer
class Details extends Component<TableProps & RouteComponentProps> {
  ref: any = React.createRef();
  ref2: any = React.createRef();
  @observable currentScanValue: string = "";
  @observable currentEAN: { value: string } = { value: "" };
  @observable currentNVE: { value: string } = { value: "" };
  @observable orders: OrderItem[] | undefined;
  @observable focused: boolean = true;
  @computed get getData() {
    if (this.orders === undefined) return {};
    else {
      // @ts-ignore

      return this.orders.find((o: OrderItem) => {
        // @ts-ignore

        // @ts-ignore
        return o.ID == this.props.match.params.ID;
      });
    }
  }

  ordersDB: Orders | undefined;
  static defaultProps = {};

  constructor(props: TableProps & RouteComponentProps) {
    super(props);

    this.props.bs.getDataset("Orders").then((v: any) => {
      this.ordersDB = v;
      this.orders = (v as Orders).getValue();
      console.log("getdata", this.getData);
    });
  }

  componentWillUnmount() {
    //   if (this.ordersDB !== undefined) this.ordersDB.abortSave();
  }

  openTour = (tour: string) => {};
  @action finishTask = () => {
    (this.getData as OrderItem).verladen = true;
    if (this.ordersDB !== undefined) this.ordersDB.save();
    window.setTimeout(() => {
      this.props.history.push("/TableScanner");
    }, 1000);
  };
  @action delVerNVE = (row: Artikel) => {
    row.NVEs.splice(0, row.NVEs.length);
  };
  @action performscan = (e: any) => {
    //alert(e.target.value);
    let value = e.target.value;
    // alert(e.target.value.length);
    if (value.length === 20) {
      // alert(JSON.stringify((this.getData as OrderItem).NVEList));
      if ((this.getData as OrderItem).NVEList.includes(value)) {
        this.currentNVE.value = value;
      }
    } else {
      let item = (this.getData as OrderItem).article.find(a => a.gtin === value);
      if (item !== undefined) {
        if (item.NVEs.length < ourParseFloat(item.lieferbar)) {
          this.currentEAN.value = value;
        }
      }
    }
    this.currentScanValue = e.target.value;
    if (this.currentNVE.value !== "" && this.currentEAN.value !== "") {
      this.checkNumbers();
    }
    window.setTimeout(() => (this.currentScanValue = ""), 10);
  };
  focusScan = () => {
    if (this.ref !== undefined) {
      this.ref.current.focus();
    }
  };
  @action checkNumbers() {
    //  alert("hi3");
    if (this.currentEAN.value !== "" && this.currentNVE.value !== "") {
      let item = (this.getData as OrderItem).article.find(i => i.gtin === this.currentEAN.value);

      if (item !== undefined) {
        item.NVEs.push(this.currentNVE.value);
        let index = (this.getData as OrderItem).NVEList.findIndex(v => v === this.currentNVE.value);
        if (index !== -1) {
          (this.getData as OrderItem).NVEList.splice(index, 1);
        }
        window.setTimeout(() => {
          this.currentNVE.value = "";
          this.currentEAN.value = "";
          if (this.ordersDB !== undefined) this.ordersDB.save();
        }, 100);
      }
    }
  }
  render() {
    return this.orders !== undefined && this.orders.length > 0 ? (
      <>
        <input
          onFocus={() => (this.focused = true)}
          onBlur={() => (this.focused = false)}
          autoFocus={true}
          ref={this.ref}
          value={this.currentScanValue}
          onChange={this.performscan}
          style={{ width: "100%" }}
        />
        <Row center={true} style={{ width: "100%" }}>
          {this.focused ? (
            <OButton width={6}></OButton>
          ) : (
            <OButton width={6} onClick={this.focusScan}>
              Scanmodus aktivieren
            </OButton>
          )}
        </Row>
        <Row fullLine style={{ width: "100%" }}>
          <OInput onBlur={this.checkNumbers} width={3.6} label={"EAN"} ref={this.ref2} value={this.currentEAN} savename={"value"} />
          <OInput onBlur={this.checkNumbers} width={4.5} label={"NVE"} value={this.currentNVE} savename={"value"} />
        </Row>
        <Row center style={{ width: "100%" }}>
          <div>{(this.getData as OrderItem).totalpacksdelivered + " / " + (this.getData as OrderItem).totallieferbar}</div>
        </Row>
        <Row center style={{ width: "100%" }}>
          <OButton width={6} onClick={this.finishTask}>
            Auftrag abschließen
          </OButton>
        </Row>
      </>
    ) : null;
  }
}
// @ts-ignore
export default withRouter(Details);
