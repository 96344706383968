import React, { Component, CSSProperties, FC, ReactNode } from "react";
import { action, autorun, computed, entries, observable } from "mobx";
import { observer } from "mobx-react";
import "./Table.scss";
import { OButton, OCheckbox, OInput, OOption, OSelect, OSlider, OSwitch } from "../FormElements/FormElements";
import { Button, Checkbox, Drawer, Dropdown, Menu, Modal } from "antd";
import { Column, Row } from "../Stylers/Stylers";
import { OurFontAwsomeIcon } from "../OurComps/Icons/OurFontAwsomeIcon";

import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowDown,
  faArrowUp,
  faBackward,
  faCog,
  faFastBackward,
  faFastForward,
  faForward,
  faMinus,
  faPlus,
  faSearch,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons";
import { SelectProps } from "antd/es/select";
import lscache from "lscache";
import { LanguageClassObject } from "../Languagechanger/Language";
import { ColorEnum } from "../FormElements/Button";
import { OurOption } from "../FormElements/Select";

const { confirm } = Modal;

function camelCaseToTitleCase(camelCaseString: string) {
  let result: string = camelCaseString // "ToGetYourGEDInTimeASongAboutThe26ABCsIsOfTheEssenceButAPersonalIDCardForUser456InRoom26AContainingABC26TimesIsNotAsEasyAs123ForC3POOrR2D2Or2R2D"
    .replace(/([a-z])([A-Z][a-z])/g, "$1 $2") // "To Get YourGEDIn TimeASong About The26ABCs IsOf The Essence ButAPersonalIDCard For User456In Room26AContainingABC26Times IsNot AsEasy As123ForC3POOrR2D2Or2R2D"
    .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2") // "To Get YourGEDIn TimeASong About The26ABCs Is Of The Essence ButAPersonalIDCard For User456In Room26AContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2") // "To Get Your GEDIn Time ASong About The26ABCs Is Of The Essence But APersonal IDCard For User456In Room26AContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2") // "To Get Your GEDIn Time A Song About The26ABCs Is Of The Essence But A Personal ID Card For User456In Room26A ContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2") // "To Get Your GEDIn Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC26Times Is Not As Easy As 123For C3POOr R2D2Or 2R2D"

    // Note: the next regex includes a special case to exclude plurals of acronyms, e.g. "ABCs"
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2") // "To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC26Times Is Not As Easy As 123For C3PO Or R2D2Or 2R2D"
    .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2") // "To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC 26Times Is Not As Easy As 123For C3PO Or R2D2Or 2R2D"

    // Note: the next two regexes use {2,} instead of + to add space on phrases like Room26A and 26ABCs but not on phrases like R2D2 and C3PO"
    .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2") // "To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
    .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2") // "To Get Your GED In Time A Song About The 26 ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
    .trim();

  // capitalize the first letter
  return result.charAt(0).toUpperCase() + result.slice(1);
}

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.className = "OTableHolder";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  // @ts-ignore
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

function convertToCsv(fName: string, rows: any) {
  var csv = "";
  for (var i = 0; i < rows.length; i++) {
    var row = rows[i];
    for (var j = 0; j < row.length; j++) {
      var val = row[j] === null ? "" : row[j].toString();
      val = val.replace(/\t/gi, " ");
      if (j > 0) csv += "\t";
      csv += val;
    }
    csv += "\n";
  }

  // for UTF-16
  var cCode,
    bArr = [];
  bArr.push(255, 254);
  for (var i = 0; i < csv.length; ++i) {
    cCode = csv.charCodeAt(i);
    bArr.push(cCode & 0xff);
    bArr.push((cCode / 256) >>> 0);
  }

  var blob = new Blob([new Uint8Array(bArr)], { type: "text/csv;charset=UTF-16LE;" });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fName);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = window.URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }
}

export enum Searchenum {
  includesString = "includesString",
  startString = "startString"
}
export enum Sortmodes {
  up = "up",
  down = "down",
  none = "none"
}

export interface Column {
  key: string;
  name?: string | ReactNode;
  formatter?: (value: any, row: any) => string | number | ReactNode;
  activ?: boolean;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  className?: string;
  headerClassName?: string;
  searchfunc?: ((searchvalue: any, dataEntrie: any) => boolean) | Searchenum;
  customSearchRenderer?: (value: any, savename: string) => ReactNode;
  sortable?: boolean;
  sortFunction?: (a: any, b: any, mode: Sortmodes) => number;
  noCSV?: boolean;
  csvFormatter?: (row: any, value: any) => string | number;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
}

type TableDataType = {
  ID: string;
};

type MyTableProps<T extends TableDataType> = {
  language: LanguageClassObject;
  data: T[];
  height: number;
  itemsPerPage: number;
  columns: Column[];
  extensible?: (row: T) => ReactNode;
  search?: boolean;
  searchtype: "and" | "or";
  size: "small" | "medium" | "large";
  bordered?: boolean;
  striped?: boolean;
  mouseOver?: boolean;
  onDelete?: (row: T) => void;
  onEdit?: (row: T) => void;
  onDetails?: (row: T) => void;
  actionRenderer?: (row: T) => string | ReactNode;
  pagination?: boolean;
  customizeName?: string;
  checkBoxArray?: string[];
  checkBoxActions?: ReactNode[];
  withCSV?: boolean;
  downloadName?: string;
  actionWidth?: number;
  onlyTableOptions?: boolean;
  getSubTableData?: (row: T) => [Column[], any[]];
  additionalHeaderItems?: ReactNode;
};

@observer
export class OTable<T extends TableDataType> extends Component<MyTableProps<T>> {
  @observable checkedAll: boolean = false;

  @observable expandAll: boolean = false;
  @observable expandArray: string[] = [];
  @observable itemsPerPage: { value: number } = { value: 30 };
  @observable currentPage: { value: number } = { value: 1 };
  @observable searchValues = {};
  @observable currentsort: { key: string; mode: Sortmodes; sortfunction: (a: any, b: any, mode: Sortmodes) => number } = {
    key: "",
    mode: Sortmodes.none,
    sortfunction: () => 0
  };

  @computed get tablewidth() {
    let width = this.props.columns.reduce((prev, cur) => prev + (cur.activ ? (cur.width !== undefined ? cur.width : 150) : 0), 0);
    if (this.props.onDelete || this.props.onDetails || this.props.onEdit || this.props.actionRenderer || this.props.checkBoxArray !== undefined) {
      width += this.props.actionWidth !== undefined ? this.props.actionWidth : 100;
    }

    if (this.props.extensible !== undefined) {
      width += 20;
    }
    width += 1;
    return width;
  }
  @computed get filteredData() {
    if (!this.props.search) return this.props.data.slice();
    return this.props.data.filter(dataentrie => {
      let passed: any = null;
      entries(this.searchValues).map(searchentrie => {
        let [searchKey, searchValue] = searchentrie;
        if (searchValue === "") return true;
        let col = this.props.columns.find(c => c.key === searchKey);
        if (col?.searchfunc) {
          let passedfunc = true;
          if (col.searchfunc === Searchenum.includesString)
            passedfunc = dataentrie[searchKey]
              ?.toString()
              .toLowerCase()
              .includes((searchValue as String).toLowerCase());
          else if (col.searchfunc === Searchenum.startString)
            passedfunc = dataentrie[searchKey]
              ?.toString()
              .toLowerCase()
              .startsWith((searchValue as String).toLowerCase());
          else {
            passedfunc = col.searchfunc(searchValue, dataentrie);
          }

          if (!passedfunc) {
            if ((this.props.searchtype === "or" && passed === null) || this.props.searchtype === "and") passed = false;
          } else if (this.props.searchtype === "or") passed = true;
        }
      });
      return passed === null ? true : passed;
    });
  }
  @computed get sortedData() {
    let data =
      this.currentsort.mode !== Sortmodes.none
        ? this.filteredData.slice().sort((a: any, b: any) => {
            return this.currentsort.sortfunction(a, b, this.currentsort.mode);
          })
        : this.filteredData.slice();
    return data;
  }
  @computed get tabledata() {
    let data = this.props.pagination
      ? this.sortedData.slice((this.currentPage.value - 1) * this.itemsPerPage.value, this.currentPage.value * this.itemsPerPage.value)
      : this.sortedData.slice();

    return data;
  }
  static defaultProps = {
    height: 800,
    itemsPerPage: 30,
    searchtype: "and",
    size: "small",
    bordered: true,
    striped: true,
    mouseOver: true,
    search: false,
    pagination: true,
    withCSV: false
  };

  constructor(props: MyTableProps<T>) {
    super(props);
    autorun(() => {
      this.currentPage.value = this.currentPage.value.toString() !== "" ? parseInt(this.currentPage.value.toString()) : 1;
      if (this.currentPage.value > Math.ceil(this.filteredData.length / this.itemsPerPage.value))
        this.currentPage.value = Math.ceil(this.filteredData.length / this.itemsPerPage.value);
      if (this.currentPage.value < 1 && this.filteredData.length > 0) this.currentPage.value = 1;
    });
    this.itemsPerPage.value = this.props.itemsPerPage !== undefined ? this.props.itemsPerPage : 30;
    if (this.props.customizeName) {
      let tablesettings = lscache.get(this.props.customizeName);

      if (tablesettings !== null) {
        console.log("tablesettings", tablesettings);
        tablesettings.map((setting: any, index: number) => {
          let oldIndex = this.props.columns.findIndex((v: any) => setting.savename === v.key);

          if (oldIndex !== -1) {
            this.props.columns[oldIndex].activ = setting.activ;
            this.props.columns[oldIndex].width = setting.width;
          }
          if (oldIndex !== index) {
            // @ts-ignore
            this.props.columns.move(oldIndex, index);
          }
        });
      }
    }
  }

  @action
  changePage = (a: "first" | "last" | "next" | "prev") => {
    this.checkedAll = false;
    this.expandAll = false;
    this.expandArray.splice(0, this.expandArray.length);
    if (a === "first") this.currentPage.value = 1;
    else if (a === "last") this.currentPage.value = Math.ceil(this.filteredData.length / this.itemsPerPage.value);
    else if (a === "prev") --this.currentPage.value;
    else if (a === "next") ++this.currentPage.value;
  };

  @action
  sort = (key: string, sortFunction?: (a: any, b: any, mode: Sortmodes) => number, e?: any) => {
    this.currentsort.mode = this.currentsort.mode === Sortmodes.none ? Sortmodes.up : this.currentsort.mode === Sortmodes.up ? Sortmodes.down : Sortmodes.none;
    console.log("key", key);
    console.log("function", sortFunction);
    //console.log(this.currentsort.mode);
    if (this.currentsort.key !== key) {
      this.currentsort.mode = Sortmodes.up;
    }
    this.currentsort.key = key;
    this.currentsort.sortfunction = sortFunction
      ? sortFunction
      : (a, b, mode) => (mode === Sortmodes.up ? (a < b ? -1 : a == b ? 0 : 1) : a < b ? 1 : a == b ? 0 : -1);
  };

  @action checkBoxClickAll = (e: any) => {
    this.checkedAll = !this.checkedAll;
    if (e.target.checked) {
      this.tabledata.map(row => {
        let index = this.props.checkBoxArray?.indexOf(row.ID);
        if (index === -1) this.props.checkBoxArray?.push(row.ID);
      });
    } else {
      this.tabledata.map(row => {
        let index = this.props.checkBoxArray?.indexOf(row.ID);
        if (index !== -1 && index !== undefined) this.props.checkBoxArray?.splice(index, 1);
      });
    }
  };

  @action expandClickAll = () => {
    if (!this.expandAll) {
      this.tabledata.map(row => {
        let index = this.expandArray?.indexOf(row.ID);
        if (index === -1) this.expandArray?.push(row.ID);
      });
    } else {
      this.tabledata.map(row => {
        let index = this.expandArray?.indexOf(row.ID);
        if (index !== -1 && index !== undefined) this.expandArray?.splice(index, 1);
      });
    }
    this.expandAll = !this.expandAll;
  };

  saveCustomizeTable = () => {
    let savecols = this.props.columns.map(c => {
      return { activ: c.activ !== undefined ? c.activ : true, savename: c.key, width: c.width };
    });
    console.log(savecols);
    lscache.set(this.props.customizeName as string, savecols);
  };

  downloadCSV = (type: number, withsubtable?: boolean, withColNames: boolean = true, withSubColNames: boolean = false) => {
    let data = [];
    if (type === 1) {
      data = this.props.data.filter(d => (this.props.checkBoxArray ? this.props.checkBoxArray.includes(d.ID) : false));
    } else if (type === 2) {
      data = this.tabledata;
    } else {
      data = this.props.data;
    }

    let rows: any[] = [];
    let cols: any[] = [];
    this.props.columns.map(col => {
      if ((col.activ || col.activ === undefined) && !col.noCSV) {
        cols.push(col);
      }
    });
    if (withColNames) {
      rows.push(
        cols.map(col =>
          col.name !== undefined && col.constructor === String
            ? col.name
            : this.props.language.l[col.key] !== undefined
            ? this.props.language.l[col.key]
            : camelCaseToTitleCase(col.key)
        )
      );
    }
    data.map((d: any) => {
      let rowdata: any[] = [];
      cols.map((c: Column) => {
        let value = d[c.key];
        let csvValue: string | number = "";
        if (c.csvFormatter !== undefined) csvValue = c.csvFormatter(d, value);
        else csvValue = value !== undefined ? value : "";
        rowdata.push(csvValue);
      });
      rows.push(rowdata);
      if (withsubtable) {
        // @ts-ignore

        let [subcols, subdata] = this.props.getSubTableData(d);
        let rowsinner: any[] = [];
        let colsinner: any[] = [];
        subcols.map(col => {
          if ((col.activ || col.activ === undefined) && !col.noCSV) {
            colsinner.push(col);
          }
        });
        if (withSubColNames) {
          rowsinner.push(
            colsinner.map(col =>
              col.name !== undefined && col.constructor === String
                ? col.name
                : this.props.language.l[col.key] !== undefined
                ? this.props.language.l[col.key]
                : camelCaseToTitleCase(col.key)
            )
          );
        }
        subdata.map((di: any) => {
          let rowdatainner: any[] = [];
          colsinner.map((c: Column) => {
            let value = di[c.key];
            let csvValue: string | number = "";
            if (c.csvFormatter !== undefined) csvValue = c.csvFormatter(di, value);
            else csvValue = value !== undefined ? value : "";
            rowdatainner.push(csvValue);
          });
          rowsinner.push(rowdatainner);
        });
        rowsinner.map((subdataLine: any[]) => {
          subdataLine.unshift("");
          rows.push(subdataLine);
        });
      }
      console.log(rows);
    });

    convertToCsv(this.props.downloadName !== undefined ? this.props.downloadName + ".csv" : "Tabelle.csv", rows);
    /*let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(";")).join("\n");
    var encodedUri = encodeURI(csvContent);
    console.log("data", encodedUri);
    window.open(encodedUri);*/
  };
  getColName = (col: Column) => {
    return col.name ? col.name : this.props.language.l[col.key] !== undefined ? this.props.language.l[col.key] : camelCaseToTitleCase(col.key);
  };
  render() {
    return (
      <div style={{ width: "100%", overflow: "hidden" }}>
        <div style={{ width: this.tablewidth + getScrollbarWidth(), overflowX: "auto", maxWidth: "100%" }}>
          {this.props.pagination || this.props.onlyTableOptions || this.props.additionalHeaderItems !== undefined ? (
            <OTableHeader
              additionalHeaderItems={this.props.additionalHeaderItems}
              getColName={this.getColName}
              tablewidth={this.tablewidth}
              getSubTableData={this.props.getSubTableData}
              onlyTableOptions={this.props.onlyTableOptions}
              columns={this.props.columns}
              customizedName={this.props.customizeName}
              size={this.props.size}
              saveCustomizeTable={this.saveCustomizeTable}
              checkBoxArray={this.props.checkBoxArray}
              language={this.props.language}
              checkBoxActions={this.props.checkBoxActions}
              withCSV={this.props.withCSV}
              downloadCSV={this.downloadCSV}
            />
          ) : null}
          <table
            id="table-to-xls"
            className={
              "OTable OTable-" +
              this.props.size +
              (this.props.bordered ? " OTable-bordered" : "") +
              (this.props.striped ? " OTable-striped" : "") +
              (this.props.mouseOver ? " OTable-mouseOver" : "") +
              (this.props.extensible ? " OExtenseTable" : "") +
              (this.props.checkBoxArray !== undefined ? " OCheckboxTable" : "")
            }
            style={{ width: this.tablewidth + getScrollbarWidth() - (this.expandArray !== undefined && this.expandArray.length > 0 ? 1 : 0) }}
          >
            <thead>
              <tr className={"OTableHeader"}>
                {this.props.extensible !== undefined ? (
                  !this.props.search ? (
                    <th style={{ width: "20px" }} className={"OTableNoBorder"}>
                      <OurFontAwsomeIcon
                        color={"#34495c"}
                        style={{ cursor: "pointer", margin: "0" }}
                        onClick={this.expandClickAll}
                        icon={this.expandAll ? faMinus : faPlus}
                      />
                    </th>
                  ) : (
                    <th style={{ width: "20px" }} className={"OTableNoBorder"} />
                  )
                ) : null}

                {this.props.columns.map((col: Column) => {
                  return col.activ === undefined || col.activ ? (
                    <th
                      key={col.key}
                      className={"OTableHeaderItem" + (col.headerClassName ? " " + col.headerClassName : "")}
                      style={{ ...col.headerStyle, width: col.width !== undefined ? col.width : "150px" }}
                    >
                      {col.sortable ? (
                        <Row fullLine style={{ alignItems: "center", color: "inherit" }}>
                          <div style={{ color: "inherit", marginTop: "" }}>{this.getColName(col)}</div>
                          <Column style={{ cursor: "pointer" }} onClick={this.sort.bind(this, col.key, col.sortFunction)}>
                            <OurFontAwsomeIcon
                              color={this.currentsort.key === col.key && this.currentsort.mode === Sortmodes.up ? "white" : "grey"}
                              icon={faSortUp}
                            />
                            <OurFontAwsomeIcon
                              color={this.currentsort.key === col.key && this.currentsort.mode === Sortmodes.down ? "white" : "grey"}
                              style={{ marginTop: "-1em" }}
                              icon={faSortDown}
                            />
                          </Column>
                        </Row>
                      ) : (
                        this.getColName(col)
                      )}
                    </th>
                  ) : null;
                })}
                {this.props.onDelete || this.props.onDetails || this.props.onEdit || this.props.actionRenderer || this.props.checkBoxArray !== undefined ? (
                  this.props.checkBoxArray !== undefined && !this.props.search ? (
                    <th
                      className={"OTableHeaderItem"}
                      style={{ paddingRight: "4px", width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }}
                    >
                      <Row centerVertical={true} style={{ justifyContent: "flex-end" }}>
                        <Checkbox checked={this.checkedAll} onChange={this.checkBoxClickAll} />
                      </Row>
                    </th>
                  ) : (
                    <th className={"OTableHeaderItem"} style={{ width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }} />
                  )
                ) : null}
              </tr>
              {this.props.search ? (
                <tr className={"OTableSearchbar"}>
                  {this.props.extensible !== undefined ? (
                    <td className={"OTableNoBorder"} style={{ width: "20px" }}>
                      <OurFontAwsomeIcon
                        color={"#34495c"}
                        style={{ cursor: "pointer", margin: "0" }}
                        onClick={this.expandClickAll}
                        icon={this.expandAll ? faMinus : faPlus}
                      />
                    </td>
                  ) : null}

                  {this.props.columns.map((col: Column) => {
                    return col.activ === undefined || col.activ ? (
                      col.searchfunc ? (
                        col.customSearchRenderer ? (
                          <td
                            key={col.key}
                            className={"OTableSearchbarItem" + (col.headerClassName ? " " + col.headerClassName : "")}
                            style={{ ...col.headerStyle, width: col.width !== undefined ? col.width : "150px" }}
                          >
                            {col.customSearchRenderer(this.searchValues, col.key)}
                          </td>
                        ) : (
                          <td
                            key={col.key}
                            className={"OTableSearchbarItem" + (col.headerClassName ? " " + col.headerClassName : "")}
                            style={{ ...col.headerStyle, width: col.width !== undefined ? col.width : "150px" }}
                          >
                            <OInput autoWidth height={this.props.size} value={this.searchValues} savename={col.key} />
                          </td>
                        )
                      ) : (
                        <td
                          key={col.key}
                          className={"OTableSearchbarItem" + (col.headerClassName ? " " + col.headerClassName : "")}
                          style={{ ...col.headerStyle, width: col.width !== undefined ? col.width : "150px" }}
                        />
                      )
                    ) : null;
                  })}
                  {this.props.onDelete || this.props.onDetails || this.props.onEdit || this.props.actionRenderer || this.props.checkBoxArray !== undefined ? (
                    this.props.checkBoxArray !== undefined ? (
                      <td style={{ width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }} className={"OTableItem"}>
                        <Row centerVertical={true} style={{ justifyContent: "flex-end" }}>
                          <Checkbox checked={this.checkedAll} onChange={this.checkBoxClickAll} />
                        </Row>
                      </td>
                    ) : (
                      <td className={"OTableSearchbarItem"} style={{ width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }} />
                    )
                  ) : null}
                </tr>
              ) : null}
            </thead>
          </table>
          <div
            style={{
              maxHeight: this.props.height !== undefined ? this.props.height + "px" : "800px",
              overflowY: "scroll",
              borderBottom: "1px solid darkgrey",
              width: this.tablewidth + getScrollbarWidth()
            }}
            className={"OTableHolder"}
          >
            <table
              id="table-to-xls"
              className={
                "OTable OTable-" +
                this.props.size +
                (this.props.bordered ? " OTable-bordered" : "") +
                (this.props.striped ? " OTable-striped" : "") +
                (this.props.mouseOver ? " OTable-mouseOver" : "") +
                (this.props.extensible ? " OExtenseTable" : "") +
                (this.props.checkBoxArray !== undefined ? " OCheckboxTable" : "")
              }
            >
              <OTableBody
                columns={this.props.columns}
                checkBoxArray={this.props.checkBoxArray}
                expandArray={this.expandArray}
                size={this.props.size}
                tabledata={this.tabledata}
                actionRenderer={this.props.actionRenderer}
                extensible={this.props.extensible}
                onDelete={this.props.onDelete}
                onDetails={this.props.onDetails}
                onEdit={this.props.onEdit}
                language={this.props.language}
                actionWidth={this.props.actionWidth}
              />
            </table>
          </div>
          {this.props.pagination ? (
            <OTableFooter
              tablewidth={this.tablewidth}
              filteredData={this.filteredData}
              changePage={this.changePage}
              currentPage={this.currentPage}
              itemsPerPage={this.itemsPerPage}
              size={this.props.size}
              language={this.props.language}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

interface OwnPropsFooter {
  tablewidth: number;
  language: LanguageClassObject;
  size: "small" | "medium" | "large";
  itemsPerPage: { value: number };
  changePage: (a: "first" | "last" | "next" | "prev") => void;
  currentPage: { value: number };
  filteredData: any[];
}

type TableFooterType = OwnPropsFooter;

@observer
class OTableFooter extends Component<TableFooterType> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    let l = this.props.language.l;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          color: "white",
          alignItems: "center",
          width: this.props.tablewidth + getScrollbarWidth()
        }}
      >
        <div style={{ color: "black" }}>
          <OSelect
            allowClear={false}
            width={this.props.size === "small" ? 3.5 : 4.5}
            labelpos={"left"}
            label={l["ProSeite"]}
            labelsize={this.props.size === "small" ? 55 : 70}
            height={this.props.size}
            value={this.props.itemsPerPage}
            savename={"value"}
          >
            <OOption value={10}>10</OOption>
            <OOption value={20}>20</OOption>
            <OOption value={30}>30</OOption>
            <OOption value={50}>50</OOption>
            <OOption value={100}>100</OOption>
          </OSelect>
        </div>

        <Button
          onClick={this.props.changePage.bind(this, "first")}
          size={this.props.size === "medium" ? "default" : this.props.size}
          style={{
            margin: "6px",
            marginRight: "4px",
            padding: "2px"
          }}
        >
          <OurFontAwsomeIcon icon={faFastBackward} color={"grey"} />
        </Button>

        <Button
          onClick={this.props.changePage.bind(this, "prev")}
          size={this.props.size === "medium" ? "default" : this.props.size}
          style={{
            margin: "6px",
            marginLeft: "4px",
            marginRight: "4px",
            padding: "2px"
          }}
        >
          <OurFontAwsomeIcon icon={faBackward} color={"grey"} />
        </Button>

        <div
          style={{
            fontSize: this.props.size === "small" ? "11px" : this.props.size === "medium" ? "13px" : "14px"
          }}
        >
          {l["Seite"]}
        </div>
        <div>
          <OInput
            min={1}
            max={Math.ceil(this.props.filteredData.length / this.props.itemsPerPage.value)}
            height={this.props.size}
            width={2}
            value={this.props.currentPage}
            savename={"value"}
          />
        </div>
        <div
          style={{
            fontSize: this.props.size === "small" ? "11px" : this.props.size === "medium" ? "13px" : "14px",
            whiteSpace: "nowrap"
          }}
        >
          {l["von"]} {Math.ceil(this.props.filteredData.length / this.props.itemsPerPage.value)}
        </div>
        <Button
          onClick={this.props.changePage.bind(this, "next")}
          size={this.props.size === "medium" ? "default" : this.props.size}
          style={{
            margin: "6px",
            marginLeft: "4px",
            marginRight: "4px",
            padding: "2px"
          }}
        >
          <OurFontAwsomeIcon icon={faForward} color={"grey"} />
        </Button>
        <Button
          onClick={this.props.changePage.bind(this, "last")}
          size={this.props.size === "medium" ? "default" : this.props.size}
          style={{
            margin: "6px",
            marginLeft: "4px",
            marginRight: "4px",
            padding: "2px"
          }}
        >
          <OurFontAwsomeIcon icon={faFastForward} color={"grey"} />
        </Button>
      </div>
    );
  }
}

interface OwnPropsHeader {
  checkBoxArray: string[] | undefined;
  tablewidth: number;
  getColName: (col: Column) => any;
  language: LanguageClassObject;
  checkBoxActions?: ReactNode[];
  additionalHeaderItems?: ReactNode;
  withCSV?: boolean;
  getSubTableData?: (row: any) => [Column[], any[]];
  downloadCSV: (type: number, withsubtable?: boolean, withColNames?: boolean, withSubColNames?: boolean) => void;
  size: "small" | "medium" | "large";
  saveCustomizeTable: () => void;
  customizedName: string | undefined;
  columns: Column[];
  onlyTableOptions?: boolean;
}

type TableHeaderType = OwnPropsHeader;

@observer
class OTableHeader extends Component<TableHeaderType> {
  @observable expanded: boolean = false;
  @observable csvexpanded: boolean = false;
  @observable csvDownloadOptions: { type: number; withsubtable: boolean; withColNames: boolean; withSubColNames: boolean } = {
    type: 2,
    withColNames: true,
    withSubColNames: false,
    withsubtable: false
  };
  static defaultProps = {
    downloadCSV: () => {}
  };

  constructor(props: any) {
    super(props);
  }
  @action toggleOptionsDrawer = () => {
    this.expanded = !this.expanded;
  };
  @action toggleCSVDrawer = () => {
    this.csvexpanded = !this.csvexpanded;
  };
  @action colMove = (index: number, direction: number, e: any) => {
    // @ts-ignore
    this.props.columns.move(index, index + direction);
  };
  saveCustomizeTable = () => {
    this.props.saveCustomizeTable();
    this.toggleOptionsDrawer();
  };
  downloadCSV = () => {
    this.props.downloadCSV(
      this.csvDownloadOptions.type,
      this.csvDownloadOptions.withsubtable,
      this.csvDownloadOptions.withColNames,
      this.csvDownloadOptions.withSubColNames
    );
    this.csvDownloadOptions.type = 2;
    this.csvDownloadOptions.withSubColNames = false;
    this.csvDownloadOptions.withColNames = true;
    this.csvDownloadOptions.withsubtable = false;
    this.toggleCSVDrawer();
  };
  getMarks = (col: Column) => {
    let maxWidth = col.maxWidth !== undefined ? col.maxWidth : 300;
    let minWidth = col.minWidth !== undefined ? col.minWidth : 50;
    let resObj = {};
    resObj[minWidth] = minWidth;
    resObj[maxWidth] = maxWidth;
    let curWidth = minWidth + 50;
    while (curWidth < maxWidth) {
      resObj[curWidth] = curWidth;
      curWidth += 50;
    }
    return resObj;
  };
  render() {
    let l = this.props.language.l;
    return (
      <Row centerVertical={true} style={{ width: this.props.tablewidth + getScrollbarWidth() }}>
        {this.props.additionalHeaderItems !== undefined ? this.props.additionalHeaderItems : null}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "white",
            alignItems: "center",
            width: "100%"
          }}
        >
          {!this.props.onlyTableOptions ? (
            <>
              {this.props.withCSV ? (
                <>
                  <OButton onClick={this.toggleCSVDrawer} height={this.props.size} width={3} color={ColorEnum.primary}>
                    {l["CSVdownload"]}
                  </OButton>

                  <Drawer width={200} onClose={this.toggleCSVDrawer} title={l["CSVdownload"]} placement={"right"} closable={true} visible={this.csvexpanded}>
                    <OSelect
                      allowClear={false}
                      savename={"type"}
                      value={this.csvDownloadOptions}
                      label={this.props.language.l["Datenauswahl"]}
                      height={this.props.size}
                    >
                      {this.props.checkBoxArray !== undefined ? (
                        <OurOption disabled={this.props.checkBoxArray.length === 0} value={1}>
                          {l["Auswahl"]}
                        </OurOption>
                      ) : null}
                      <OurOption value={2}>{l["Seite"]}</OurOption>
                      <OurOption value={3}>{l["Alles"]}</OurOption>
                    </OSelect>
                    <OSwitch
                      savename={"withsubtable"}
                      value={this.csvDownloadOptions}
                      label={this.props.language.l["MitUntertablle"]}
                      height={this.props.size}
                    />
                    <OSwitch
                      savename={"withColNames"}
                      value={this.csvDownloadOptions}
                      label={this.props.language.l["MitSpaltenNamen"]}
                      height={this.props.size}
                    />
                    {this.csvDownloadOptions.withsubtable ? (
                      <OSwitch
                        savename={"withSubColNames"}
                        value={this.csvDownloadOptions}
                        label={this.props.language.l["MitSpaltenNamenUntertablle"]}
                        height={this.props.size}
                      />
                    ) : null}
                    <OButton onClick={this.downloadCSV} height={this.props.size} color={ColorEnum.primary} style={{ width: "150px" }}>
                      {l["Download"]}
                    </OButton>
                  </Drawer>
                </>
              ) : null}

              <OButton height={this.props.size} color={ColorEnum.primary} style={{}} onClick={this.toggleOptionsDrawer}>
                <OurFontAwsomeIcon color={"white"} style={{ cursor: "pointer", margin: "0" }} icon={faCog} />
              </OButton>

              {this.props.checkBoxArray !== undefined ? (
                <OTableActions language={this.props.language} disabled={this.props.checkBoxArray.length === 0} checkedArray={this.props.checkBoxArray}>
                  {this.props.checkBoxActions ? [...this.props.checkBoxActions] : null}
                </OTableActions>
              ) : null}
            </>
          ) : (
            <OButton
              height={this.props.size}
              color={ColorEnum.primary}
              style={{
                marginRight: "20px"
              }}
              onClick={this.toggleOptionsDrawer}
            >
              <OurFontAwsomeIcon color={"white"} style={{ cursor: "pointer", margin: "0" }} icon={faCog} />
            </OButton>
          )}
          {this.props.customizedName ? (
            <>
              <Drawer
                width={580}
                onClose={this.toggleOptionsDrawer}
                title={l["TabellenEinstellungen"]}
                placement={"right"}
                closable={true}
                visible={this.expanded}
              >
                <table style={{ borderCollapse: "collapse", marginBottom: "20px", width: "350px", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid darkgrey", padding: "5px", width: "204px", wordWrap: "break-word" }}>{l["Name"]}</th>
                      <th style={{ border: "1px solid darkgrey", padding: "5px", width: "50px", wordWrap: "break-word" }}>{l["Aktiv"]}</th>
                      <th style={{ border: "1px solid darkgrey", padding: "5px", width: "96px" }}>{l["Position"]}</th>
                      <th style={{ border: "1px solid darkgrey", padding: "5px", width: "180px" }}>{l["Breite"]}</th>
                    </tr>
                  </thead>
                  {this.props.columns.map((col, index) => (
                    <tbody key={col.key}>
                      <tr>
                        <td
                          style={{
                            padding: "5px",
                            border: "1px solid darkgrey"
                          }}
                        >
                          {this.props.getColName(col)}
                        </td>
                        <td style={{ border: "1px solid darkgrey", textAlign: "center" }}>
                          <OCheckbox autoWidth value={col} savename={"activ"} />
                        </td>
                        <td style={{ border: "1px solid darkgrey" }}>
                          <Row>
                            <Button
                              disabled={index === 0}
                              onClick={this.colMove.bind(this, index, -1)}
                              style={{ marginLeft: "6px", marginRight: "6px" }}
                              size="small"
                              type={"primary"}
                            >
                              <OurFontAwsomeIcon icon={faArrowUp} />
                            </Button>

                            <Button
                              disabled={index === this.props.columns.length - 1}
                              onClick={this.colMove.bind(this, index, 1)}
                              style={{ marginRight: "6px" }}
                              size="small"
                              type={"primary"}
                            >
                              <OurFontAwsomeIcon icon={faArrowDown} />
                            </Button>
                          </Row>
                        </td>
                        <td style={{ border: "1px solid darkgrey", textAlign: "center" }}>
                          {col.minWidth !== undefined && col.maxWidth !== undefined && col.maxWidth === col.minWidth ? null : (
                            <OSlider
                              max={col.maxWidth !== undefined ? col.maxWidth : 300}
                              min={col.minWidth !== undefined ? col.minWidth : 50}
                              autoWidth
                              value={col}
                              step={10}
                              defaultValue={150}
                              marks={this.getMarks(col)}
                              savename={"width"}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
                <Row fullLine>
                  <OButton onClick={this.saveCustomizeTable} height={this.props.size} color={ColorEnum.primary} style={{ width: "150px" }}>
                    {l["Immeranwenden"]}
                  </OButton>
                  <OButton onClick={this.toggleOptionsDrawer} height={this.props.size} color={ColorEnum.primary} style={{ width: "150px" }}>
                    {l["Nurjeztanwenden"]}
                  </OButton>

                  {this.props.withCSV ? (
                    <OButton onClick={this.toggleCSVDrawer} height={this.props.size} width={3} color={ColorEnum.primary}>
                      {l["CSVdownload"]}
                    </OButton>
                  ) : null}
                </Row>
              </Drawer>
            </>
          ) : null}
        </div>
      </Row>
    );
  }
}

interface OwnPropsTableBody {
  checkBoxArray: string[] | undefined;
  language: LanguageClassObject;
  tabledata: any[];
  extensible?: (row: any) => ReactNode;
  expandArray: string[];
  columns: Column[];
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onDetails?: (row: any) => void;
  actionRenderer?: (row: any) => string | ReactNode;
  size: "small" | "medium" | "large";
  actionWidth?: number;
}

type TableBodyType = OwnPropsTableBody;

@observer
class OTableBody extends Component<TableBodyType> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }
  @action expandClick = (rowID: string) => {
    if (this.props.expandArray.includes(rowID)) {
      let index = this.props.expandArray.findIndex(v => v === rowID);
      if (index !== -1 && index !== undefined) this.props.expandArray?.splice(index, 1);
    } else {
      this.props.expandArray.push(rowID);
    }
  };
  @action checkBoxClick = (rowID: string, e: any) => {
    if (e.target.checked) this.props.checkBoxArray?.push(rowID);
    else {
      let index = this.props.checkBoxArray?.indexOf(rowID);
      if (index !== -1 && index !== undefined) this.props.checkBoxArray?.splice(index, 1);
    }
  };
  showDeleteConfirm = (exfunc: () => void) => {
    let l = this.props.language.l;
    confirm({
      title: l["WollenSiedenEintragwirklichloeschen"],
      okText: l["Ja"],
      okType: "danger",
      cancelText: l["Nein"],
      onOk() {
        exfunc();
      },
      onCancel() {}
    });
  };
  @action deleteConfirm = (row: any) => {
    this.showDeleteConfirm(() => {
      if (this.props.onDelete !== undefined) {
        this.props.onDelete(row);
        this.props.checkBoxArray?.splice(0, this.props.checkBoxArray.length);
      }
    });
  };
  render() {
    return (
      <tbody className={"OTableBody"}>
        {this.props.tabledata.map((row: any) => {
          return (
            <React.Fragment key={row.ID ? row.ID : row.id ? row.id : row.fakeKey ? row.fakeKey : undefined}>
              <tr className={"OTableRow"}>
                {this.props.extensible !== undefined ? (
                  <td className={"OTableNoBorder OTableItem"} style={{ width: "20px" }}>
                    <OurFontAwsomeIcon
                      color={"#34495c"}
                      style={{ cursor: "pointer", margin: "0" }}
                      onClick={this.expandClick.bind(this, row.ID)}
                      icon={this.props.expandArray.includes(row.ID) ? faMinus : faPlus}
                    />
                  </td>
                ) : null}

                {this.props.columns.map((col: Column) => (
                  <OTableTdMobx col={col} row={row} />
                ))}

                {this.props.onDelete || this.props.onDetails || this.props.onEdit || this.props.actionRenderer || this.props.checkBoxArray !== undefined ? (
                  <td className={"OTableItem"} style={{ width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }}>
                    <Row centerVertical={true} fullLine={true} style={{ width: "100%" }}>
                      {this.props.actionRenderer !== undefined ? this.props.actionRenderer(row) : null}
                      <Row centerVertical={true} style={{ justifyContent: "flex-end" }}>
                        <Row centerVertical={true} style={{ fontSize: this.props.size === "small" ? "11px" : this.props.size === "medium" ? "13px" : "14px" }}>
                          {this.props.onEdit !== undefined ? (
                            <OurFontAwsomeIcon style={{ cursor: "pointer" }} color={"#1890ff"} icon={faEdit} onClick={this.props.onEdit.bind(this, row)} />
                          ) : null}
                          {this.props.onDetails !== undefined ? (
                            <OurFontAwsomeIcon style={{ cursor: "pointer" }} color={"#1890ff"} icon={faSearch} onClick={this.props.onDetails.bind(this, row)} />
                          ) : null}
                          {this.props.onDelete !== undefined ? (
                            <OurFontAwsomeIcon style={{ cursor: "pointer" }} color={"#ff4d4f"} icon={faTrashAlt} onClick={this.deleteConfirm.bind(this, row)} />
                          ) : null}
                          {this.props.checkBoxArray !== undefined ? (
                            <Checkbox checked={this.props.checkBoxArray.includes(row.ID)} onChange={this.checkBoxClick.bind(this, row.ID)} />
                          ) : null}
                        </Row>
                      </Row>
                    </Row>
                  </td>
                ) : null}
              </tr>
              {this.props.expandArray.includes(row.ID) ? (
                <tr className={"OSubtable"}>
                  <td colSpan={100}>{this.props.extensible ? this.props.extensible(row) : null} </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </tbody>
    );
  }
}

interface OwnPropsTableTdMobx {
  col: Column;
  row: any;
}

type TypeTableTdMobx = OwnPropsTableTdMobx;

const OTableTdMobx: FC<TypeTableTdMobx> = observer(props => {
  return (
    <>
      {props.col.activ !== false ? (
        props.row[props.col.key] !== undefined || props.col.formatter !== undefined ? (
          <>
            <td
              key={props.col.key}
              className={"OTableItem" + (props.col.className ? " " + props.col.className : "")}
              style={{ ...props.col.style, width: props.col.width !== undefined ? props.col.width : "150px" }}
            >
              {props.col.formatter ? props.col.formatter(props.row[props.col.key], props.row) : props.row[props.col.key]}
            </td>
          </>
        ) : (
          <td
            key={props.col.key}
            className={"OTableItem" + (props.col.headerClassName ? " " + props.col.headerClassName : "")}
            style={{ ...props.col.headerStyle, width: props.col.width !== undefined ? props.col.width : "150px" }}
          />
        )
      ) : null}
    </>
  );
});

interface OwnPropsActionRenderer extends SelectProps {
  disabled: boolean;
  checkedArray: string[];
  language: LanguageClassObject;
}

type ActionRendererType = OwnPropsActionRenderer;

@observer
class OTableActions extends Component<ActionRendererType> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Dropdown disabled={this.props.disabled} trigger={["click"]} overlay={<Menu>{this.props.children}</Menu>}>
        <Button style={{ margin: "6px", marginRight: "3px" }} size="small" type={"primary"}>
          {this.props.language.l["MitAuswahl"]}
        </Button>
      </Dropdown>
    );
  }
}

/*@observer
export class OTable extends Component<MyTableProps> {
  @observable checkedAll: boolean = false;

  @observable expandAll: boolean = false;
  @observable expandArray: number[] = [];
  @observable itemsPerPage: { value: number } = { value: 30 };
  @observable currentPage: { value: number } = { value: 1 };
  @observable searchValues = {};
  @observable currentsort: { key: string; mode: Sortmodes; sortfunction: (a: any, b: any, mode: Sortmodes) => number } = {
    key: "",
    mode: Sortmodes.none,
    sortfunction: () => 0
  };

  @computed get tablewidth() {
    let width = this.props.columns.reduce((prev, cur) => prev + (cur.activ ? (cur.width !== undefined ? cur.width : 150) : 0), 0);
    if (this.props.onDelete || this.props.onDetails || this.props.onEdit || this.props.actionRenderer || this.props.checkBoxArray !== undefined) {
      width += this.props.actionWidth !== undefined ? this.props.actionWidth : 100;
    }

    if (this.props.extensible !== undefined) {
      width += 20;
    }
    width += 1;
    return width;
  }
  @computed get filteredData() {
    if (!this.props.search) return this.props.data.slice();
    return this.props.data.filter(dataentrie => {
      let passed: any = null;
      entries(this.searchValues).map(searchentrie => {
        let [searchKey, searchValue] = searchentrie;
        if (searchValue === "") return true;
        let col = this.props.columns.find(c => c.key === searchKey);
        if (col?.searchfunc) {
          let passedfunc = true;
          if (col.searchfunc === Searchenum.includesString)
            passedfunc = dataentrie[searchKey]
              ?.toString()
              .toLowerCase()
              .includes((searchValue as String).toLowerCase());
          else if (col.searchfunc === Searchenum.startString)
            passedfunc = dataentrie[searchKey]
              ?.toString()
              .toLowerCase()
              .startsWith((searchValue as String).toLowerCase());
          else {
            passedfunc = col.searchfunc(searchValue, dataentrie);
          }

          if (!passedfunc) {
            if ((this.props.searchtype === "or" && passed === null) || this.props.searchtype === "and") passed = false;
          } else if (this.props.searchtype === "or") passed = true;
        }
      });
      return passed === null ? true : passed;
    });
  }
  @computed get sortedData() {
    let data =
      this.currentsort.mode !== Sortmodes.none
        ? this.filteredData.slice().sort((a: any, b: any) => {
            return this.currentsort.sortfunction(a, b, this.currentsort.mode);
          })
        : this.filteredData.slice();
    return data;
  }
  @computed get tabledata() {
    let data = this.props.pagination
      ? this.sortedData.slice((this.currentPage.value - 1) * this.itemsPerPage.value, this.currentPage.value * this.itemsPerPage.value)
      : this.sortedData.slice();

    return data;
  }
  static defaultProps = {
    height: 800,
    itemsPerPage: 30,
    searchtype: "and",
    size: "small",
    bordered: true,
    striped: true,
    mouseOver: true,
    search: false,
    pagination: true,
    withCSV: false
  };

  constructor(props: MyTableProps) {
    super(props);
    autorun(() => {
      this.currentPage.value = this.currentPage.value.toString() !== "" ? parseInt(this.currentPage.value.toString()) : 1;
      if (this.currentPage.value > Math.ceil(this.filteredData.length / this.itemsPerPage.value))
        this.currentPage.value = Math.ceil(this.filteredData.length / this.itemsPerPage.value);
      if (this.currentPage.value < 1 && this.filteredData.length > 0) this.currentPage.value = 1;
    });
    this.itemsPerPage.value = this.props.itemsPerPage !== undefined ? this.props.itemsPerPage : 30;
    if (this.props.customizeName) {
      let tablesettings = lscache.get(this.props.customizeName);

      if (tablesettings !== null) {
        console.log("tablesettings", tablesettings);
        tablesettings.map((setting: any, index: number) => {
          let oldIndex = this.props.columns.findIndex((v: any) => setting.savename === v.key);

          if (oldIndex !== -1) {
            this.props.columns[oldIndex].activ = setting.activ;
            this.props.columns[oldIndex].width = setting.width;
          }
          if (oldIndex !== index) {
            // @ts-ignore
            this.props.columns.move(oldIndex, index);
          }
        });
      }
    }
  }

  @action
  changePage = (a: "first" | "last" | "next" | "prev") => {
    this.checkedAll = false;
    this.expandAll = false;
    this.expandArray.splice(0, this.expandArray.length);
    if (a === "first") this.currentPage.value = 1;
    else if (a === "last") this.currentPage.value = Math.ceil(this.filteredData.length / this.itemsPerPage.value);
    else if (a === "prev") --this.currentPage.value;
    else if (a === "next") ++this.currentPage.value;
  };

  @action
  sort = (key: string, sortFunction?: (a: any, b: any, mode: Sortmodes) => number, e?: any) => {
    this.currentsort.mode = this.currentsort.mode === Sortmodes.none ? Sortmodes.up : this.currentsort.mode === Sortmodes.up ? Sortmodes.down : Sortmodes.none;
    console.log("key", key);
    console.log("function", sortFunction);
    //console.log(this.currentsort.mode);
    if (this.currentsort.key !== key) {
      this.currentsort.mode = Sortmodes.up;
    }
    this.currentsort.key = key;
    this.currentsort.sortfunction = sortFunction
      ? sortFunction
      : (a, b, mode) => (mode === Sortmodes.up ? (a < b ? -1 : a == b ? 0 : 1) : a < b ? 1 : a == b ? 0 : -1);
  };

  @action checkBoxClickAll = (e: any) => {
    this.checkedAll = !this.checkedAll;
    if (e.target.checked) {
      this.tabledata.map(row => {
        let index = this.props.checkBoxArray?.indexOf(row.ID);
        if (index === -1) this.props.checkBoxArray?.push(row.ID);
      });
    } else {
      this.tabledata.map(row => {
        let index = this.props.checkBoxArray?.indexOf(row.ID);
        if (index !== -1 && index !== undefined) this.props.checkBoxArray?.splice(index, 1);
      });
    }
  };

  @action expandClickAll = () => {
    if (!this.expandAll) {
      this.tabledata.map(row => {
        let index = this.expandArray?.indexOf(row.ID);
        if (index === -1) this.expandArray?.push(row.ID);
      });
    } else {
      this.tabledata.map(row => {
        let index = this.expandArray?.indexOf(row.ID);
        if (index !== -1 && index !== undefined) this.expandArray?.splice(index, 1);
      });
    }
    this.expandAll = !this.expandAll;
  };

  saveCustomizeTable = () => {
    let savecols = this.props.columns.map(c => {
      return { activ: c.activ !== undefined ? c.activ : true, savename: c.key, width: c.width };
    });
    console.log(savecols);
    lscache.set(this.props.customizeName as string, savecols);
  };

  downloadCSV = (type: number, withsubtable?: boolean, withColNames: boolean = true, withSubColNames: boolean = false) => {
    let data = [];
    if (type === 1) {
      data = this.props.data.filter(d => (this.props.checkBoxArray ? this.props.checkBoxArray.includes(d.ID) : false));
    } else if (type === 2) {
      data = this.tabledata;
    } else {
      data = this.props.data;
    }

    let rows: any[] = [];
    let cols: any[] = [];
    this.props.columns.map(col => {
      if ((col.activ || col.activ === undefined) && !col.noCSV) {
        cols.push(col);
      }
    });
    if (withColNames) {
      rows.push(
        cols.map(col =>
          col.name !== undefined && col.constructor === String
            ? col.name
            : this.props.language.l[col.key] !== undefined
            ? this.props.language.l[col.key]
            : camelCaseToTitleCase(col.key)
        )
      );
    }
    data.map((d: any) => {
      let rowdata: any[] = [];
      cols.map((c: Column) => {
        let value = d[c.key];
        let csvValue: string | number = "";
        if (c.csvFormatter !== undefined) csvValue = c.csvFormatter(d, value);
        else csvValue = value !== undefined ? value : "";
        rowdata.push(csvValue);
      });
      rows.push(rowdata);
      if (withsubtable) {
        // @ts-ignore

        let [subcols, subdata] = this.props.getSubTableData(d);
        let rowsinner: any[] = [];
        let colsinner: any[] = [];
        subcols.map(col => {
          if ((col.activ || col.activ === undefined) && !col.noCSV) {
            colsinner.push(col);
          }
        });
        if (withSubColNames) {
          rowsinner.push(
            colsinner.map(col =>
              col.name !== undefined && col.constructor === String
                ? col.name
                : this.props.language.l[col.key] !== undefined
                ? this.props.language.l[col.key]
                : camelCaseToTitleCase(col.key)
            )
          );
        }
        subdata.map((di: any) => {
          let rowdatainner: any[] = [];
          colsinner.map((c: Column) => {
            let value = di[c.key];
            let csvValue: string | number = "";
            if (c.csvFormatter !== undefined) csvValue = c.csvFormatter(di, value);
            else csvValue = value !== undefined ? value : "";
            rowdatainner.push(csvValue);
          });
          rowsinner.push(rowdatainner);
        });
        rowsinner.map((subdataLine: any[]) => {
          subdataLine.unshift("");
          rows.push(subdataLine);
        });
      }
      console.log(rows);
    });

    convertToCsv(this.props.downloadName !== undefined ? this.props.downloadName + ".csv" : "Tabelle.csv", rows);
    /!*let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(";")).join("\n");
    var encodedUri = encodeURI(csvContent);
    console.log("data", encodedUri);
    window.open(encodedUri);*!/
  };
  getColName = (col: Column) => {
    return col.name ? col.name : this.props.language.l[col.key] !== undefined ? this.props.language.l[col.key] : camelCaseToTitleCase(col.key);
  };
  render() {
    return (
      <div style={{ width: "100%", overflow: "hidden" }}>
        <div style={{ width: this.tablewidth + getScrollbarWidth(), overflowX: "auto", maxWidth: "100%" }}>
          {this.props.pagination || this.props.onlyTableOptions || this.props.additionalHeaderItems !== undefined ? (
            <OTableHeader
              additionalHeaderItems={this.props.additionalHeaderItems}
              getColName={this.getColName}
              tablewidth={this.tablewidth}
              getSubTableData={this.props.getSubTableData}
              onlyTableOptions={this.props.onlyTableOptions}
              columns={this.props.columns}
              customizedName={this.props.customizeName}
              size={this.props.size}
              saveCustomizeTable={this.saveCustomizeTable}
              checkBoxArray={this.props.checkBoxArray}
              language={this.props.language}
              checkBoxActions={this.props.checkBoxActions}
              withCSV={this.props.withCSV}
              downloadCSV={this.downloadCSV}
            />
          ) : null}
          <table
            id="table-to-xls"
            className={
              "OTable OTable-" +
              this.props.size +
              (this.props.bordered ? " OTable-bordered" : "") +
              (this.props.striped ? " OTable-striped" : "") +
              (this.props.mouseOver ? " OTable-mouseOver" : "") +
              (this.props.extensible ? " OExtenseTable" : "") +
              (this.props.checkBoxArray !== undefined ? " OCheckboxTable" : "")
            }
            style={{ width: this.tablewidth + getScrollbarWidth() - (this.expandArray !== undefined && this.expandArray.length > 0 ? 1 : 0) }}
          >
            <thead>
              <tr className={"OTableHeader"}>
                {this.props.extensible !== undefined ? (
                  !this.props.search ? (
                    <th style={{ width: "20px" }} className={"OTableNoBorder"}>
                      <OurFontAwsomeIcon
                        color={"#34495c"}
                        style={{ cursor: "pointer", margin: "0" }}
                        onClick={this.expandClickAll}
                        icon={this.expandAll ? faMinus : faPlus}
                      />
                    </th>
                  ) : (
                    <th style={{ width: "20px" }} className={"OTableNoBorder"} />
                  )
                ) : null}

                {this.props.columns.map((col: Column) => {
                  return col.activ === undefined || col.activ ? (
                    <th
                      key={col.key}
                      className={"OTableHeaderItem" + (col.headerClassName ? " " + col.headerClassName : "")}
                      style={{ ...col.headerStyle, width: col.width !== undefined ? col.width : "150px" }}
                    >
                      {col.sortable ? (
                        <Row fullLine style={{ alignItems: "center", color: "inherit" }}>
                          <div style={{ color: "inherit", marginTop: "" }}>{this.getColName(col)}</div>
                          <Column style={{ cursor: "pointer" }} onClick={this.sort.bind(this, col.key, col.sortFunction)}>
                            <OurFontAwsomeIcon
                              color={this.currentsort.key === col.key && this.currentsort.mode === Sortmodes.up ? "white" : "grey"}
                              icon={faSortUp}
                            />
                            <OurFontAwsomeIcon
                              color={this.currentsort.key === col.key && this.currentsort.mode === Sortmodes.down ? "white" : "grey"}
                              style={{ marginTop: "-1em" }}
                              icon={faSortDown}
                            />
                          </Column>
                        </Row>
                      ) : (
                        this.getColName(col)
                      )}
                    </th>
                  ) : null;
                })}
                {this.props.onDelete || this.props.onDetails || this.props.onEdit || this.props.actionRenderer || this.props.checkBoxArray !== undefined ? (
                  this.props.checkBoxArray !== undefined && !this.props.search ? (
                    <th
                      className={"OTableHeaderItem"}
                      style={{ paddingRight: "4px", width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }}
                    >
                      <Row centerVertical={true} style={{ justifyContent: "flex-end" }}>
                        <Checkbox checked={this.checkedAll} onChange={this.checkBoxClickAll} />
                      </Row>
                    </th>
                  ) : (
                    <th className={"OTableHeaderItem"} style={{ width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }} />
                  )
                ) : null}
              </tr>
              {this.props.search ? (
                <tr className={"OTableSearchbar"}>
                  {this.props.extensible !== undefined ? (
                    <td className={"OTableNoBorder"} style={{ width: "20px" }}>
                      <OurFontAwsomeIcon
                        color={"#34495c"}
                        style={{ cursor: "pointer", margin: "0" }}
                        onClick={this.expandClickAll}
                        icon={this.expandAll ? faMinus : faPlus}
                      />
                    </td>
                  ) : null}

                  {this.props.columns.map((col: Column) => {
                    return col.activ === undefined || col.activ ? (
                      col.searchfunc ? (
                        col.customSearchRenderer ? (
                          <td
                            key={col.key}
                            className={"OTableSearchbarItem" + (col.headerClassName ? " " + col.headerClassName : "")}
                            style={{ ...col.headerStyle, width: col.width !== undefined ? col.width : "150px" }}
                          >
                            {col.customSearchRenderer(this.searchValues, col.key)}
                          </td>
                        ) : (
                          <td
                            key={col.key}
                            className={"OTableSearchbarItem" + (col.headerClassName ? " " + col.headerClassName : "")}
                            style={{ ...col.headerStyle, width: col.width !== undefined ? col.width : "150px" }}
                          >
                            <OInput autoWidth height={this.props.size} value={this.searchValues} savename={col.key} />
                          </td>
                        )
                      ) : (
                        <td
                          key={col.key}
                          className={"OTableSearchbarItem" + (col.headerClassName ? " " + col.headerClassName : "")}
                          style={{ ...col.headerStyle, width: col.width !== undefined ? col.width : "150px" }}
                        />
                      )
                    ) : null;
                  })}
                  {this.props.onDelete || this.props.onDetails || this.props.onEdit || this.props.actionRenderer || this.props.checkBoxArray !== undefined ? (
                    this.props.checkBoxArray !== undefined ? (
                      <td style={{ width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }} className={"OTableItem"}>
                        <Row centerVertical={true} style={{ justifyContent: "flex-end" }}>
                          <Checkbox checked={this.checkedAll} onChange={this.checkBoxClickAll} />
                        </Row>
                      </td>
                    ) : (
                      <td className={"OTableSearchbarItem"} style={{ width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }} />
                    )
                  ) : null}
                </tr>
              ) : null}
            </thead>
          </table>
          <div
            style={{
              maxHeight: this.props.height !== undefined ? this.props.height + "px" : "800px",
              overflowY: "scroll",
              borderBottom: "1px solid darkgrey",
              width: this.tablewidth + getScrollbarWidth()
            }}
            className={"OTableHolder"}
          >
            <table
              id="table-to-xls"
              className={
                "OTable OTable-" +
                this.props.size +
                (this.props.bordered ? " OTable-bordered" : "") +
                (this.props.striped ? " OTable-striped" : "") +
                (this.props.mouseOver ? " OTable-mouseOver" : "") +
                (this.props.extensible ? " OExtenseTable" : "") +
                (this.props.checkBoxArray !== undefined ? " OCheckboxTable" : "")
              }
            >
              <OTableBody
                columns={this.props.columns}
                checkBoxArray={this.props.checkBoxArray}
                expandArray={this.expandArray}
                size={this.props.size}
                tabledata={this.tabledata}
                actionRenderer={this.props.actionRenderer}
                extensible={this.props.extensible}
                onDelete={this.props.onDelete}
                onDetails={this.props.onDetails}
                onEdit={this.props.onEdit}
                language={this.props.language}
                actionWidth={this.props.actionWidth}
              />
            </table>
          </div>
          {this.props.pagination ? (
            <OTableFooter
              tablewidth={this.tablewidth}
              filteredData={this.filteredData}
              changePage={this.changePage}
              currentPage={this.currentPage}
              itemsPerPage={this.itemsPerPage}
              size={this.props.size}
              language={this.props.language}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

interface OwnPropsFooter {
  tablewidth: number;
  language: LanguageClassObject;
  size: "small" | "medium" | "large";
  itemsPerPage: { value: number };
  changePage: (a: "first" | "last" | "next" | "prev") => void;
  currentPage: { value: number };
  filteredData: any[];
}

type TableFooterType = OwnPropsFooter;

@observer
class OTableFooter extends Component<TableFooterType> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    let l = this.props.language.l;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          color: "white",
          alignItems: "center",
          width: this.props.tablewidth + getScrollbarWidth()
        }}
      >
        <div style={{ color: "black" }}>
          <OSelect
            allowClear={false}
            width={this.props.size === "small" ? 3.5 : 4.5}
            labelpos={"left"}
            label={l["ProSeite"]}
            labelsize={this.props.size === "small" ? 55 : 70}
            height={this.props.size}
            value={this.props.itemsPerPage}
            savename={"value"}
          >
            <OOption value={10}>10</OOption>
            <OOption value={20}>20</OOption>
            <OOption value={30}>30</OOption>
            <OOption value={50}>50</OOption>
            <OOption value={100}>100</OOption>
          </OSelect>
        </div>

        <Button
          onClick={this.props.changePage.bind(this, "first")}
          size={this.props.size === "medium" ? "default" : this.props.size}
          style={{
            margin: "6px",
            marginRight: "4px",
            padding: "2px"
          }}
        >
          <OurFontAwsomeIcon icon={faFastBackward} color={"grey"} />
        </Button>

        <Button
          onClick={this.props.changePage.bind(this, "prev")}
          size={this.props.size === "medium" ? "default" : this.props.size}
          style={{
            margin: "6px",
            marginLeft: "4px",
            marginRight: "4px",
            padding: "2px"
          }}
        >
          <OurFontAwsomeIcon icon={faBackward} color={"grey"} />
        </Button>

        <div
          style={{
            fontSize: this.props.size === "small" ? "11px" : this.props.size === "medium" ? "13px" : "14px"
          }}
        >
          {l["Seite"]}
        </div>
        <div>
          <OInput
            min={1}
            max={Math.ceil(this.props.filteredData.length / this.props.itemsPerPage.value)}
            height={this.props.size}
            width={2}
            value={this.props.currentPage}
            savename={"value"}
          />
        </div>
        <div
          style={{
            fontSize: this.props.size === "small" ? "11px" : this.props.size === "medium" ? "13px" : "14px",
            whiteSpace: "nowrap"
          }}
        >
          {l["von"]} {Math.ceil(this.props.filteredData.length / this.props.itemsPerPage.value)}
        </div>
        <Button
          onClick={this.props.changePage.bind(this, "next")}
          size={this.props.size === "medium" ? "default" : this.props.size}
          style={{
            margin: "6px",
            marginLeft: "4px",
            marginRight: "4px",
            padding: "2px"
          }}
        >
          <OurFontAwsomeIcon icon={faForward} color={"grey"} />
        </Button>
        <Button
          onClick={this.props.changePage.bind(this, "last")}
          size={this.props.size === "medium" ? "default" : this.props.size}
          style={{
            margin: "6px",
            marginLeft: "4px",
            marginRight: "4px",
            padding: "2px"
          }}
        >
          <OurFontAwsomeIcon icon={faFastForward} color={"grey"} />
        </Button>
      </div>
    );
  }
}

interface OwnPropsHeader {
  checkBoxArray: string[] | undefined;
  tablewidth: number;
  getColName: (col: Column) => any;
  language: LanguageClassObject;
  checkBoxActions?: ReactNode[];
  additionalHeaderItems?: ReactNode;
  withCSV?: boolean;
  getSubTableData?: (row: any) => [Column[], any[]];
  downloadCSV: (type: number, withsubtable?: boolean, withColNames?: boolean, withSubColNames?: boolean) => void;
  size: "small" | "medium" | "large";
  saveCustomizeTable: () => void;
  customizedName: string | undefined;
  columns: Column[];
  onlyTableOptions?: boolean;
}

type TableHeaderType = OwnPropsHeader;

@observer
class OTableHeader extends Component<TableHeaderType> {
  @observable expanded: boolean = false;
  @observable csvexpanded: boolean = false;
  @observable csvDownloadOptions: { type: number; withsubtable: boolean; withColNames: boolean; withSubColNames: boolean } = {
    type: 2,
    withColNames: true,
    withSubColNames: false,
    withsubtable: false
  };
  static defaultProps = {
    downloadCSV: () => {}
  };

  constructor(props: any) {
    super(props);
  }
  @action toggleOptionsDrawer = () => {
    this.expanded = !this.expanded;
  };
  @action toggleCSVDrawer = () => {
    this.csvexpanded = !this.csvexpanded;
  };
  @action colMove = (index: number, direction: number, e: any) => {
    // @ts-ignore
    this.props.columns.move(index, index + direction);
  };
  saveCustomizeTable = () => {
    this.props.saveCustomizeTable();
    this.toggleOptionsDrawer();
  };
  downloadCSV = () => {
    this.props.downloadCSV(
      this.csvDownloadOptions.type,
      this.csvDownloadOptions.withsubtable,
      this.csvDownloadOptions.withColNames,
      this.csvDownloadOptions.withSubColNames
    );
    this.csvDownloadOptions.type = 2;
    this.csvDownloadOptions.withSubColNames = false;
    this.csvDownloadOptions.withColNames = true;
    this.csvDownloadOptions.withsubtable = false;
    this.toggleCSVDrawer();
  };
  getMarks = (col: Column) => {
    let maxWidth = col.maxWidth !== undefined ? col.maxWidth : 300;
    let minWidth = col.minWidth !== undefined ? col.minWidth : 50;
    let resObj = {};
    resObj[minWidth] = minWidth;
    resObj[maxWidth] = maxWidth;
    let curWidth = minWidth + 50;
    while (curWidth < maxWidth) {
      resObj[curWidth] = curWidth;
      curWidth += 50;
    }
    return resObj;
  };
  render() {
    let l = this.props.language.l;
    return (
      <Row centerVertical={true} style={{ width: this.props.tablewidth + getScrollbarWidth() }}>
        {this.props.additionalHeaderItems !== undefined ? this.props.additionalHeaderItems : null}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "white",
            alignItems: "center",
            width: "100%"
          }}
        >
          {!this.props.onlyTableOptions ? (
            <>
              {this.props.withCSV ? (
                <>
                  <OButton onClick={this.toggleCSVDrawer} height={this.props.size} width={3} color={ColorEnum.primary}>
                    {l["CSVdownload"]}
                  </OButton>

                  <Drawer width={200} onClose={this.toggleCSVDrawer} title={l["CSVdownload"]} placement={"right"} closable={true} visible={this.csvexpanded}>
                    <OSelect
                      allowClear={false}
                      savename={"type"}
                      value={this.csvDownloadOptions}
                      label={this.props.language.l["Datenauswahl"]}
                      height={this.props.size}
                    >
                      {this.props.checkBoxArray !== undefined ? (
                        <OurOption disabled={this.props.checkBoxArray.length === 0} value={1}>
                          {l["Auswahl"]}
                        </OurOption>
                      ) : null}
                      <OurOption value={2}>{l["Seite"]}</OurOption>
                      <OurOption value={3}>{l["Alles"]}</OurOption>
                    </OSelect>
                    <OSwitch
                      savename={"withsubtable"}
                      value={this.csvDownloadOptions}
                      label={this.props.language.l["MitUntertablle"]}
                      height={this.props.size}
                    />
                    <OSwitch
                      savename={"withColNames"}
                      value={this.csvDownloadOptions}
                      label={this.props.language.l["MitSpaltenNamen"]}
                      height={this.props.size}
                    />
                    {this.csvDownloadOptions.withsubtable ? (
                      <OSwitch
                        savename={"withSubColNames"}
                        value={this.csvDownloadOptions}
                        label={this.props.language.l["MitSpaltenNamenUntertablle"]}
                        height={this.props.size}
                      />
                    ) : null}
                    <OButton onClick={this.downloadCSV} height={this.props.size} color={ColorEnum.primary} style={{ width: "150px" }}>
                      {l["Download"]}
                    </OButton>
                  </Drawer>
                </>
              ) : null}

              <OButton height={this.props.size} color={ColorEnum.primary} style={{}} onClick={this.toggleOptionsDrawer}>
                <OurFontAwsomeIcon color={"white"} style={{ cursor: "pointer", margin: "0" }} icon={faCog} />
              </OButton>

              {this.props.checkBoxArray !== undefined ? (
                <OTableActions language={this.props.language} disabled={this.props.checkBoxArray.length === 0} checkedArray={this.props.checkBoxArray}>
                  {this.props.checkBoxActions ? [...this.props.checkBoxActions] : null}
                </OTableActions>
              ) : null}
            </>
          ) : (
            <OButton
              height={this.props.size}
              color={ColorEnum.primary}
              style={{
                marginRight: "20px"
              }}
              onClick={this.toggleOptionsDrawer}
            >
              <OurFontAwsomeIcon color={"white"} style={{ cursor: "pointer", margin: "0" }} icon={faCog} />
            </OButton>
          )}
          {this.props.customizedName ? (
            <>
              <Drawer
                width={580}
                onClose={this.toggleOptionsDrawer}
                title={l["TabellenEinstellungen"]}
                placement={"right"}
                closable={true}
                visible={this.expanded}
              >
                <table style={{ borderCollapse: "collapse", marginBottom: "20px", width: "350px", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid darkgrey", padding: "5px", width: "204px", wordWrap: "break-word" }}>{l["Name"]}</th>
                      <th style={{ border: "1px solid darkgrey", padding: "5px", width: "50px", wordWrap: "break-word" }}>{l["Aktiv"]}</th>
                      <th style={{ border: "1px solid darkgrey", padding: "5px", width: "96px" }}>{l["Position"]}</th>
                      <th style={{ border: "1px solid darkgrey", padding: "5px", width: "180px" }}>{l["Breite"]}</th>
                    </tr>
                  </thead>
                  {this.props.columns.map((col, index) => (
                    <tbody key={col.key}>
                      <tr>
                        <td
                          style={{
                            padding: "5px",
                            border: "1px solid darkgrey"
                          }}
                        >
                          {this.props.getColName(col)}
                        </td>
                        <td style={{ border: "1px solid darkgrey", textAlign: "center" }}>
                          <OCheckbox autoWidth value={col} savename={"activ"} />
                        </td>
                        <td style={{ border: "1px solid darkgrey" }}>
                          <Row>
                            <Button
                              disabled={index === 0}
                              onClick={this.colMove.bind(this, index, -1)}
                              style={{ marginLeft: "6px", marginRight: "6px" }}
                              size="small"
                              type={"primary"}
                            >
                              <OurFontAwsomeIcon icon={faArrowUp} />
                            </Button>

                            <Button
                              disabled={index === this.props.columns.length - 1}
                              onClick={this.colMove.bind(this, index, 1)}
                              style={{ marginRight: "6px" }}
                              size="small"
                              type={"primary"}
                            >
                              <OurFontAwsomeIcon icon={faArrowDown} />
                            </Button>
                          </Row>
                        </td>
                        <td style={{ border: "1px solid darkgrey", textAlign: "center" }}>
                          {col.minWidth !== undefined && col.maxWidth !== undefined && col.maxWidth === col.minWidth ? null : (
                            <OSlider
                              max={col.maxWidth !== undefined ? col.maxWidth : 300}
                              min={col.minWidth !== undefined ? col.minWidth : 50}
                              autoWidth
                              value={col}
                              step={10}
                              defaultValue={150}
                              marks={this.getMarks(col)}
                              savename={"width"}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
                <Row fullLine>
                  <OButton onClick={this.saveCustomizeTable} height={this.props.size} color={ColorEnum.primary} style={{ width: "150px" }}>
                    {l["Immeranwenden"]}
                  </OButton>
                  <OButton onClick={this.toggleOptionsDrawer} height={this.props.size} color={ColorEnum.primary} style={{ width: "150px" }}>
                    {l["Nurjeztanwenden"]}
                  </OButton>

                  {this.props.withCSV ? (
                    <OButton onClick={this.toggleCSVDrawer} height={this.props.size} width={3} color={ColorEnum.primary}>
                      {l["CSVdownload"]}
                    </OButton>
                  ) : null}
                </Row>
              </Drawer>
            </>
          ) : null}
        </div>
      </Row>
    );
  }
}

interface OwnPropsTableBody {
  checkBoxArray: string[] | undefined;
  language: LanguageClassObject;
  tabledata: any[];
  extensible?: (row: any) => ReactNode;
  expandArray: number[];
  columns: Column[];
  onDelete?: (row: any) => void;
  onEdit?: (row: any) => void;
  onDetails?: (row: any) => void;
  actionRenderer?: (row: any) => string | ReactNode;
  size: "small" | "medium" | "large";
  actionWidth?: number;
}

type TableBodyType = OwnPropsTableBody;

@observer
class OTableBody extends Component<TableBodyType> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }
  @action expandClick = (rowID: number) => {
    if (this.props.expandArray.includes(rowID)) {
      let index = this.props.expandArray.findIndex(v => v === rowID);
      if (index !== -1 && index !== undefined) this.props.expandArray?.splice(index, 1);
    } else {
      this.props.expandArray.push(rowID);
    }
  };
  @action checkBoxClick = (rowID: string, e: any) => {
    if (e.target.checked) this.props.checkBoxArray?.push(rowID);
    else {
      let index = this.props.checkBoxArray?.indexOf(rowID);
      if (index !== -1 && index !== undefined) this.props.checkBoxArray?.splice(index, 1);
    }
  };
  showDeleteConfirm = (exfunc: () => void) => {
    let l = this.props.language.l;
    confirm({
      title: l["WollenSiedenEintragwirklichloeschen"],
      okText: l["Ja"],
      okType: "danger",
      cancelText: l["Nein"],
      onOk() {
        exfunc();
      },
      onCancel() {}
    });
  };
  @action deleteConfirm = (row: any) => {
    this.showDeleteConfirm(() => {
      if (this.props.onDelete !== undefined) {
        this.props.onDelete(row);
        this.props.checkBoxArray?.splice(0, this.props.checkBoxArray.length);
      }
    });
  };
  render() {
    return (
      <tbody className={"OTableBody"}>
        {this.props.tabledata.map((row: any) => {
          return (
            <React.Fragment key={row.ID ? row.ID : row.id ? row.id : row.fakeKey ? row.fakeKey : undefined}>
              <tr className={"OTableRow"}>
                {this.props.extensible !== undefined ? (
                  <td className={"OTableNoBorder OTableItem"} style={{ width: "20px" }}>
                    <OurFontAwsomeIcon
                      color={"#34495c"}
                      style={{ cursor: "pointer", margin: "0" }}
                      onClick={this.expandClick.bind(this, row.ID)}
                      icon={this.props.expandArray.includes(row.ID) ? faMinus : faPlus}
                    />
                  </td>
                ) : null}

                {this.props.columns.map((col: Column) => (
                  <OTableTdMobx col={col} row={row} />
                ))}

                {this.props.onDelete || this.props.onDetails || this.props.onEdit || this.props.actionRenderer || this.props.checkBoxArray !== undefined ? (
                  <td className={"OTableItem"} style={{ width: this.props.actionWidth !== undefined ? this.props.actionWidth : "100px" }}>
                    <Row centerVertical={true} fullLine={true} style={{ width: "100%" }}>
                      {this.props.actionRenderer !== undefined ? this.props.actionRenderer(row) : null}
                      <Row centerVertical={true} style={{ justifyContent: "flex-end" }}>
                        <Row centerVertical={true} style={{ fontSize: this.props.size === "small" ? "11px" : this.props.size === "medium" ? "13px" : "14px" }}>
                          {this.props.onEdit !== undefined ? (
                            <OurFontAwsomeIcon style={{ cursor: "pointer" }} color={"#1890ff"} icon={faEdit} onClick={this.props.onEdit.bind(this, row)} />
                          ) : null}
                          {this.props.onDetails !== undefined ? (
                            <OurFontAwsomeIcon style={{ cursor: "pointer" }} color={"#1890ff"} icon={faSearch} onClick={this.props.onDetails.bind(this, row)} />
                          ) : null}
                          {this.props.onDelete !== undefined ? (
                            <OurFontAwsomeIcon style={{ cursor: "pointer" }} color={"#ff4d4f"} icon={faTrashAlt} onClick={this.deleteConfirm.bind(this, row)} />
                          ) : null}
                          {this.props.checkBoxArray !== undefined ? (
                            <Checkbox checked={this.props.checkBoxArray.includes(row.ID)} onChange={this.checkBoxClick.bind(this, row.ID)} />
                          ) : null}
                        </Row>
                      </Row>
                    </Row>
                  </td>
                ) : null}
              </tr>
              {this.props.expandArray.includes(row.ID) ? (
                <tr className={"OSubtable"}>
                  <td colSpan={100}>{this.props.extensible ? this.props.extensible(row) : null} </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </tbody>
    );
  }
}

interface OwnPropsTableTdMobx {
  col: Column;
  row: any;
}

type TypeTableTdMobx = OwnPropsTableTdMobx;

const OTableTdMobx: FC<TypeTableTdMobx> = observer(props => {
  return (
    <>
      {props.col.activ !== false ? (
        props.row[props.col.key] !== undefined || props.col.formatter !== undefined ? (
          <>
            <td
              key={props.col.key}
              className={"OTableItem" + (props.col.className ? " " + props.col.className : "")}
              style={{ ...props.col.style, width: props.col.width !== undefined ? props.col.width : "150px" }}
            >
              {props.col.formatter ? props.col.formatter(props.row[props.col.key], props.row) : props.row[props.col.key]}
            </td>
          </>
        ) : (
          <td
            key={props.col.key}
            className={"OTableItem" + (props.col.headerClassName ? " " + props.col.headerClassName : "")}
            style={{ ...props.col.headerStyle, width: props.col.width !== undefined ? props.col.width : "150px" }}
          />
        )
      ) : null}
    </>
  );
});

interface OwnPropsActionRenderer extends SelectProps {
  disabled: boolean;
  checkedArray: string[];
  language: LanguageClassObject;
}

type ActionRendererType = OwnPropsActionRenderer;

@observer
class OTableActions extends Component<ActionRendererType> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Dropdown disabled={this.props.disabled} trigger={["click"]} overlay={<Menu>{this.props.children}</Menu>}>
        <Button style={{ margin: "6px", marginRight: "3px" }} size="small" type={"primary"}>
          {this.props.language.l["MitAuswahl"]}
        </Button>
      </Dropdown>
    );
  }
}*/
