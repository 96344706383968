import { observer, useLocalStore } from "mobx-react";
import React, { CSSProperties, HTMLProps, ReactNode } from "react";
import { action, computed, entries, isArrayLike, observable, values } from "mobx";
import { Input } from "antd";
import { Column, Row } from "../Stylers/Stylers";

interface HOCProps {
  submitted?: boolean;
  required?: boolean;
  value: any;
  savename: string;
  label?: string | ReactNode;
  labelpos?: "left" | "top";
  labelsize?: number;
  height?: "small" | "medium" | "large";
  width?: number;
  autoWidth?: boolean;
  listSize?: boolean;
  className?: string;
  style?: CSSProperties;
  outerStyle?: CSSProperties;
  onChange?: (v: any) => void;
}

export type HOCInputProps = HOCProps;

export const InputHOC = <P extends object>(WrappedComponent: React.ComponentType<P>, withBorder = true) => {
  return observer(
    class extends React.Component<HOCInputProps & P> {
      // @ts-ignore
      @computed get emptyValue() {
        let value = this.props.value[this.props.savename];
        if (isArrayLike(value) && value.length === 0) {
          return true;
        }

        let res = value === "" || value === null || value === undefined;
        if (typeof value === "object") {
          values(value).map(v => {
            if (v === "" || v === null || v === undefined) {
              res = true;
            }
          });
        }
        return res;
      }
      // @ts-ignore
      @observable touched: boolean = false;

      static defaultProps = {
        labelpos: "top",
        labelsize: 80,
        height: "small",
        width: 4,
        autoWidth: false,
        style: {},
        outerStyle: {}
      };
      settouched = () => {
        this.touched = true;
      };
      // @ts-ignore
      @action
      setValue = (v: any) => {
        this.props.value[this.props.savename] = v?.target?.value ?? v?.target?.checked ?? v;
        if (this.props.onChange !== undefined) {
          this.props.onChange(v);
        }
      };
      render() {
        let { submitted, value, savename, style, label, labelpos, labelsize, onChange, ...props } = this.props;
        return (
          <div
            className={
              (this.props.required && this.emptyValue ? "invalid " : "") +
              "InputHOC" +
              (this.props.height === "small" ? " smallInput" : "" + (this.props.className !== undefined ? " " + this.props.className : ""))
            }
            style={{
              margin: this.props.listSize ? "" : "6px",
              width: !this.props.autoWidth
                ? (36 * (this.props.height === "small" ? 1 : 14 / 11) * (this.props.width as number) - 12).toString() + "px"
                : "auto",
              ...this.props.outerStyle
            }}
          >
            {this.props.label ? (
              this.props.labelpos === "left" ? (
                <Row centerVertical={true} style={{ display: "flex", overflow: "hidden" }}>
                  <div
                    style={{
                      fontSize: this.props.height === "small" ? "11px" : "",
                      textOverflow: "clip",

                      whiteSpace: "nowrap",
                      marginRight: "8px",
                      width: this.props.labelsize
                    }}
                  >
                    {this.props.label}
                    {this.props.required ? "*" : ""}
                  </div>
                  <div className={"inputBorderWraper"} style={{ border: withBorder ? "1px solid lightgrey" : "", borderRadius: "3px", width: "100%" }}>
                    <WrappedComponent
                      style={{
                        width: "100%",
                        ...this.props.style
                      }}
                      size={this.props.height}
                      ovalue={value}
                      savename={savename}
                      className={this.touched ? "touched" : ""}
                      value={value[savename]}
                      onChange={this.setValue}
                      onKeyDown={this.settouched}
                      {...(props as P)}
                    />
                  </div>
                </Row>
              ) : (
                <Column>
                  <div
                    style={{
                      fontSize: this.props.height === "small" ? "11px" : "",
                      marginTop: this.props.height === "small" ? "5px" : this.props.height === "medium" ? "6px" : "10px",
                      marginLeft: "2px",
                      textOverflow: "clip",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: (36 * (this.props.height === "small" ? 1 : 14 / 11) * (this.props.width as number) - 12).toString() + "px"
                    }}
                  >
                    {this.props.label}
                    {this.props.required ? "*" : ""}
                  </div>
                  <div className={"inputBorderWraper"} style={{ border: withBorder ? "1px solid lightgrey" : "", borderRadius: "3px", width: "100%" }}>
                    <WrappedComponent
                      style={{
                        width: "100%",
                        ...this.props.style
                      }}
                      size={this.props.height}
                      ovalue={value}
                      savename={savename}
                      className={this.touched ? "touched" : ""}
                      value={value[savename]}
                      onChange={this.setValue}
                      onKeyDown={this.settouched}
                      {...(props as P)}
                    />
                  </div>
                </Column>
              )
            ) : (
              <div className={"inputBorderWraper"} style={{ border: withBorder ? "1px solid lightgrey" : "", borderRadius: "3px", width: "100%" }}>
                <WrappedComponent
                  style={{
                    width: "100%",
                    ...this.props.style
                  }}
                  ovalue={value}
                  size={this.props.height}
                  savename={savename}
                  className={this.touched ? "touched" : ""}
                  value={value[savename]}
                  onChange={this.setValue}
                  onKeyDown={this.settouched}
                  {...(props as P)}
                />
              </div>
            )}
          </div>
        );
      }
    }
  );
};
