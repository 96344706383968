import React, { PureComponent, FC } from "react";
import lscache from "lscache";
import { Button } from "antd";

import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { LanguageClassObject } from "../Languagechanger/Language";

interface OwnPropsCookie {
  language: LanguageClassObject;
}

type PropsCookie = OwnPropsCookie;

@observer
class Cookie extends PureComponent<PropsCookie> {
  @observable cookiesAccepted: boolean = lscache.get("cookiesAccepted");
  @action
  setCookieAccpeted = () => {
    this.cookiesAccepted = true;

    lscache.set("cookiesAccepted", true);
  };

  render() {
    return (
      <>
        {!this.cookiesAccepted ? (
          <div
            style={{
              position: "fixed",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              bottom: 0,
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              paddingRight: "10px",
              paddingLeft: "10px",
              //paddingTop: "10px",
              lineHeight: "60px",
              boxSizing: "border-box",
              maxHeight: "180px",
              minHeight: "60px"
            }}
          >
            <div style={{ lineHeight: "18px", color: "white", textAlign: "center" }} className="cookiepadding">
              {this.props.language.l["CookieAnzeige"]}
            </div>
            <div>
              <Button type={"primary"} style={{ marginLeft: "20px" }} onClick={this.setCookieAccpeted}>
                {this.props.language.l["Okay"]}
              </Button>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
export default Cookie;
